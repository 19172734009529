import React from 'react';
import { Link } from 'react-router-dom';
import freeFillingIcon from '../../assets/images/free-filling.svg';
import wizardFilling from '../../assets/images/wizard-filling.svg';
import { EezyButton } from '../../components/Buttons';
import { Modal, ModalActions, ModalHeader } from '../../components/modals/Modal';
import { P, UniqueTitle } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { useTranslation } from 'react-i18next';

type IModalProps = {
    handleModalClose: () => void;
    isOpen: boolean;
};

const InvoiceCreationMethodModal: React.FC<IModalProps> = (props: IModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal id="modal-strong-auth" isOpen={props.isOpen} onClose={props.handleModalClose}>
            <ModalHeader>
                <UniqueTitle
                    style={{
                        lineHeight: '35px',
                        padding: '15px 0 0 0',
                    }}
                >
                    {t('invoice.new-invoice')}
                </UniqueTitle>
                <P
                    style={{
                        color: COLOR_GREYHOUND,
                        fontSize: '15px',
                    }}
                >
                    {t('invoice.new-invoice-label')}
                </P>
            </ModalHeader>
            <div className="flex justify-center gap-6 my-3">
                <Link
                    to={'/invoices/new'}
                    state={{ fillType: 'free' }}
                    onClick={props.handleModalClose}
                    className="no-underline"
                >
                    <div className="border-[3px] border-violet-950 rounded-lg flex w-[240px] min-h-[142px] flex-col items-center justify-end py-4 gap-2">
                        <img src={freeFillingIcon} />
                        <h3 className="text-black text-sm font-bold">{t('invoice.free-filling-invoice')}</h3>
                    </div>
                </Link>
                <Link
                    to={'/invoices/new'}
                    state={{ fillType: 'guided' }}
                    onClick={props.handleModalClose}
                    className="no-underline"
                >
                    <div className="border-[3px] border-violet-950 rounded-lg flex w-[240px] min-h-[142px] flex-col items-center justify-end py-4 gap-2">
                        <img src={wizardFilling} />
                        <h3 className="text-black text-sm font-bold">
                            {t('invoice.guided-filling-invoice')}
                        </h3>
                    </div>
                </Link>
            </div>
            <ModalActions
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '15px 0 40px 0',
                }}
            >
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    onClick={props.handleModalClose}
                >
                    {t('general.cancel')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InvoiceCreationMethodModal;
