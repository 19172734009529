import { IAddress } from '../../../../shared/src/types/address';
import { CostInvoiceStatus, ICostInvoice } from '../../../../shared/src/types/costs';
import {
    ClientType,
    IBriefInvoice,
    IClient,
    IInputClient,
    IInvoice,
    IInvoiceItem,
    IInvoiceItemKeys,
    InvoiceStatus,
    InvoiceTemplate,
    ItemType,
    IYtjBriefCompany,
    QuantityUnit,
} from '../../../../shared/src/types/invoice';
import { IDropdownOption } from 'components/form/AutocompleteDropdown';
import { IRecipientOption } from 'containers/invoice/InvoiceRecipientOption';
import { divide, multiply, round, vatFactor } from '../calc';
import { ANKKANET_ID_LIMIT, canCostInvoiceBeEditedEvenInvoiceIsPaid } from '../costs/costLogic';
import { trans } from '../i18n';
import { searchInvoices } from '../search/search';
import { addDay, addDaysTo } from '../time';
import { paymentTerms } from './constants';
import {
    completed,
    credited,
    daysInvoiceLate,
    finalityConfirmationSent,
    IInvoiceBriefStatus,
    incomplete,
    inDebtCollectionStatus,
    inDebtPaid,
    isExpressRejected,
    isPaidAndReimbursed,
    isPartiallyPaid,
    isPartiallyPaidInDebt,
    isDueDateToday,
    overdued,
    dueDateToday,
    paidAndReimbursed,
    paidStatus,
    partiallyPaid,
    partiallyPaidInDebt,
    reclaimed,
    sendingFailed,
    sendingPending,
    turnedBack,
    unaccepted,
    waitingForDispatch,
} from './invoiceStatuses';
import invoiceTemplates, {
    DEFAULT_INVOICE_TEMPLATE,
    IInvoiceTemplate,
    VAT_PERCENTAGES,
} from './invoiceTemplates';

export enum CopyInvoiceMessageEnum {
    DATES_DELETED = 'DATES_DELETED',
    ADDRESS_CHANGED = 'ADDRESS_CHANGED',
    EINVOICE_ADDRESS_CHANGED = 'EINVOICE_ADDRESS_CHANGED',
    EMAIL_CHANGED = 'EMAIL_CHANGED',
    INVOICING_METHOD_CHANGED = 'INVOICING_METHOD_CHANGED',
    INVOICING_VAT_CHANGED = 'INVOICING_VAT_CHANGED',
}

export const isAnkkaId = (id: number) => id >= ANKKANET_ID_LIMIT;

export const isEditable = (invoice?: IInvoice | IBriefInvoice, creatingNew?: boolean) => {
    if (creatingNew) return true;
    if (!invoice) return false;
    if (invoice.status === 'incomplete' && isAnkkaId(invoice.id)) return true;
    if (invoice.status === 'turned_back' && (invoice.id ?? 0) >= ANKKANET_ID_LIMIT) return true;

    return false;
};

export const isGroupInvoiceCoworker = (personId: number | undefined, invoice?: IInvoice) => {
    return !!(invoice?.isGroupInvoice && invoice?.createdBy && personId !== invoice.createdBy.id);
};

export const isTurnedBackAndNotEditable = (invoice?: IInvoice) => {
    return invoice?.status === 'turned_back' && !isAnkkaId(invoice?.id);
};

export const isIncompleteAavaInvoice = (invoice?: IInvoice) => {
    return invoice?.status === 'incomplete' && !isAnkkaId(invoice?.id);
};

const hasExpress = (invoice?: IInvoice) => {
    if (!invoice) return false;

    switch (invoice.eezyExpressStatus) {
        case 'advance':
        case 'normal':
        case 'quick':
            return true;
        default:
            return false;
    }
};

export const isCostInvoiceStatusEditable = (costInvoiceStatus?: CostInvoiceStatus) => {
    return (
        costInvoiceStatus === 'incomplete' ||
        costInvoiceStatus === 'unaccepted' ||
        costInvoiceStatus === 'turned_back'
    );
};

export const isCostInvoiceEditable = (invoice?: IInvoice) => {
    // costs can be edited or new costInvoice created
    if (!invoice) {
        return false;
    }
    switch (invoice?.status) {
        case 'incomplete':
            return (
                invoice.id >= ANKKANET_ID_LIMIT &&
                (!invoice.costInvoice || isCostInvoiceStatusEditable(invoice.costInvoice?.status))
            );
        case 'unaccepted':
        case 'waiting_for_dispatch':
        case 'turned_back':
            return !invoice.costInvoice || isCostInvoiceStatusEditable(invoice.costInvoice?.status);
        case 'overdued':
            return (
                !invoice.costInvoice ||
                invoice.costInvoice?.status === 'incomplete' ||
                invoice.costInvoice?.status === 'turned_back' ||
                (invoice.costInvoice?.status === 'unaccepted' && !hasExpress(invoice))
            );
        case 'completed':
            return (
                (!invoice.costInvoice && !hasExpress(invoice)) ||
                invoice.costInvoice?.status === 'turned_back' ||
                (invoice.costInvoice?.status === 'incomplete' && !hasExpress(invoice)) ||
                (invoice.costInvoice?.status === 'unaccepted' && !hasExpress(invoice))
            );
        case 'paid':
            return (
                invoice.costInvoice?.status === 'turned_back' ||
                (!hasExpress(invoice) &&
                    invoice.costInvoice?.status !== 'completed' &&
                    canCostInvoiceBeEditedEvenInvoiceIsPaid(invoice))
            );
        default:
            return false;
    }
};

export const isCostInvoiceReSendable = (invoice?: IInvoice) => {
    const costInvoice = invoice?.costInvoice;
    if (!costInvoice || !invoice || isEditable(invoice)) {
        return false;
    }
    switch (invoice.status) {
        case 'unaccepted':
        case 'waiting_for_dispatch':
        case 'overdued':
        case 'completed':
            return costInvoice?.status === 'incomplete' || costInvoice?.status === 'turned_back';
        case 'paid':
            return (
                costInvoice.status === 'turned_back' ||
                (costInvoice?.status === 'incomplete' &&
                    !hasExpress(invoice) &&
                    canCostInvoiceBeEditedEvenInvoiceIsPaid(invoice))
            );
        default:
            return false;
    }
};

export const costInvoiceHasCosts = (costInvoice?: ICostInvoice | undefined): boolean =>
    !!(costInvoice?.travels?.length || costInvoice?.receiptCosts?.length);

export const shouldCostInvoiceBeReturnedBeforeEditing = (invoice?: IInvoice) => {
    if (!invoice || !invoice.costInvoice) {
        return false;
    }
    return isCostInvoiceEditable(invoice) && !isAnkkaId(invoice.costInvoice.id);
};

export const canCostInvoiceMessageBeEdited = (invoice?: IInvoice) => {
    const costInvoice = invoice?.costInvoice;
    if (!invoice || !costInvoice) {
        return false;
    }
    switch (invoice.status) {
        case 'incomplete':
        case 'unaccepted':
        case 'waiting_for_dispatch':
        case 'turned_back':
        case 'overdued':
        case 'completed':
            return (
                costInvoice?.status === 'incomplete' ||
                costInvoice?.status === 'unaccepted' ||
                costInvoice?.status === 'turned_back'
            );
        case 'paid':
            return costInvoice.status === 'turned_back';
        default:
            return false;
    }
};

export const showCostInvoiceTurnbackReason = (costInvoice?: ICostInvoice) => {
    if (!costInvoice) {
        return false;
    }
    return (
        costInvoice.status === 'turned_back' ||
        (costInvoice.status === 'incomplete' && costInvoice.turnBackReason)
    );
};

export const isDownloadable = (status?: InvoiceStatus) => {
    switch (status) {
        case 'completed':
        case 'overdued':
        case 'paid':
            return true;
        default:
            return false;
    }
};

export const isExpenseReimbursementMissing = (invoice?: IInvoice) => {
    const travelOrSupplyItems = invoice?.invoiceItems?.find(
        (item) => item.itemType === 'material' || item.itemType === 'travel'
    );
    return (
        !!travelOrSupplyItems &&
        (!invoice?.eezyExpressStatus || (invoice?.eezyExpressStatus && invoice?.status === 'incomplete')) &&
        (!invoice?.costInvoice ||
            (invoice?.costInvoice?.receiptCosts?.length === 0 &&
                invoice?.costInvoice?.travels?.length === 0)) &&
        invoice?.status !== 'paid' &&
        invoice?.status !== 'credited'
    );
};

export const canCoworkerAddExpenses = (invoice?: IInvoice) => {
    const invoiceStatus = invoice?.status;
    return (
        invoiceStatus === 'completed' ||
        invoiceStatus === 'overdued' ||
        (invoiceStatus === 'paid' && canCostInvoiceBeEditedEvenInvoiceIsPaid(invoice!))
    );
};

export const isCreditableStatus = (invoiceStatus: InvoiceStatus) => {
    return invoiceStatus === 'completed' || invoiceStatus === 'overdued';
};

export const isCreditable = (invoice: IInvoice) => {
    if (hasExpress(invoice)) {
        return false;
    }
    return isCreditableStatus(invoice.status);
};

export const isInvoicePartlyPaid = (invoice: IInvoice) => {
    return invoice.paymentInformation && invoice.paymentInformation?.paidAmount > 0;
};

export const getStatus = (invoiceBrief?: IBriefInvoice, invoice?: IInvoice): IInvoiceBriefStatus | null => {
    if (!invoiceBrief) {
        return null;
    }

    if (invoiceBrief?.sendingFailed) {
        return sendingFailed(invoiceBrief);
    }
    if (
        invoiceBrief.hasBeenReclaimed &&
        invoiceBrief.status !== 'paid' &&
        invoiceBrief.status !== 'credited'
    ) {
        return reclaimed(invoiceBrief);
    }
    switch (invoiceBrief.status) {
        case 'incomplete': // keskeneräinen
            return incomplete(invoiceBrief);
        case 'sending_pending':
            return sendingPending(invoiceBrief);
        case 'unaccepted': // tarkastettavana, laskutus tsekkaa laskun
            return unaccepted(invoiceBrief);
        case 'turned_back': // palautettu
            return turnedBack(invoiceBrief);
        case 'completed': // laskutettu, odottaa maksua, lasku on lähetetty asiakkaalle
            if (isExpressRejected(invoiceBrief) && invoice?.finalityConfirmationSent) {
                return finalityConfirmationSent(invoiceBrief);
            }
            if (isPartiallyPaidInDebt(invoiceBrief)) {
                return partiallyPaidInDebt(invoiceBrief);
            } else if (isPartiallyPaid(invoiceBrief)) {
                return partiallyPaid(invoiceBrief);
            } else {
                return completed(invoiceBrief);
            }
        case 'overdued': // erääntynyt
            if (isDueDateToday(invoiceBrief)) {
                return dueDateToday(invoiceBrief);
            } else if (isPartiallyPaidInDebt(invoiceBrief)) {
                return partiallyPaidInDebt(invoiceBrief);
            } else if (isPartiallyPaid(invoiceBrief)) {
                return partiallyPaid(invoiceBrief);
            } else if (invoiceBrief.inDebtCollection) {
                return inDebtCollectionStatus(invoiceBrief);
            } else {
                return overdued(invoiceBrief);
            }
        case 'credited': // hyvitetty / peruttu
            return credited(invoiceBrief);
        case 'paid': // maksettu
            if (invoiceBrief.inDebtCollection) {
                return inDebtPaid(invoiceBrief);
            } else if (invoiceBrief.paymentReceived === false) {
                // Express invoice may have status paid but actually is unpaid, if paymentReceived===false
                return {
                    ...completed(invoiceBrief),
                    reclaimed: invoiceBrief.hasBeenReclaimed,
                };
            } else if (isPaidAndReimbursed(invoiceBrief)) {
                return paidAndReimbursed(invoiceBrief);
            } else {
                return paidStatus(invoiceBrief);
            }
        case 'waiting_for_dispatch':
            return waitingForDispatch(invoiceBrief, invoice);
        default:
            return null;
    }
};

export const costInvoiceNotSent = (invoiceBrief: IBriefInvoice) =>
    invoiceBrief.costInvoiceStatus === 'incomplete' &&
    invoiceBrief.status !== 'incomplete' &&
    invoiceBrief.status !== 'turned_back';

// Which IClient fields are shown on the diff
const DIFF_RECIPIENT_FIELDS = ['address', 'name', 'einvoiceOvt', 'email'];

export const ytjDiff = (formData: IClient, ytjData: IClient) => {
    const [o1, o2] = [formData as any, ytjData as any];
    return Object.keys(o2).reduce((obj, key) => {
        if (!DIFF_RECIPIENT_FIELDS.includes(key) || o1[key] === o2[key] || !o2[key]) {
            // Field is not of interest, or values are the same, or ytj data is empty => ignore
            return obj;
        }
        if (key === 'address') {
            // Compare address fields and if any of them differs, return the whole address
            const [a1, a2] = [o1.address, o2.address];
            if (
                a1.street1 !== a2.street1 ||
                a1.street2 !== a2.street2 ||
                a1.zipCode !== a2.zipCode ||
                a1.town !== a2.town ||
                a1.country !== a2.country
            ) {
                return { ...obj, [key]: o2.address };
            } else {
                return obj;
            }
        }
        // return diff data
        return {
            ...obj,
            [key]: o2[key],
        };
    }, {});
};

type IRowEditProps = (changedProperty: IInvoiceItemKeys, item: IInvoiceItem, withVat?: boolean) => object;

const m = (a: number | undefined | null, b: number | undefined | null) => multiply(a || 0, b || 0);
const d = (a: number, b: number) => divide(a, b);
const r = round;

export const onQuantityEdit: IRowEditProps = (prop, item, withVat) => {
    if (withVat) {
        const totalPriceWithVat = m(item.price, prop.quantity);
        const totalPrice = totalPriceWithVat ? d(totalPriceWithVat, vatFactor(item.vat)) : 0;
        return {
            ...prop,
            totalPrice: r(totalPrice),
            totalPriceWithVat: r(totalPriceWithVat),
        };
    } else {
        const totalPrice = m(item.price, prop.quantity);
        const totalPriceWithVat = m(totalPrice, vatFactor(item.vat));
        return {
            ...prop,
            totalPrice: r(totalPrice),
            totalPriceWithVat: r(totalPriceWithVat),
        };
    }
};

export const onPriceEdit: IRowEditProps = (prop, item, withVat) => {
    if (withVat) {
        const totalPriceWithVat = m(prop.price, item.quantity);
        const totalPrice = totalPriceWithVat ? d(totalPriceWithVat, vatFactor(item.vat)) : 0;
        const price = prop.price ? d(prop.price, vatFactor(item.vat)) : 0;
        return {
            ...prop,
            price,
            totalPrice: r(totalPrice),
            totalPriceWithVat: r(totalPriceWithVat),
        };
    } else {
        const totalPrice = m(prop.price, item.quantity);
        const totalPriceWithVat = m(totalPrice, vatFactor(item.vat));
        return {
            ...prop,
            totalPrice: r(totalPrice),
            totalPriceWithVat: r(totalPriceWithVat),
        };
    }
};

export const onVatEdit: IRowEditProps = (prop, item, withVat) => {
    if (withVat) {
        const totalPrice = item.totalPriceWithVat ? d(item.totalPriceWithVat, vatFactor(prop.vat)) : 0;
        const price = totalPrice && item.quantity ? d(totalPrice, item.quantity) : totalPrice;
        return { ...prop, price, totalPrice };
    } else {
        const totalPriceWithVat = r(m(item.totalPrice, vatFactor(prop.vat)));
        return { ...prop, totalPriceWithVat };
    }
};

export const onTotalPriceEdit: IRowEditProps = (prop, item) => {
    const price = prop.totalPrice && item.quantity ? d(prop.totalPrice, item.quantity) : 0;
    const totalPriceWithVat = m(prop.totalPrice, vatFactor(item.vat));
    return {
        ...prop,
        price,
        totalPriceWithVat: r(totalPriceWithVat),
    };
};

export const onTotalPriceWithVatEdit: IRowEditProps = (prop, item) => {
    const totalPrice = prop.totalPriceWithVat ? d(prop.totalPriceWithVat, vatFactor(item.vat)) : 0;
    const price = item.quantity ? d(totalPrice, item.quantity) : 0;
    return { ...prop, price, totalPrice: r(totalPrice) };
};

export const getRowUpdates: IRowEditProps = (changedProperty, item, withVat): object => {
    switch (true) {
        case 'quantity' in changedProperty:
            return onQuantityEdit(changedProperty, item, withVat);

        case 'price' in changedProperty:
            return onPriceEdit(changedProperty, item, withVat);

        case 'vat' in changedProperty:
            return onVatEdit(changedProperty, item, withVat);

        case 'totalPrice' in changedProperty:
            return {
                ...onTotalPriceEdit(changedProperty, item),
                quantityUnit: 'pcs',
            };

        case 'totalPriceWithVat' in changedProperty:
            return onTotalPriceWithVatEdit(changedProperty, item);

        case 'description' in changedProperty:
        case 'quantityUnit' in changedProperty:
        default:
            return { ...changedProperty };
    }
};

export const getPaymentTermInterval = (invoice?: IInvoice): number => {
    if (invoice?.recipient?.type === 'person') {
        return 14;
    } else if (invoice?.recipient?.invoicingMethod === 'mail') {
        return 7;
    } else {
        return 0;
    }
};

export const getPaymentTermOptions = (invoice?: IInvoice): IDropdownOption[] => {
    const formatOptions = (options: number[]) => {
        return options.map((opt: number) => {
            return {
                label: trans('invoice.paymentTerm_interval', {
                    count: opt,
                    postProcess: 'interval',
                }),
                value: opt.toString(),
            };
        });
    };

    return formatOptions(paymentTerms.filter((term) => term >= getPaymentTermInterval(invoice)));
};

export const duedateReferences = (
    refDate: string = new Date().toISOString()
): { now: Date; nowPlus14: Date; nowPlus7: Date; tomorrow: Date } => {
    const date = refDate ? new Date(refDate) : new Date();
    return {
        now: new Date(date),
        nowPlus14: addDaysTo(14, new Date(date)),
        nowPlus7: addDaysTo(7, new Date(date)),
        tomorrow: addDaysTo(1, new Date()),
    };
};

export const getMinDuedate = (invoice?: IInvoice): Date => {
    // Today cannot be selected for duedate, so if the payment term
    // interval is 0, earliest duedate is tomorrow
    const refDate = invoice?.dispatchDate ? new Date(invoice?.dispatchDate) : new Date();
    return addDaysTo(getPaymentTermInterval(invoice) || 1, refDate);
};

export const getMaxDispatchDate = (invoice: IInvoice) => {
    const dueDate = invoice.dueDate ? new Date(invoice.dueDate) : addDaysTo(365, new Date());
    return addDaysTo(invoice.recipient?.invoicingMethod === 'mail' ? -7 : -1, dueDate);
};

export const defaultQuantityUnit = (itemType: ItemType): QuantityUnit => {
    if (itemType === 'travel') {
        return 'km';
    } else {
        return 'pcs';
    }
};

const DEFAULT_INVOICE_VAT = (() => {
    const now = new Date();
    const newVAT = new Date(2024, 8, 1); // 1 September 2024 will apply new VAT rate

    if (now >= newVAT) {
        return 25.5;
    }

    return 24;
})();

export const defaultVat = (
    template: IInvoiceTemplate,
    workItemVats: number[],
    recipientType?: ClientType
): number => {
    const listVATs = workItemVats || [];
    const targetDefaultVAT = DEFAULT_INVOICE_VAT;

    if (template.id === 'domestic') {
        if (!listVATs.length || listVATs.includes(targetDefaultVAT)) {
            return targetDefaultVAT;
        }

        if (listVATs.includes(10)) {
            return 10;
        }
    } else if (template.id === 'foreign' && recipientType === 'person') {
        if (!listVATs.length || listVATs.includes(targetDefaultVAT)) {
            return targetDefaultVAT;
        }
    }

    return 0;
};

export const quantityUnitOptions = (itemType?: ItemType): IDropdownOption[] => {
    if (itemType === 'work') {
        return [
            { label: trans('form.x-pcs'), value: 'pcs' },
            { label: trans('form.x-h'), value: 'h' },
        ];
    } else if (itemType === 'travel') {
        return [
            { label: trans('form.x-pcs'), value: 'pcs' },
            { label: trans('form.x-km'), value: 'km' },
        ];
    } else {
        return [
            { label: trans('form.x-pcs'), value: 'pcs' },
            { label: trans('form.x-kg'), value: 'kg' },
            { label: trans('form.x-l'), value: 'l' },
            { label: trans('form.x-m'), value: 'm' },
            { label: trans('form.x-m3'), value: 'm3' },
        ];
    }
};

export const invoicingMethodOptions = (clientType: ClientType): IDropdownOption[] => {
    const options = [
        {
            label: trans('invoice.form.recipient.invoicingMethod.email'),
            value: 'email',
        },
        {
            label: trans('invoice.form.recipient.invoicingMethod.mail'),
            value: 'mail',
        },
        {
            label: trans('invoice.form.recipient.invoicingMethod.nosend'),
            value: 'nosend',
        },
    ];
    if (clientType === 'company') {
        options.splice(1, 0, {
            label: trans('invoice.form.recipient.invoicingMethod.einvoice'),
            value: 'einvoice',
        });
    }
    return options;
};

export const recipientTypeOptions = (): IDropdownOption[] => {
    return [
        {
            label: trans('invoice.form.recipient.type.company'),
            value: 'company',
        },
        { label: trans('invoice.form.recipient.type.person'), value: 'person' },
    ];
};

export const vatOptions = (
    itemType?: ItemType,
    vatList?: number[],
    template?: IInvoiceTemplate
): IDropdownOption[] => {
    const templatePercentages = template?.vatPercentages || [];
    const vats: number[] =
        itemType === 'work' || !vatList || vatList.length === 0
            ? templatePercentages
            : templatePercentages.filter((vat) => vatList.includes(vat));

    const options: IDropdownOption[] = VAT_PERCENTAGES.map((vat) => ({
        disabled: !vats.includes(vat),
        label: trans('form.percent', { percentage: vat.toString() }),
        value: vat.toString(),
    }));

    return options;
};

export const calcTypeOptions = (itemType?: ItemType): IDropdownOption[] => {
    if (itemType === 'work') {
        return [
            {
                label: trans('invoice.rows-form.calc-type-h'),
                value: 'h',
            },
            {
                label: trans('invoice.rows-form.calc-type-units-work'),
                value: 'units',
            },
            {
                label: trans('invoice.rows-form.calc-type-sum'),
                value: 'sum',
            },
        ];
    } else {
        return [
            {
                label: trans('invoice.rows-form.calc-type-units'),
                value: 'units',
            },
            {
                label: trans('invoice.rows-form.calc-type-sum'),
                value: 'sum',
            },
        ];
    }
};

export const initialRowData = {
    description: '',
    endDate: undefined,
    id: 0,
    itemType: 'work' as ItemType,
    price: undefined,
    quantity: undefined,
    quantityUnit: 'pcs' as QuantityUnit,
    startDate: undefined,
    totalPrice: undefined,
    totalPriceWithVat: undefined,
    vat: DEFAULT_INVOICE_VAT,
};

export const clientToClientInput = (recipient: IClient): IInputClient => {
    return {
        active: recipient.active,
        address: {
            country: recipient.address?.country || '',
            street1: recipient.address?.street1 || '',
            street2: recipient.address?.street2 || '',
            town: recipient.address?.town || '',
            zipCode: recipient.address?.zipCode || '',
        },
        businessId: recipient.businessId,
        company: recipient.type === 'company' ? recipient.name : undefined,
        contactPeople: recipient.contactPeople,
        einvoiceOvt: recipient.einvoiceOvt,
        email: recipient.email,
        firstName: recipient.firstName,
        invoiceLanguage: recipient.invoiceLanguage,
        invoicingMethod: recipient.invoicingMethod || 'email',
        lastName: recipient.lastName,
        type: recipient.type || 'company',
        operator: recipient.operator,
    };
};

export const filterDuplicateBusinessIds = (
    ytjResult: IYtjBriefCompany[],
    ownRecipients: IRecipientOption[]
) => {
    return ytjResult.filter((item: IYtjBriefCompany) => {
        return !ownRecipients.find((o: IRecipientOption) => o.businessId === item.businessId);
    });
};

export const convertCompanyToPerson = (recipient: IClient): IClient => {
    const companyName = recipient.name || '';
    return {
        ...recipient,
        firstName: companyName.split(' ', 1)[0],
        lastName: companyName.indexOf(' ') > 0 ? companyName.substring(companyName.indexOf(' ') + 1) : '',
    };
};
export const convertPersonToCompany = (recipient: IClient): IClient => {
    return {
        ...recipient,
        firstName: '',
        lastName: '',
        name: `${recipient.firstName || ''} ${recipient.lastName || ''}`.trim(),
    };
};

export const cleanRecipientBeforeSending = (recipient: IInputClient): IInputClient => {
    if (recipient.type === 'person') {
        // businessId not allowed when saving person data
        delete recipient.businessId;
        delete recipient.contactPeople;
        recipient.company = '';
        recipient.operator = '';
    } else {
        recipient.firstName = '';
        recipient.lastName = '';
    }
    if (recipient.type === 'company' && recipient.invoicingMethod !== 'einvoice') {
        recipient.operator = '';
    }
    return recipient;
};

export const getTemplate = (template?: InvoiceTemplate): IInvoiceTemplate =>
    invoiceTemplates.find((t) => t.id === template) || DEFAULT_INVOICE_TEMPLATE;

const onTop = (invoice: IBriefInvoice) => {
    return invoice.priority === 1 || (invoice.priority === 2 && invoice.costInvoiceStatus === 'incomplete');
};

export const splitRecipientId = (recipientId: string) => {
    return recipientId.includes('-') ? recipientId.split('-')[0] : recipientId;
};

export const filterInvoicesByRecipientId = (recipientId: string, invoices: IBriefInvoice[]) => {
    return invoices.filter((b: IBriefInvoice) => {
        return splitRecipientId(b.recipientId) === recipientId;
    });
};

interface IDividedInvoices {
    bottomInvoices: IBriefInvoice[];
    topInvoices: IBriefInvoice[];
}

export const divideInvoices = (invoices: IBriefInvoice[]): IDividedInvoices => {
    const topInvoices = invoices.filter(onTop);
    const bottomInvoices = invoices.filter((invoice) => !onTop(invoice));

    const incompleteInvoices = topInvoices.filter(
        (invoice) => costInvoiceNotSent(invoice) && invoice?.status !== 'unaccepted'
    );
    const filteredInvoices = topInvoices.filter((item) => !incompleteInvoices.includes(item));

    return {
        topInvoices: incompleteInvoices.concat(filteredInvoices),
        bottomInvoices,
    };
};

export const filterInvoices = (q: string, invoices: IBriefInvoice[]) => {
    if (!q) {
        return invoices;
    }
    return searchInvoices(invoices, q);
};

export const getById = (id?: number, invoices?: IBriefInvoice[]): IBriefInvoice | undefined => {
    if (!id || !invoices) {
        return undefined;
    }
    return invoices.find((i: IBriefInvoice) => i.id === id);
};

export const getFieldFromBriefInvoice = (
    id?: number,
    field?: keyof IBriefInvoice,
    invoices?: IBriefInvoice[]
) => {
    if (!invoices || !id || !field) {
        return null;
    }
    const invoice = getById(id, invoices);
    return invoice ? invoice[field] : null;
};

export const formatAddress = (address?: IAddress) => {
    const streetName = `${address?.street1 || ''} ${address?.street2 || ''}`.trim();
    return `${streetName}, ${address?.zipCode || ''} ${address?.town || ''}, ${trans(
        `countries:${address?.country}`
    )}`;
};

export const getTravelInvoicingTotal = (invoice: IInvoice): number => {
    if (!invoice.invoiceItems) {
        return 0;
    }
    return invoice.invoiceItems
        .filter((item: IInvoiceItem) => item.itemType === 'travel')
        .reduce((total: number, item: IInvoiceItem) => total + (item.totalPriceWithVat || 0), 0);
};

export const getTravelInvoicingTotalWithoutVat = (invoice: IInvoice, userId: number | undefined): number => {
    if (!invoice.invoiceItems || !userId) {
        return 0;
    }
    const travelInvoicing = invoice.invoiceItems
        .filter((item: IInvoiceItem) => item.itemType === 'travel')
        .reduce((total: number, item: IInvoiceItem) => total + (item.totalPrice || 0), 0);
    if ((invoice.provisions || []).length > 1) {
        const ownProvision = invoice.provisions?.find((p) => p.personId === userId);
        const percentage = ownProvision?.travelExpenses || 0;
        return travelInvoicing * (percentage / 100);
    }
    return travelInvoicing;
};

const DAYS_LATE_FOR_COLLECTION = 3;

export const showCollectionInfoBox = (invoice: IInvoice, briefInvoice?: IBriefInvoice) => {
    return (
        !invoice.creditRequested &&
        !briefInvoice?.hasBeenReclaimed &&
        (((daysInvoiceLate(invoice?.dueDate) || 0) >= DAYS_LATE_FOR_COLLECTION &&
            invoice.status === 'overdued' &&
            !invoice.debtCollection) ||
            ((invoice.status === 'completed' || invoice.status === 'overdued') &&
                (invoice.collectionRequested || briefInvoice?.inDebtCollection)))
    );
};

export const showCreditRequestedInfoBox = (invoice: IInvoice) => {
    return invoice.creditRequested && (invoice.status === 'overdued' || invoice.status === 'completed');
};

export const getInvoiceItemCopy = (invoiceItem: IInvoiceItem) => {
    const { id, endDate, startDate, totalPrice, totalPriceWithVat, ...itemProps } = invoiceItem;

    return {
        endDate: addDay(endDate),
        startDate: addDay(startDate),
        ...itemProps,
    };
};

export const shouldShowNotificationDays = (invoice?: IInvoice): boolean =>
    !!invoice?.notificationDays && invoice.status !== 'incomplete' && invoice.status !== 'unaccepted';

export const shouldShowInterestPercentage = (invoice?: IInvoice): boolean =>
    !!invoice?.interestPercentage && invoice.status !== 'incomplete' && invoice.status !== 'unaccepted';

export const sortInvoices = (invoices: IBriefInvoice[]): IBriefInvoice[] => {
    const invoicesArray = [...invoices];
    const isTurnedBack = (i: IBriefInvoice): boolean => i.costInvoiceStatus === 'turned_back';

    const sorted = invoicesArray.sort((a, b) => {
        if (isTurnedBack(a) && !isTurnedBack(b)) {
            return -1;
        }
        if (!isTurnedBack(a) && isTurnedBack(b)) {
            return 1;
        }
        if (a.sendingFailed && !b.sendingFailed) {
            return -1;
        }
        if (!a.sendingFailed && b.sendingFailed) {
            return 1;
        }
        return new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime();
    });
    return sorted;
};
