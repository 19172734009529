import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HighlightBox } from 'components/Balloon';
import { EezyButton } from 'components/Buttons';
import { Checkbox } from 'components/form';
import { Icon } from 'components/Icon';
import { Line2 } from 'components/Lines';
import { COLOR_BLUM } from 'styles/variables';
import { ButtonContainer, H3, Wrapper } from 'styles/yelStyles';
import { currentDate, formatCents, formatDate, formatPercentage } from 'utils';
import { yelLogic } from 'utils/yel';
import { eloDocumentUrl } from 'utils/yel/variables';
import { useYelData } from 'queries/useYelData';
import useYelStore from 'stores/yelStore';
import { useUser } from 'queries/useUserQuery';

const P = styled.p`
    &&& {
        margin-bottom: 0;
    }
`;

const YelSummary = () => {
    const { t, i18n } = useTranslation();
    const user = useUser();

    const updateYelStore = useYelStore((state) => state.updateYelStore);
    const yelStoreData = useYelStore((state) => state.yelData);

    const { data, postYelData } = useYelData();

    const handleCancel = () => updateYelStore({ showSummary: false });

    const handleSubmit = () => {
        postYelData();
    };

    const toggleTerms = () => {
        updateYelStore({ termsAccepted: !yelStoreData.termsAccepted });
    };

    const getQuotaText = (numberOfQuotas?: number) => {
        switch (numberOfQuotas) {
            case 1:
                return 'yel:payment-once-year';
            case 3:
                return 'yel:payment-three-times';
            case 4:
                return 'yel:payment-four-times';
            case 6:
                return 'yel:payment-six-times';
            case 12:
                return 'yel:payment-twelve-times';
            case 2:
            default:
                return 'yel:payment-twice-year';
        }
    };

    return (
        <>
            <Wrapper>
                <H3 style={{ fontSize: '20px' }}>
                    {yelStoreData.whoPays === 'self'
                        ? t('yel:check-data-order-yel-2')
                        : t('yel:check-data-order-yel')}
                    :
                </H3>
            </Wrapper>

            <Wrapper>
                <H3>{t('yel:yel-insurance')}</H3>

                {yelStoreData.hasYel === 'Yes' ? (
                    <p>{t('yel:yel-general-description')}</p>
                ) : (
                    <p>
                        {t('yel:yel-startdate-description', {
                            startdate: formatDate(yelStoreData.dateStarted || currentDate()),
                        })}{' '}
                    </p>
                )}
            </Wrapper>

            {!yelStoreData.hasSameIncomeAsBefore && (
                <Wrapper>
                    <H3>{t('yel:income')}:</H3>
                    <p>
                        {t('form.eurs-year-value', {
                            eurs: formatCents(yelStoreData.estimatedIncome),
                        })}
                    </p>
                </Wrapper>
            )}

            {yelLogic.shouldShowBasePercentage(yelStoreData) && (
                <Wrapper>
                    <p>
                        {t('yel:term.long')}{' '}
                        {t('form.percent', {
                            percentage: formatPercentage(data.collectionInfo.insurancePercentage),
                        })}
                    </p>

                    {!yelStoreData.hasSameIncomeAsBefore && (
                        <p>
                            {t('yel:yearly-payment-estimate')}:{' '}
                            {t('form.eurs', {
                                eurs: formatCents(
                                    yelLogic.calculateYelPayment(
                                        yelStoreData.estimatedIncome,
                                        data.collectionInfo.insurancePercentage,
                                        data.incomeLimits
                                    )
                                ),
                            })}
                        </p>
                    )}
                </Wrapper>
            )}

            {yelLogic.shouldShowDiscountPercentage(yelStoreData) && (
                <Wrapper>
                    <p>
                        {t('yel:term.long')}{' '}
                        {t('form.percent', {
                            percentage: formatPercentage(data.collectionInfo.insurancePercentageDiscount),
                        })}
                        {'. '}({t('yel:includes-discount')})
                    </p>

                    <p>
                        {t('yel:yearly-payment-estimate')}:{' '}
                        {t('form.eurs', {
                            eurs: formatCents(
                                yelLogic.calculateYelPayment(
                                    yelStoreData.estimatedIncome,
                                    data.collectionInfo.insurancePercentageDiscount,
                                    data.incomeLimits
                                )
                            ),
                        })}
                        . {t(getQuotaText(yelStoreData.numberOfQuotas))}
                    </p>
                </Wrapper>
            )}

            {!yelStoreData.hasSameIncomeAsBefore && (
                <Wrapper>
                    <p>{t('yel:payment-disclaimer')}</p>
                </Wrapper>
            )}

            <Wrapper>
                <H3>{t('yel:estimated-invoicing')}:</H3>
                <p>
                    {t('form.eurs-year-value', {
                        eurs: formatCents(yelStoreData.estimatedInvoicing),
                    })}
                </p>
            </Wrapper>

            {yelStoreData.occupation && (
                <Wrapper>
                    <H3>{t('yel:your-occupation')}:</H3>
                    <p>{yelStoreData.occupation.replace(/ *\[[^)]*] */g, '')}</p>
                </Wrapper>
            )}

            {yelStoreData.whoPays !== 'self' && (
                <HighlightBox>
                    <p>{t('yel:note-invoice-dates')}</p>
                </HighlightBox>
            )}

            <Wrapper>
                <H3>{t('profile.own-information')}</H3>
                {(user?.firstName || user?.lastName) && (
                    <P>
                        {user.firstName} {user.lastName}
                    </P>
                )}
                {user?.phone && <P>{user.phone}</P>}
                {user?.email && <P>{user.email}</P>}
                {user?.mailingAddress && (
                    <>
                        <P>{user.mailingAddress.street1}</P>
                        {user.mailingAddress.street2 && <P>{user.mailingAddress.street2}</P>}
                        <P>
                            {user.mailingAddress.zipCode} {user.mailingAddress.town}
                        </P>
                    </>
                )}
            </Wrapper>

            <Wrapper>
                <H3>{t('yel:insurance-company')}:</H3>
                <p>
                    {t('yel:elo')}{' '}
                    <a href="https://www.elo.fi" rel="noopener noreferrer" target="_blank">
                        www.elo.fi
                        <Icon
                            className="small"
                            color={COLOR_BLUM}
                            icon={['far', 'arrow-up-right-from-square']}
                            style={{ marginLeft: 10 }}
                        />
                    </a>
                </p>
            </Wrapper>

            <Line2 />

            <Wrapper>
                {yelStoreData.whoPays === 'self' ? (
                    <>
                        <p>{t('yel:auth-self-description1')}</p>
                        <p>{t('yel:auth-self-description2')}</p>
                    </>
                ) : (
                    <>
                        <p>{t('yel:auth-description1')}</p>
                        <p>{t('yel:auth-description2')}</p>
                    </>
                )}
            </Wrapper>

            <Wrapper>
                <Link to="/yel/insurance-info" target="_blank">
                    {t('yel:yel-document-anchor')}
                </Link>
                <a href={eloDocumentUrl(i18n.language)} rel="noopener noreferrer" target="_blank">
                    {t('yel:elo-document-anchor')}
                </a>
            </Wrapper>

            <Wrapper>
                <Checkbox
                    checked={yelStoreData.termsAccepted}
                    onChange={toggleTerms}
                    label={
                        yelStoreData.whoPays === 'self'
                            ? t('yel:auth-self-confirmation')
                            : t('yel:auth-confirmation')
                    }
                />
            </Wrapper>

            <ButtonContainer>
                <EezyButton color="purple" onClick={handleCancel}>
                    {t('menu.back')}
                </EezyButton>
                <EezyButton
                    color="important"
                    dark
                    onClick={handleSubmit}
                    disabled={!yelStoreData.termsAccepted}
                >
                    {t('auth.continue-to-auth')}
                </EezyButton>
            </ButtonContainer>
        </>
    );
};

export default YelSummary;
