import React from 'react';
import { HighlightBox } from '../../components/Balloon';
import PopOver from '../../components/modals/PopOver';
import { UniqueTitleLeft } from '../../components/textElements';
import { HelpButton } from '../../styles/yelStyles';
import { formatCents } from '../../utils';
import { yelSummaryLink } from '../../utils/yel/variables';
import { useTranslation } from 'react-i18next';
import { useYelData } from 'queries/useYelData';

export const YelStartDescription = () => {
    const { t, i18n } = useTranslation();
    const { data } = useYelData();
    return (
        <>
            <UniqueTitleLeft>{t('yel:start-using-yel')}</UniqueTitleLeft>
            <p>{t('yel:eezy-saves-and-pays1')}</p>
            <p>
                {t('yel:eezy-saves-and-pays2', {
                    limitMin: formatCents(data.incomeLimits.min),
                })}
            </p>
            <p>
                <a href={yelSummaryLink(i18n.language)} rel="noopener noreferrer" target="_blank">
                    {t('yel:help.read-yel-insurance')}
                </a>
            </p>
        </>
    );
};

export const YelNotNeeded = () => {
    const { t } = useTranslation();
    return (
        <HighlightBox>
            <p>{t('yel:voluntary-insurance-description')}</p>
        </HighlightBox>
    );
};

export const YelEmergency = () => {
    const { t } = useTranslation();
    return (
        <>
            <h3>{t('yel:note-retro-payment')}</h3>
            <p>
                {t('yel:contact-support1')} <a href="tel:+358942475630">09 4247 5630</a> {t('general.or')}{' '}
                <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>
            </p>
            <p>{t('yel:contact-support2')}</p>
        </>
    );
};

export const YelExtraCharge = () => {
    const { t } = useTranslation();
    return (
        <>
            <h3>{t('yel:note-retro-payment')}</h3>
            <p>
                {t('yel:extra-charge-note')} <a href="tel:+358942475630">09 4247 5630</a> {t('general.or')}{' '}
                <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>
            </p>
            <p>{t('yel:contact-support2')}</p>
        </>
    );
};

export const RenewalNote = () => {
    const { t } = useTranslation();
    return (
        <>
            <p>{t('yel:note-data-transfer')}</p>
            <p>
                <PopOver
                    helpButton={<HelpButton>{t('yel:help.read-transfer')}</HelpButton>}
                    helpText={t('yel:help.transfer')}
                />
            </p>
        </>
    );
};

export const YelPayingNote = () => {
    const { t, i18n } = useTranslation();
    const { data } = useYelData();
    return (
        <div>
            {data.whoPays === 'self' ? (
                <p>{t('yel:summary-of-payment-2')}</p>
            ) : (
                <>
                    <p>{t('yel:summary-of-payment')}</p>
                    <p>{t('yel:note-withdrawal')}</p>
                </>
            )}

            <p>{t('yel:discount-disclaimer')}</p>
            {data.whoPays !== 'self' && (
                <p>
                    <a href={yelSummaryLink(i18n.language)} rel="noopener noreferrer" target="_blank">
                        {t('yel:help.read-collection')}
                    </a>
                </p>
            )}
        </div>
    );
};
