import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalActions, ModalContent } from './Modal';
import { trans } from '../../utils';
import { EezyButton } from '../Buttons';
import EezyPayTermsFi from './EezyPayTermsFi';
import EezyPayTermsEn from './EezyPayTermsEn';
import { COLOR_GREYHOUND } from '../../styles/variables';
import { FormCheckmark } from '../form/FormCheckmark';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IModalProps {
    handleModalClose: () => void;
    handleConfirm?: () => void;
    isOpen: boolean;
    termsAccepted: boolean;
    userLoadingComplete: boolean;
    readonly?: boolean;
}

const Section = styled.div`
    margin: 30px 0;

    p {
        color: ${COLOR_GREYHOUND};
        margin-bottom: 10px;
    }
    div {
        margin-bottom: 20px;
    }
    .p1 {
        margin-left: 5px;
    }
    ul > ul {
        margin-left: 10px;
    }
`;

const EezyPayTermsModal = (props: IModalProps) => {
    const { i18n } = useTranslation();

    const [termsRead, setTermsRead] = useState(false);
    const { isOpen, termsAccepted, userLoadingComplete, readonly, handleConfirm, handleModalClose } = props;

    return (
        <Modal
            small
            id="modal-eezy-pay-about"
            isOpen={isOpen}
            onClose={handleModalClose}
            title={trans('eezy-pay-terms-modal.title')}
        >
            <ModalContent>
                <Section>{i18n.language === 'fi' ? <EezyPayTermsFi /> : <EezyPayTermsEn />}</Section>
            </ModalContent>
            <ModalActions style={{ flexDirection: 'column' }}>
                {(termsAccepted && userLoadingComplete) || readonly ? (
                    <EezyButton color="purple" dark onClick={handleModalClose}>
                        {trans('menu.close')}
                    </EezyButton>
                ) : (
                    <>
                        <div>
                            <FormCheckmark
                                id="eezy-pay-terms"
                                label={trans('eezy-pay-terms-modal.checkbox-label')}
                                name="eezy-pay-terms"
                                checked={termsRead}
                                onChange={() => {
                                    setTermsRead(!termsRead);
                                }}
                                required
                                value={'acceptTerms'}
                            />
                        </div>
                        {handleConfirm && (
                            <div style={{ width: '75%', marginTop: 15 }}>
                                <EezyButton
                                    style={{ width: '100%' }}
                                    autoFocus
                                    color="purple"
                                    dark
                                    disabled={!termsRead}
                                    onClick={() => {
                                        handleConfirm();
                                        handleModalClose();
                                    }}
                                    type="submit"
                                >
                                    {trans('eezy-pay-terms-modal.button')}
                                </EezyButton>
                            </div>
                        )}
                    </>
                )}
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        termsAccepted: state.eezyPay.eezyPayTermsAccepted,
        userLoadingComplete: state.eezyPay.userLoadingComplete,
    };
};

export default connect(mapStateToProps, null)(EezyPayTermsModal);
