import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { IFile } from '../../../../shared/src/types/common';
import { uploadCostReceipt } from '../../actions/file';
import { DELETE_ATTACHMENT, GET_ATTACHMENTS } from '../../containers/costs/queries';
import { ITempFile } from '../../reducers/fileReducer';
import {
    COLOR_BLUM,
    COLOR_GREY_FOG,
    COLOR_GREYHOUND,
    COLOR_IMPORTANT,
    COLOR_WHITE_WALKER,
} from '../../styles/variables';
import { formatDate, trans } from '../../utils';
import { COST_RECEIPT_FILETYPES } from '../../utils/costs/costLogic';
import { EezyButton, EezyLink } from '../Buttons';
import { Flex } from '../Flex';
import { FormRow } from '../form';
import FileInput from '../form/FileInput';
import { Icon } from '../Icon';
import LoadingSpinner from '../Loading';
import { P, SingleText } from '../textElements';
import { Modal, ModalActions, ModalContent } from './Modal';

const FileRow = styled.div`
    & {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 18px 0;
    }
    &:not(:last-of-type) {
        border-bottom: 1px solid ${COLOR_GREY_FOG};
    }
    & div:first-of-type {
        display: flex;
        margin-right: 5px;
        max-width: calc(100% - 165px);
        p {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    button {
        margin-left: 10px;
    }
    ${SingleText} {
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const renderFile = (file: IFile, handleRemove: (file: ITempFile) => void, loading: boolean) => {
    return (
        <FileRow key={`file-${file.id}`}>
            <FormRow>
                <SingleText color="black" title={file.name}>
                    {file.name}
                </SingleText>
                <SingleText style={{ display: 'inline' }}>{formatDate(file.createTime)}</SingleText>
            </FormRow>
            <div>
                <EezyLink
                    color="purple"
                    href={file.url}
                    rel="noopener noreferrer"
                    square
                    style={{ padding: 0 }}
                    target="_blank"
                    width={75}
                >
                    <Icon icon={['far', 'eye']} /> {trans('file.show')}
                </EezyLink>
                <EezyButton
                    color="purple"
                    disabled={loading}
                    onClick={() => handleRemove(file)}
                    square
                    style={{ padding: 0 }}
                    width={75}
                >
                    <Icon icon={['far', 'trash-alt']} /> {trans('form.delete')}
                </EezyButton>
            </div>
        </FileRow>
    );
};

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    fileLoading: boolean;
    receiptFiles: ITempFile[];
    uploadReceipt: (data: FormData) => void;
}

const UploadFileModal = (props: IModalProps) => {
    const { data: attachmentsData, refetch: refetchAttachments } = useQuery(GET_ATTACHMENTS, {
        pollInterval: 2 * 60 * 1000,
    });
    const allUnusedAttachments = attachmentsData ? [...(attachmentsData?.allUnusedAttachments ?? [])] : [];
    const attachments = allUnusedAttachments.sort((a: IFile, b: IFile) => b.id - a.id) || [];

    React.useEffect(() => {
        // props.uploadReceipt() adds the file to the files list in file store
        // and update in that list fires the attachment refetch...
        refetchAttachments();
    }, [refetchAttachments, props.receiptFiles]);

    const [deleteAttachment, { loading: deleteLoading }] = useMutation(DELETE_ATTACHMENT, {
        onError: () => null,
        refetchQueries: () => [{ query: GET_ATTACHMENTS }],
    });

    const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            const uploadData = new FormData();
            uploadData.append('file', newFile);
            props.uploadReceipt(uploadData);
        }
    };

    const handleFileRemoval = (file: ITempFile) => {
        deleteAttachment({ variables: { attachmentId: file.id } });
    };

    return (
        <Modal
            id="modal-yel"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={trans('attachments.modal-title')}
        >
            <ModalContent>
                <P color={COLOR_GREYHOUND}>{trans('attachments.modal-1')}</P>
                <P color={COLOR_GREYHOUND}>{trans('attachments.modal-2')}</P>
                <P color={COLOR_GREYHOUND}>{trans('attachments.modal-3')}</P>
                {attachments.length > 0 && <P color={COLOR_GREYHOUND}>{trans('attachments.modal-4')}</P>}

                <Flex column style={{ marginTop: 30 }}>
                    {attachments.map((file: IFile) => {
                        return renderFile(file, handleFileRemoval, deleteLoading);
                    })}
                </Flex>
            </ModalContent>

            <ModalActions>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {trans('menu.close')}
                </EezyButton>

                <FileInput
                    accept={COST_RECEIPT_FILETYPES}
                    color={props.fileLoading ? COLOR_IMPORTANT : undefined}
                    onChange={handleAddFile}
                    style={{
                        backgroundColor: COLOR_BLUM,
                        borderRadius: 100,
                        color: COLOR_WHITE_WALKER,
                        textAlign: 'center',
                    }}
                >
                    {props.fileLoading ? (
                        <LoadingSpinner color="white" size="1em" />
                    ) : (
                        <span aria-hidden>{trans('file.upload')}</span>
                    )}
                    <span style={{ display: 'none' }}>{trans('file.upload-receipt')}</span>
                </FileInput>
            </ModalActions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => {
    return {
        fileLoading: state.file.loading,
        receiptFiles: state.file.costReceiptFiles,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        uploadReceipt: (data: FormData) => {
            dispatch(uploadCostReceipt(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileModal);
