import { IDropdownOption } from './../components/form/AutocompleteDropdown';

export const stripSpaces = (s: string) => s.replace(/\s/g, '');

export const convertStrToFloat = (s: string) => Math.round(parseFloat(s.replace(/\s/g, '')) * 100) / 100;

export const strToFloatOrZero = (a: string) => {
    const result = convertStrToFloat(a);
    return isNaN(result) ? 0 : result;
};

export const sortObjectsByLabel = (options: IDropdownOption[]) => {
    const copy = [...options];
    copy.sort((a, b) => a.label.localeCompare(b.label));
    return copy;
};

export const addLeadingZero = (s: string) => (s.length < 2 ? '0' + s : s);

export const stringOrEmpty = (s: string | null | undefined, defaultValue = ''): string => {
    return s || defaultValue
}
/**
 * Capitalize first letter of string.
 * Ex: "tammikuu" -> "Tammikuu"
 * @param text
 * @returns
 */
export const capitalize = (text: string): string => text[0].toUpperCase() + text.slice(1);
