import { useMutation, useQuery } from '@tanstack/react-query';
import API from 'utils/API';
import { IUserData } from '../../../shared/src/types/user';
import { changeLanguage } from 'i18next';
import { useRef } from 'react';

export const useUserQuery = () =>
    useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            const response = await API.get<IUserData | undefined>('/user');
            return response.data ?? null;
        },
    });

export const useUser = () => {
    return useUserQuery().data ?? null;
};

export const useChangeUserLanguageMutation = () => {
    const changeLanguageMutation = useMutation({
        mutationFn: async (lang: string) => {
            await API.put('/user', { language: lang });
        },
    });

    const timeoutRef = useRef<NodeJS.Timeout>();

    return (lang: string) => {
        changeLanguage(lang);

        // Debounce the language change to avoid unnecessary API calls
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => changeLanguageMutation.mutate(lang), 3000);
    };
};
