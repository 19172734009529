import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { saveTransactionsSearchQuery } from '../../actions/eezyPay';
import SearchField from '../../components/SearchField';
import { trans } from '../../utils';

const SEARCH_TIMEOUT = 1000;

interface ITransactionsSearch {
    onChange: (searchQuery: string) => void;
    searchQuery: string;
}
const TransactionsSearch = (props: ITransactionsSearch) => {
    const [query, setQuery] = React.useState('');
    const [searchTimer, setSearchTimer] = React.useState<NodeJS.Timeout>();

    React.useEffect(() => {
        setQuery(props.searchQuery);
    }, [props.searchQuery]);

    const handleChange = (val: string, fireImmediately?: boolean) => {
        setQuery(val);
        clearTimeout(searchTimer);
        if (fireImmediately) {
            props.onChange(val);
        } else {
            setSearchTimer(
                setTimeout(() => {
                    props.onChange(val);
                }, SEARCH_TIMEOUT)
            );
        }
    };
    return (
        <SearchField onChange={handleChange} placeholder={trans('eezyPay:payments.search')} query={query} />
    );
};

const mapStateToProps = (state: any) => {
    return {
        searchQuery: state.eezyPay.transactionsSearchQuery,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        onChange: (q: string) => {
            dispatch(saveTransactionsSearchQuery(q));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsSearch);
