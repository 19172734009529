import { Hidden } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { EezyButton } from '../../components/Buttons';
import { AddressInput, FormSection, SplitRow } from '../../components/form';
import { FormLabel } from '../../components/form/FormLabel';
import { Icon } from '../../components/Icon';
import { COLOR_IMPORTANT, SCREEN_S } from '../../styles/variables';
import { trans } from '../../utils';
import { round } from '../../utils/calc';
import { ICostComponentProps } from '../../utils/costs/costLogic';
import {
    checkDirectionsServices,
    getDirections,
    getDistance,
    IDirectionsResult,
} from '../../utils/mapsUtils';

const AddressWrapper = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    & > div {
        padding-left: 15px;
        width: 100%;
    }

    button {
        position: absolute;
        right: 0;
    }

    @media (min-width: ${SCREEN_S}px) {
        & {
            max-width: 50%;
        }
        & > div {
            padding-left: 5px;
            width: 100%;
        }
    }
`;

interface ITravelAddressesProps extends ICostComponentProps {
    children?: ReactNode;
}

export const TravelRoute = (props: ITravelAddressesProps) => {
    const { dispatch } = props;

    const getRouteDirections = React.useMemo(() => getDirections, []);

    const saveDirections = React.useCallback(
        (payload?: { route: string[]; directions: IDirectionsResult }) => {
            dispatch({ payload, type: 'SAVE_ROUTE_DIRECTIONS' });
        },
        [dispatch]
    );

    const getRouteSteps = React.useCallback(() => {
        return props.travelRoute.filter((a) => a.length > 0);
    }, [props.travelRoute]);

    const handleKmUpdate = React.useCallback(
        (val: number) => {
            dispatch({
                payload: { reimbursedKMs: { quantity: round(val) } },
                type: 'SAVE_TRAVEL',
            });
        },
        [dispatch]
    );

    const handleMapsDistanceUpdate = React.useCallback(
        (totalKMs: number) => {
            dispatch({
                payload: { totalKMs },
                type: 'SAVE_MAPS_DISTANCE',
            });
        },
        [dispatch]
    );

    React.useEffect(() => {
        // When route is changed, calculate new directions.
        // Distance is calculated based on the directions in TravelDistance.tsx.
        if (!checkDirectionsServices()) {
            return;
        }

        if (getRouteSteps().length < 2 || props.travelRoute === props.travelRouteDirections?.route) {
            return;
        }

        const route = props.travelRoute.filter((a) => a.length > 0);
        const origin = route[0];
        const waypoints =
            route.slice(1, -1).map((a: string) => {
                return { location: a };
            }) || [];
        const destination = route.slice(-1)[0];

        getRouteDirections({ destination, origin, travelMode: 'DRIVING', waypoints }, (results?: any) => {
            if (results.status === 'OK') {
                const distanceM = getDistance(results);
                handleKmUpdate(round(distanceM / 1000));
                handleMapsDistanceUpdate(round(distanceM / 1000));
                saveDirections({
                    directions: results,
                    route: props.travelRoute,
                });
            } else {
                handleKmUpdate(0);
                handleMapsDistanceUpdate(0);
                saveDirections(undefined);
            }
        });
    }, [
        props.travelRoute,
        props.travelRouteDirections,
        getRouteDirections,
        saveDirections,
        getRouteSteps,
        handleKmUpdate,
        handleMapsDistanceUpdate,
    ]);

    const handleAddAddress = () => {
        dispatch({
            payload: [...props.travelRoute, ''],
            type: 'SAVE_ROUTE',
        });
    };

    const handleDeleteAddress = (index: number) => {
        const newRoute = props.travelRoute.filter((a: string, i: number) => {
            return i !== index;
        });
        dispatch({ payload: newRoute, type: 'SAVE_ROUTE' });
    };

    const handleUpdateAddress = (index: number, address: string) => {
        const newRoute = props.travelRoute.map((a: string, i: number) => {
            if (i === index) {
                return address;
            }
            return a;
        });
        dispatch({ payload: newRoute, type: 'SAVE_ROUTE' });
    };

    return (
        <div>
            <FormSection>
                {props.travelRoute.map((address: string, index) => {
                    return (
                        <SplitRow key={`address-${address}-${index}`}>
                            <FormLabel value={!!address} htmlFor={`route-${index}`}>
                                {trans('costs.address')} {index + 1}
                            </FormLabel>
                            <AddressWrapper>
                                <AddressInput
                                    freeSolo
                                    id={`route-${index}`}
                                    onBlur={(val: string) => {
                                        handleUpdateAddress(index, val);
                                    }}
                                    onChange={(val: string, event: any) => {
                                        /*
                                        Skip saving if user is typing address. Otherwise this input
                                        will re-render and focus will be lost. Instead do saving
                                        when user clicks address or when input is blurred.
                                         */
                                        if (!(event.nativeEvent instanceof InputEvent)) {
                                            handleUpdateAddress(index, val);
                                        }
                                    }}
                                    placeholder={trans(`costs.address-placeholders.${index < 2 ? index : 2}`)}
                                    suggestedValue={
                                        index === 0
                                            ? props.addressSuggestions?.home || ''
                                            : props.addressSuggestions?.client || ''
                                    }
                                    value={address}
                                />
                                <EezyButton
                                    aria-label={trans('costs.address-remove', {
                                        address,
                                    })}
                                    color="purple"
                                    onClick={() => handleDeleteAddress(index)}
                                    square
                                    style={{ marginLeft: 5, padding: 0 }}
                                    width={30}
                                >
                                    <Icon
                                        style={{
                                            color: COLOR_IMPORTANT,
                                        }}
                                        icon={['far', 'trash-alt']}
                                    />
                                </EezyButton>
                            </AddressWrapper>
                        </SplitRow>
                    );
                })}

                <SplitRow style={{ justifyItems: 'baseline' }}>
                    <EezyButton color="purple" hasIcon onClick={handleAddAddress} square width={115}>
                        <Icon icon={['far', 'plus']} />
                        {trans('costs.add-address')}
                    </EezyButton>
                    {props.children && <Hidden smDown>{props.children}</Hidden>}
                </SplitRow>
                {props.children && (
                    <Hidden smUp>
                        <div style={{ marginTop: 15 }}>{props.children}</div>
                    </Hidden>
                )}
            </FormSection>
        </div>
    );
};
