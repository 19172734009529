import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
    COLOR_BLUM,
    COLOR_IMPORTANT,
    COLOR_STATUS_DONE,
    COLOR_WHITE_WALKER,
    SCREEN_S,
} from 'styles/variables';

const ToastMsg = styled.div`
    @media (max-width: ${SCREEN_S}px) {
        flex-direction: column;
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

type WithholdingTaxToastProps = {
    taxApiMessage: string;
    isDataError: boolean;
    isTechError: boolean;
};

const WithholdingTaxToast = (props: WithholdingTaxToastProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <ToastMsg>
            <Flex style={{ margin: '10px 0' }}>
                <Icon
                    color={props.isTechError || props.isDataError ? COLOR_IMPORTANT : COLOR_STATUS_DONE}
                    icon={
                        props.isTechError || props.isDataError
                            ? ['fas', 'triangle-exclamation']
                            : ['far', 'rotate']
                    }
                    style={{ margin: 'auto 14px auto 0' }}
                />
                {props.taxApiMessage}
            </Flex>
            {!props.isDataError && !props.isTechError && (
                <Flex style={{ margin: 10 }}>
                    <EezyButton color="purple" onClick={() => undefined}>
                        {t('menu.close')}
                    </EezyButton>
                    <EezyButton
                        onClick={() => {
                            navigate('/salaries');
                        }}
                        color="important"
                        style={{
                            backgroundColor: COLOR_BLUM,
                            color: COLOR_WHITE_WALKER,
                        }}
                    >
                        <Flex
                            style={{
                                marginLeft: 22,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {t('general.show')}
                            <Icon
                                color={COLOR_WHITE_WALKER}
                                icon={['far', 'arrow-right']}
                                style={{ marginLeft: 15, marginRight: 5 }}
                            />
                        </Flex>
                    </EezyButton>
                </Flex>
            )}
            {props.isDataError && (
                <a
                    href={
                        i18n.language === 'en'
                            ? 'https://www.vero.fi/en/e-file/mytax/'
                            : 'https://www.vero.fi/sahkoiset-asiointipalvelut/omavero/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <EezyButton
                        color="important"
                        style={{
                            backgroundColor: COLOR_BLUM,
                            color: COLOR_WHITE_WALKER,
                            margin: '0 10px 10px 10px',
                        }}
                    >
                        {t('withholdingTax.noDataErrorButton')}
                    </EezyButton>
                </a>
            )}
        </ToastMsg>
    );
};

export default WithholdingTaxToast;
