import React, { ChangeEvent, HTMLProps, MouseEvent } from 'react';

import { IInvoiceItem } from '../../../../../shared/src/types/invoice';
import { FormRadio, FormSection } from '../../../components/form';
import { IDropdownOption } from '../../../components/form/AutocompleteDropdown';
import { FormCheckmarkRow } from '../../../components/form/FormCheckmark';
import { trans } from '../../../utils';
import { vatOptions } from '../../../utils/invoice/invoiceLogic';
import { IInvoiceTemplate } from '../../../utils/invoice/invoiceTemplates';
import { vatHelpTexts } from '../InvoiceRowDetails';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const VatHighlighter = (props: any) => <div {...props} />;
const TempVatHighlighter = styled(VatHighlighter)`
    .grid > *:nth-child(4) {
        position: relative;

        label {
            text-decoration: underline;
        }

        &:after {
            content: '${(props: any) => props.newVatHintMessage}';
            position: absolute;
            display: none;
            bottom: 100%;
            left: 0;
            width: 300px;
            margin-bottom: 0.25rem;
            padding: 0.25rem 1.5rem;
            border: 1px solid #ccc;
            background: #fff;
            box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
            border-radius: 4px;
        }
        &:hover:after {
            display: block;
        }
    }
`;

type Props = HTMLProps<HTMLDivElement> & {
    item: IInvoiceItem;
    vatType: string;
    error: any;
    vatList?: number[];
    template: IInvoiceTemplate;
    setVatType: (val: string) => void;
    handleChange: (val: string | number, name: string) => void;
};

const VatPopOver: React.FC<Props> = (props: Props) => {
    const { t, i18n } = useTranslation();

    const vatRateValue = props.item.vat?.toString() ?? '';

    const rowVatOptions: IDropdownOption[] = vatOptions(props.item.itemType, props.vatList, props.template);

    const VatOptions = [
        { label: trans('invoice.rows-form.vat-type-without-vat'), value: 'without-vat' },
        { label: trans('invoice.rows-form.vat-type-with-vat'), value: 'with-vat' },
    ];

    const vatDisplayStyles = rowVatOptions.length >= 4 ? 'grid grid-cols-2 gap-1' : 'grid grid-cols-3 gap-1';

    const onChangeVatRate = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
        props.handleChange(parseFloat(e.currentTarget.value), e.currentTarget.name);
    };

    return (
        <div style={props.style}>
            <FormSection style={{ marginTop: 20 }}>
                <FormRadio
                    helpTexts={['invoice.rows-form.help.vatType.1', 'invoice.rows-form.help.vatType.2']}
                    label={trans('invoice.rows-form.vat-q')}
                    name="vatType"
                    onChange={props.setVatType}
                    onTwoLines
                    options={VatOptions}
                    value={props.vatType}
                />
            </FormSection>

            <TempVatHighlighter newVatHintMessage={t('invoice.rows-form.vat-new-rate-hint-message')}>
                <FormSection style={{ marginTop: 20 }}>
                    <FormCheckmarkRow
                        className={vatDisplayStyles}
                        required
                        error={props.error}
                        helpTexts={vatHelpTexts(props.item.itemType)?.helpTexts}
                        helpLink={vatHelpTexts(props.item.itemType, i18n.language)?.helpLink}
                        name="vat"
                        onChange={onChangeVatRate}
                        options={rowVatOptions}
                        value={vatRateValue}
                    />
                </FormSection>
            </TempVatHighlighter>
        </div>
    );
};

export default VatPopOver;
