import styled from 'styled-components';
import {
    COLOR_BLUM,
    COLOR_BLUM_30,
    COLOR_LIGHT_BG,
    COLOR_WHITE_WALKER,
    DARK_GRADIENT,
    DRAWERWIDTH,
    DRAWERWIDTH_MAX,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    INVOICE_WIDTH,
    LILA_GRADIENT,
    PAGEWIDTH_MAX,
    SCREEN_M,
    TOPBAR_HEIGHT,
} from 'styles/variables';
import { BaseButtonStyles } from 'components/Buttons';
import SideMenuBar from 'components/SideMenuBar';
import { UniqueTitle } from 'components/textElements';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { List } from 'components/layout/List';

export const transitions = {
    inCloseButton: { fade: 500, wait: 300 },
    inLogo: { fade: 200, wait: 200 },
    inMenu: { fade: 200, wait: 0 },
    inMenuContent: { fade: 200, wait: 100 },
    inMenuItem: { fade: 300, wait: 50 },
};

const mainMarginTop = 20;
const mainMarginBottom = 20;

export const Main = styled.main`
    /* The content provided by router */
    min-height: calc(100vh - ${TOPBAR_HEIGHT + mainMarginTop + mainMarginBottom + FOOTER_HEIGHT_MOBILE}px);
    padding: 8px;
    width: 100%;
    margin-bottom: ${mainMarginBottom}px;

    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + mainMarginTop + mainMarginBottom + FOOTER_HEIGHT}px);
        margin-top: 0;
        margin-bottom: 0;
    }
`;
Main.displayName = 'Main';

export const MenuButton = styled.button`
    ${BaseButtonStyles};
    && {
        height: auto;
    }
    && svg {
        font-size: 20px;
        height: 20px;
        vertical-align: middle;
        width: 20px;
    }
    svg:first-of-type {
        margin-right: 10px;
    }
    h3 {
        display: initial;
        vertical-align: middle;
    }
`;

const StyledDrawer = styled(SwipeableDrawer)`
    .MuiBackdrop-root {
        background: ${DARK_GRADIENT};
    }
    .MuiDrawer-paper {
        width: 100%;
        @media (min-width: ${DRAWERWIDTH}px) {
            width: ${DRAWERWIDTH}px;
        }
        @media (min-width: ${SCREEN_M}px) {
            max-width: calc((${DRAWERWIDTH_MAX}px) + 15px);
            width: calc(100vw - (${INVOICE_WIDTH}px) - 45px);
        }
        @media (min-width: ${PAGEWIDTH_MAX}px) {
            left: calc((100vw - ${PAGEWIDTH_MAX}px) / 2);
            margin-left: -7px;
        }
    }
    ${SideMenuBar} {
        img.logo {
            height: 65px;
            margin-top: 142px;
            width: 65px;
        }
    }
`;

export const MenuList = styled(List)`
    overflow-y: initial;
    max-height: unset !important;

    a:not(.new-message-link) {
        line-height: 48px;
        text-decoration: none;
    }
    a.fullwidth {
        width: 100%;
    }
    a:focus {
        outline: none;
    }
    li {
        color: ${COLOR_BLUM};
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        height: 51px;
        line-height: 15px;
        padding: 0 30px 0 60px;

        &:hover,
        &.active,
        &.selected,
        &:focus-within {
            background: ${LILA_GRADIENT};
        }

        &.small {
            font-weight: normal;
            font-size: 12px;
            height: 32px;
            line-height: 20px;
            text-transform: none;
        }

        &.icon {
            padding-left: 30px;
        }

        button {
            color: inherit;
            text-transform: inherit;
            font: inherit;
        }
    }
`;

export const NavContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0 30px 0;
    h1 {
        min-height: 90px;
        padding: 30px 15px 30px 30px;
        text-align: start;
    }
    && hr {
        background-color: ${COLOR_BLUM_30};
        margin: 20px 30px;
    }
`;

export const FeedbackButton = styled.button<{ left: boolean }>`
    display: flex;
    justify-content: center;
    align-content: center;
    background: transparent;
    border-radius: 34px;
    height: 32px;
    padding-top: 1px;
    width: 32px;
    margin-right: 15px;
    @media (max-width: ${SCREEN_M}px) {
        margin-right: ${(props) => (props.left ? '125px' : '15px')};
    }
`;

export const ToggleIntroButton = styled.button`
    display: flex;
    justify-content: center;
    align-content: center;
    background: transparent;
    border-radius: 34px;
    height: 32px;
    padding-top: 1px;
    width: 32px;
    margin-right: 33px;

    & {
        @media (max-width: ${SCREEN_M}px) {
            margin-right: 15px;
        }
    }
`;

export const NavItemBadge = styled.span`
    display: inline-block;
    background-color: ${COLOR_BLUM};
    height: 17px;
    line-height: 17px;
    padding: 0 5px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 600;
    color: ${COLOR_WHITE_WALKER};
    vertical-align: middle;
    margin-left: 10px;
`;

export const LanguageOptionsWrapper = styled.div`
    margin-right: 32px;

    & {
        @media (max-width: ${SCREEN_M}px) {
            display: none;
        }
    }
`;

export const LanguageOptionsWrapperMobile = styled.div`
    display: none;

    & {
        @media (max-width: ${SCREEN_M}px) {
            display: block;
        }
    }
`;

export const WithSubtext = styled.span`
    line-height: 1;
    display: flex;
    flex-direction: column;
`;

export const UserNameWrapper = styled(UniqueTitle)`
    flex: 1;

    @media (max-width: ${SCREEN_M}px) {
        font-size: 22px;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        min-height: auto !important;
    }
`;

export const LogoHolder = styled.div`
    width: 65px;
    margin-right: 30px;
    align-self: center;

    @media (max-width: ${SCREEN_M}px) {
        width: 50px;
    }
`;

export const StyledStyledDrawer = styled(StyledDrawer)`
    .MuiDrawer-paperAnchorLeft {
        background: ${COLOR_LIGHT_BG};
        box-shadow: 2px 0 0 rgba(0, 0, 0, 0.1);
        transition: opacity 1s linear;
    }
`;
