import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { showModals } from 'actions/auth';
import { IRootState } from 'reducers';
import { useUser } from 'queries/useUserQuery';

interface IEezyPayTermsCheckerProps {
    children: ReactNode;
}

const EezyPayTermsChecker: FC<IEezyPayTermsCheckerProps> = ({ children }) => {
    const dispatch = useDispatch();
    const user = useUser();
    const termsAccepted = useSelector((state: IRootState) => state.eezyPay.eezyPayTermsAccepted);
    const userLoadingComplete = useSelector((state: IRootState) => state.eezyPay.userLoadingComplete);

    useEffect(() => {
        if (!termsAccepted && user?.isIdentified && userLoadingComplete) {
            dispatch(showModals(['EEZY_PAY']));
        }
    }, [user, termsAccepted, dispatch, userLoadingComplete]);

    return user?.isIdentified ? <>{children}</> : <Navigate to="/" />;
};

export default EezyPayTermsChecker;
