export const COLOR_BLUM = '#351f67';
export const COLOR_BLUM_10D = '#1C064C';
export const COLOR_BLUM_30 = 'rgba(53, 31, 103, 0.3)';
export const COLOR_BLUMER = '#2A1953';
export const COLOR_GRIPE = '#432884';
export const COLOR_TINT_GRIPE = '#432884';
export const COLOR_BLACKWATER = '#000000';
export const COLOR_WHITE_WALKER = '#ffffff';
export const COLOR_WHITE_GRAY = '#f9f9f9';
export const COLOR_GREYS_ANATOMY = '#f6f6f6';
export const COLOR_GREY_FOG = '#dddddd';
export const COLOR_GREYJOY = '#707070';
export const COLOR_GREYHOUND = '#5c5c5c';
export const COLOR_DARK_GRAY = '#404040';
export const COLOR_GRAYS_ANATOMY = '#f6f6f6';

export const COLOR_EEZY_GREEN_50 = '#F4F9F8';
export const COLOR_EEZY_GREEN_100 = '#D9EEE9';
export const COLOR_EEZY_GREEN_400 = '#5BA698';
export const COLOR_EEZY_GREEN_500 = '#418B7E';
export const COLOR_EEZY_GREEN_600 = '#326F65';
export const COLOR_EEZY_GREEN_700 = '#2B5A53';
export const COLOR_EEZY_VIOLOET_50 = '#F6F5FD';
export const COLOR_EEZY_VIOLOET_950 = '#351F65';
export const COLOR_EEZY_GRAY_200 = '#E5E7EB';
export const COLOR_EEZY_GRAY_300 = '#D1D5DB';
export const COLOR_EEZY_GRAY_400 = '#9CA3AF';
export const COLOR_EEZY_GRAY_500 = '#6B7280';
export const COLOR_EEZY_GRAY_800 = '#1F2937';
export const COLOR_EEZY_BORDER_GRAY_200 = '#E5E7EB';

export const COLOR_LILA = '#D7B4F5';
export const COLOR_LILA_MEDIUM = 'rgba(215, 180, 245, 0.7)';
export const COLOR_LILA_LIGHT = 'rgba(215, 180, 245, 0.4)';
export const COLOR_LILA_LIGHTEST = '#E6E0F0'; //new color added for EezyPay pricing table
export const COLOR_LIGHT_BG = '#E2E0F0';
export const COLOR_MEDIUM_BG = '#D5D1EF';
export const COLOR_DARKER_BG = '#C5C1E1';
export const COLOR_DARK_BG = '#A8A2D2';

export const COLOR_IMPORTANT = '#F7726F';
export const COLOR_IMPORTANT_DARKER = '#EC2632';
export const COLOR_IMPORTANT_MEDIUM = 'rgba(255, 80, 90, 0.7)';
export const COLOR_IMPORTANT_LIGHT = 'rgba(255, 80, 90, 0.4)';
export const COLOR_IMPORTANT_LIGHTEST = 'rgba(255, 80, 90, 0.1)';

export const COLOR_STATUS_DONE = '#8EC8BB';
export const COLOR_STATUS_DONE_MEDIUM = 'rgba(180, 235, 70, 0.7)';
export const COLOR_STATUS_DONE_LIGHT = 'rgba(180, 235, 70, 0.4)';
export const COLOR_STATUS_WAITING = '#F4A17E';
export const COLOR_STATUS_WAITING_MEDIUM = 'rgba(255, 150, 0, 0.7)';
export const COLOR_STATUS_WAITING_LIGHT = 'rgba(255, 150, 0, 0.4)';

export const COLOR_SALES_STATS_CARD = '#F3F4F6';
export const COLOR_SALES_STATS_CARD_ICON = COLOR_EEZY_GREEN_400;
export const COLOR_SALES_STATS_CARD_DESC = '#4B5563';
export const COLOR_SALES_STATS_CARD_AMOUNT = COLOR_EEZY_VIOLOET_950;

export const COLOR_WARINING_BACKGROUND = '#F6B700';
export const COLOR_BLACK = '#000000';

export const COLOR_DARK_TABLE = '#352066';

export const FOCUS_COLOR = COLOR_IMPORTANT;

export const SCREEN_L = 1280;
export const SCREEN_M = 1110;
export const SCREEN_S = 600;
export const SCREEN_XS = 425;

export const DARK_GRADIENT = 'linear-gradient(176.1deg, rgba(0, 0, 0, 0.2) 70.8%, rgba(0, 0, 0, 0) 101.38%)';
export const PURPLE_GRADIENT = `linear-gradient(90.85deg, rgba(255, 255, 255, 0.35) -80.32%, rgba(255, 255, 255, 0) 123.28%)`;
export const LIGHT_GRADIENT = 'linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.02) 100%);';
export const LIGHT_GRADIENT_INVERT = 'linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.02) 0);';
export const LILA_GRADIENT =
    'linear-gradient(96.21deg, rgba(215, 180, 245, 0.7) -13.9%, rgba(215, 180, 245, 0) 83.19%)';
export const LILA_GRADIENT_LT =
    'linear-gradient(96.21deg, rgba(215, 180, 245, 0) -13.9%, rgba(215, 180, 245, 0.7) 83.19%)';
export const COLOR_JAFFA = `linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), #F6B700`;
export const TRANS_WHITE = 'rgba(255, 255, 255, 0.6)';
export const TRANS_WHITE_7 = 'rgba(255, 255, 255, 0.7)';

export const DRAWERWIDTH = 400;
export const DRAWERWIDTH_MAX = 550;

export const PAGEWIDTH_MAX = 1330;
export const INVOICE_WIDTH = 840;

export const BORDER_RADIUS = '10px';

export const TOPBAR_HEIGHT = 60;

export const BORDER_ACTIVE_FIELD = `2px solid ${COLOR_IMPORTANT}`;
export const BORDER_REQUIRED_FIELD = `2px solid ${COLOR_GREY_FOG}`;
export const BORDER_OPTIONAL_FIELD = `2px dotted ${COLOR_GREY_FOG}`;

export const FOOTER_HEIGHT = 120;
export const FOOTER_HEIGHT_MOBILE = 80;

export const BANNER_TITLE_COLOR = COLOR_EEZY_GRAY_800;
export const BANNER_DESCRIPTION_COLOR = '#4B5563';
