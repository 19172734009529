import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_DARK_GRAY } from '../../styles/variables';
import { tokenize } from '../../utils';

const StyledInput = styled(TextField).attrs(() => ({
    autoComplete: 'off',
}))`
    && div::before,
    && div::after,
    && div:hover::before {
        border-bottom: 2px solid ${COLOR_BLUM};
    }

    && label {
        color: ${COLOR_BLACKWATER};
    }
`;

interface ITextInputProps {
    endAdornment?: string;
    label: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    readonly?: boolean;
    type?: string;
    value: string | number;
}

export const TextInput = (props: ITextInputProps) => {
    const { endAdornment, label, onChange, readonly, type, value } = props;

    return (
        <StyledInput
            defaultValue={value}
            id={tokenize(label)}
            InputProps={{
                endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
                readOnly: readonly || false,
                type: type || 'text',
            }}
            label={label}
            margin="normal"
            onChange={onChange}
        />
    );
};

export const Input = styled.input`
    color: ${COLOR_DARK_GRAY};
    font-size: 15px;
    max-height: 24px;
    max-width: 40%;
    padding: 0;
    text-align: right;

    &:focus {
        border-bottom: none;
    }
`;
