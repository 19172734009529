import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { saveSearchQuery } from '../../actions/salary';
import SearchField from '../../components/SearchField';
import { useTranslation } from 'react-i18next';

const SEARCH_TIMEOUT = 1000;

interface ISalarySearch {
    onChange: (searchQuery: string) => void;
    searchQuery: string;
}
const SalarySearch = (props: ISalarySearch) => {
    const { t } = useTranslation();
    const [query, setQuery] = React.useState('');
    const [searchTimer, setSearchTimer] = React.useState<NodeJS.Timeout>();

    React.useEffect(() => {
        setQuery(props.searchQuery);
    }, [props.searchQuery]);

    const handleChange = (val: string, fireImmediately?: boolean) => {
        setQuery(val);
        clearTimeout(searchTimer);
        if (fireImmediately) {
            props.onChange(val);
        } else {
            setSearchTimer(
                setTimeout(() => {
                    props.onChange(val);
                }, SEARCH_TIMEOUT)
            );
        }
    };
    return (
        <SearchField
            onChange={handleChange}
            placeholder={t('salaries:search')}
            query={query}
            tooltip={t('salaries:searchTooltip')}
        />
    );
};

const mapStateToProps = (state: any) => {
    return {
        searchQuery: state.salary.searchQuery,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        onChange: (q: string) => {
            dispatch(saveSearchQuery(q));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalarySearch);
