import moment, { Moment } from 'moment';

interface IUserEvent {
    userId: number;
    name: string;
    category: string;
    created?: string;
    updated?: string;
    additionalInfo?: string;
    id?: number;
}

export const dateFormatWithWeekday = 'dd L';
const minutesInDay = 1440; // 24h

export const currentYear = () => {
    return moment().year();
};

export const currentMonth = () => {
    return moment().month() + 1;
};

export const currentDate = () => {
    return moment().format('YYYY-MM-DD');
};

export const daysBetween = (dateNow: Moment, date: string | Date | null) => {
    if (!date) {
        return 0;
    }
    const now = moment(dateNow);
    return Math.abs(now.diff(moment(date, 'YYYY-MM-DD'), 'days'));
};

export const daysLeftForSubscription = (subscriptionEndDate: string | Date | null) => {
    if (!subscriptionEndDate) {
        return -1;
    }
    const now = +new Date();
    const endDate = +new Date(subscriptionEndDate).setHours(23, 59, 59, 999);
    const diffTime = endDate - now;
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

export const calcOrderProcessingMinutesDependingOnWeekday = (minutesSinceBuyClick?: number): number => {
    const weekday = moment().day();
    if (!minutesSinceBuyClick) {
        return minutesInDay;
    }
    if (weekday === 1) {
        if (minutesSinceBuyClick < minutesInDay) {
            return minutesInDay;
        } else if (minutesSinceBuyClick < 2 * minutesInDay) {
            return 2 * minutesInDay;
        } else {
            return 3 * minutesInDay;
        }
    } else if (weekday === 6 || weekday === 7) {
        return 3 * minutesInDay;
    }
    return minutesInDay;
};

/**
 * Calculates the difference in hours between two dates.
 * If the difference is an exact number of hours, it subtracts 1 from the result.
 * If there's more than 1 minute over a full hour, it counts that hour.
 *
 * Examples:
 * - 10:00 hours difference returns 9 hours.
 * - 10:01 hours difference returns 10 hours.
 * - 9:59 hours difference returns 9 hours.
 */
export const hoursBetween = (date1: string, date2: string) => {
    if (!date1 || !date2) {
        return 0;
    }

    // Get the difference in minutes
    const diffInMinutes = Math.abs(moment(date1).diff(moment(date2), 'minutes'));

    // Calculate full hours
    const fullHours = Math.floor(diffInMinutes / 60);

    // Determine the extra minutes
    const extraMinutes = diffInMinutes % 60;

    // Adjust the hour count based on the extra minutes
    return extraMinutes === 0 ? fullHours - 1 : fullHours;
};

export const monthsSince = (dateNow: Moment, date: string) => {
    if (!date) {
        return 0;
    }
    const now = moment(dateNow);
    return now.diff(moment(date), 'months');
};

export const formatDate = (date: Date | string, dateFormat?: string) => {
    const format = dateFormat || 'l';
    return date ? moment(date).format(format) : '';
};

export const formatDateAndTime = (date: string) => {
    return date ? moment(date).format('lll') : '';
};

export const formatDay = (date: Date | string) => {
    return date ? moment(date).format('D.M.') : '';
};

export const formatTime = (date: Date | string) => {
    return date ? moment(date).format('H:mm') : '';
};

export const formatDateISO = (dateValue: Date | string): string => {
    let date: Date;
    typeof dateValue === 'string' ? (date = new Date(dateValue)) : (date = dateValue);
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(
        -2
    )}`;
};

export const formatCalendarTime = (date: string) =>
    date
        ? moment(date).calendar({
              lastWeek: 'dd, l',
          })
        : '';

export const xNotAfterYInDays = (x: Date, y: Date) => {
    const newX = new Date(x).setHours(0, 0, 0, 0);
    const newY = new Date(y).setHours(0, 0, 0, 0);
    return moment(newY).diff(moment(newX), 'days') >= 0;
};

export const dateIsValid = (d?: Date) => {
    if (!d) {
        return false;
    }
    return !isNaN(d.getTime());
};

export const addYearsTo = (years: number, date: Date) => {
    // adds year and removes 1 day, or removes year and adds one day
    const d = new Date(date.setFullYear(date.getFullYear() + years));
    const d2 = new Date(d.setDate(d.getDate() - (years > 0 ? 1 : -1)));
    return new Date(d2.setHours(0, 0, 0, 0));
};

export const addDaysTo = (days: number, date: Date) => {
    const future = new Date(date.setDate(date.getDate() + days));
    return new Date(future.setHours(0, 0, 0, 0));
};

export const addDay = (date?: string): string | undefined =>
    date ? moment(date).add(1, 'days').format('YYYY-MM-DD') : date;

export const addDayToDatetime = (date?: string) => (date ? moment(date).add(1, 'days').toISOString() : date);

export const addDays = (days: number) => {
    return addDaysTo(days, new Date());
};

export const minDate = (date1: Date, date2: Date) => {
    if (date1.getTime() < date2.getTime()) {
        return date1;
    }
    return date2;
};

export const dateOrDefault = (date?: string, defaultReturn?: any) => {
    if (date) {
        return new Date(date);
    } else {
        return defaultReturn;
    }
};

export const onSameDay = (date1?: string, date2?: string) => {
    return formatDate(date1 || '') === formatDate(date2 || '');
};

export const getMonthName = (month: number) => {
    return moment().month(month).format('MMMM');
};

export const subtractTwoWeeks = (date: string): string => {
    return moment(date).subtract(2, 'weeks').toISOString();
};

const getMinutesSinceUTCTime = (timeUTC?: string): number =>
    (new Date().getTime() - new Date(`${timeUTC?.replace(/-/g, '/')} GMT`).getTime()) / (1000 * 60);

export const getMinutesSinceLastEvent = (
    lastUserEvents: {
        userEventsList: IUserEvent[];
    },
    eventName: string
) => {
    const lastEventTimeUTC = lastUserEvents?.userEventsList?.find(
        (event: IUserEvent) => event.name === eventName
    )?.created;
    return getMinutesSinceUTCTime(lastEventTimeUTC);
};
