import { IClient, IContactPerson, IInputClient } from './../../../../shared/src/types/invoice';

export interface IRecipientFormState {
    recipient: IClient;
    selectedContactPersonIndex?: number;
}

export const initialClient: IClient = {
    active: true,
    address: {
        country: 'FI',
        street1: '',
        street2: '',
        town: '',
        zipCode: '',
    },
    businessId: '',
    contactPeople: [],
    einvoiceOvt: '',
    operator: '',
    email: '',
    firstName: '',
    id: undefined,
    invoiceLanguage: 'fi',
    invoicingMethod: 'email',
    lastName: '',
    name: '',
    type: 'company',
};

const initialClientInput: IInputClient = {
    address: {
        country: 'FI',
        street1: '',
        street2: '',
        town: '',
        zipCode: '',
    },
    invoiceLanguage: 'fi',
    invoicingMethod: 'email',
    type: 'company',
};

export const initialRecipientForm: IRecipientFormState = {
    recipient: initialClientInput,
};

const findCpById = (id: number | undefined, contactPeople: IContactPerson[]): number | undefined => {
    return contactPeople
        .map((c: IContactPerson, index: number) => ({
            ...c,
            index,
        }))
        .find((c: IContactPerson) => c.id === id)?.index;
};

export const recipientReducer = (state: IRecipientFormState, action: any): IRecipientFormState => {
    switch (action.type) {
        case 'INIT':
            return {
                ...state,
                recipient: action.payload.recipient || initialRecipientForm,
                selectedContactPersonIndex: findCpById(
                    action.payload.selectedContactPersonId,
                    action.payload.recipient.contactPeople || []
                ),
            };
        case 'UPDATE_RECIPIENT':
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    ...action.payload,
                },
            };

        case 'UPDATE_ADDRESS':
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    address: {
                        ...state.recipient.address,
                        ...action.payload,
                    },
                },
            };
        case 'UPDATE_CONTACT_PEOPLE':
            return {
                ...state,
                recipient: {
                    ...state.recipient,
                    contactPeople: action.payload.contactPeople,
                },
                selectedContactPersonIndex: action.payload.selectedContactPersonIndex,
            };

        default:
            return state;
    }
};

export const findSelectedContactPersonId = (
    selectedContactPersonIndex?: number,
    formContactPeople?: IContactPerson[],
    contactPeopleWithIds?: IContactPerson[]
): number | null => {
    const selectedCP = formContactPeople?.find(
        (c: IContactPerson, index: number) => index === selectedContactPersonIndex
    );
    if (!selectedCP) {
        return null;
    }
    const cp = contactPeopleWithIds?.find(
        (c: IContactPerson) => c.firstName === selectedCP.firstName && c.lastName === selectedCP.lastName
    );
    return cp?.id || null;
};
