import moment from 'moment';
import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { DateRange } from 'react-day-picker';
import { DateFilter, dateRangeToString, getTimeLabel, isWithinSingleMonth } from 'utils/statistics/dateUtils';
import { CHART_COMPARE_COLORS, ChartColor } from './statisticConstants';
import { LabelValueType, SalesPageStatisticData, StatisticInvoice } from './statisticsPageTypes';

export const briefInvoiceToStatisticInvoice = (invoice: IBriefInvoice): StatisticInvoice => ({
    id: invoice.id,
    date: invoice.invoiceDate ? moment(invoice.invoiceDate).format('YYYY-MM-DD') : '',
    updatedDate: invoice.updateDate,
    status: invoice.status,
    total: invoice.total,
    totalWithVat: invoice.totalWithVat,
    paidAmount: invoice.paidAmount ?? 0,
    clientName: invoice?.name?.trim(),
});

export const getNewDataColor = (usedColors: string[]) =>
    CHART_COMPARE_COLORS.find((c) => usedColors.every((item) => item !== c)) || '#85e4eb';

export const checkInvoiceAcceptForStatistics = (invoice: StatisticInvoice) =>
    ['completed', 'paid', 'overdued'].includes(invoice.status);

// Filter invoices that are not accepted for statistics, and handle partly credited invoices
export const handleInvoicesForStatistics = (invoices: StatisticInvoice[]): StatisticInvoice[] =>
    invoices.filter(checkInvoiceAcceptForStatistics).map((i) => {
        const credit = calculateCreditedPrices(i);
        if (i.status === 'paid' && credit.creditedPriceWithVAT < i.totalWithVat) {
            return {
                ...i,
                total: credit.creditedPriceWithoutVAT,
                totalWithVat: credit.creditedPriceWithVAT,
            };
        }

        return i;
    });

export const calculateCreditedPrices = (invoice: StatisticInvoice | IBriefInvoice) => {
    const originalPriceWithVAT = invoice.totalWithVat;
    const originalPriceWithoutVAT = invoice.total;
    const paidAmount = invoice.paidAmount ?? 0;

    // Calculate the VAT percentage
    const vatPercentage = ((originalPriceWithVAT - originalPriceWithoutVAT) / originalPriceWithoutVAT) * 100;

    // Calculate the credited price without VAT
    const creditedPriceWithoutVAT = paidAmount / (1 + vatPercentage / 100);

    // Calculate the credited price with VAT
    const creditedPriceWithVAT = paidAmount;

    return {
        creditedPriceWithoutVAT: +creditedPriceWithoutVAT.toFixed(2),
        creditedPriceWithVAT: +creditedPriceWithVAT.toFixed(2),
        vatPercentage: +vatPercentage.toFixed(2),
    };
};

export const groupInvoices = (
    invoices: StatisticInvoice[],
    getKey: (item: StatisticInvoice) => string
): { [key: string]: StatisticInvoice } => {
    const items = handleInvoicesForStatistics(invoices);

    // Group by period
    const byGroups: { [key: string]: StatisticInvoice } = {};

    items.forEach((i) => {
        const key = getKey(i);

        if (!byGroups[key]) {
            byGroups[key] = { ...i };
        } else {
            const item = byGroups[key];

            item.paidAmount += i.paidAmount;
            item.total += i.total;
            item.totalWithVat += i.totalWithVat;
        }
    });

    for (const k in byGroups) {
        const item = byGroups[k];
        item.paidAmount *= 0.01;
        item.total *= 0.01;
        item.totalWithVat *= 0.01;
    }

    return byGroups;
};

type GroupedInvoicesToChartDataOptions = {
    groupByYear?: boolean;
};

const groupedInvoicesToChartData = (
    groupedInvoices: { [key: string]: StatisticInvoice },
    dateRange: DateRange,
    options: GroupedInvoicesToChartDataOptions
) => {
    const now = new Date();
    const byGroups = { ...groupedInvoices };

    if (options.groupByYear) {
        for (let date = moment(dateRange.from); date.isSameOrBefore(dateRange.to); date.add(1, 'month')) {
            const key = date.format('YYYY-MM');
            const isAfterToday = +date.toDate() - +now > 0;
            const value = isAfterToday ? NaN : 0;

            if (!byGroups[key]) {
                byGroups[key] = {
                    id: Math.floor(Math.random() * 10000),
                    date: key,
                    updatedDate: key,
                    status: 'Generated',
                    total: value,
                    totalWithVat: value,
                    paidAmount: value,
                };
            }
        }
    } else {
        for (let date = moment(dateRange.from); date.isSameOrBefore(dateRange.to); date.add(1, 'day')) {
            const key = date.format('YYYY-MM-DD');
            const isAfterToday = +date.toDate() - +now > 0;
            const value = isAfterToday ? NaN : 0;

            if (!byGroups[key]) {
                byGroups[key] = {
                    id: Math.floor(Math.random() * 10000),
                    date: key,
                    updatedDate: key,
                    status: 'Generated',
                    total: value,
                    totalWithVat: value,
                    paidAmount: value,
                };
            }
        }
    }

    const result = Object.values(byGroups);

    result.sort((a, b) => +moment(a.date).toDate() - +moment(b.date).toDate());

    return result;
};

type InvoiceUtilOptions = {
    groupByMonth: boolean;
    spansMultipleYears: boolean;
    withVat: boolean;
};

function mapInvoiceToChartItem(
    item: StatisticInvoice,
    options: InvoiceUtilOptions & { isWithinMonth: boolean }
): LabelValueType<number> {
    let format = 'DD.MM.';
    if (options.isWithinMonth) format = 'Do';
    if (options.groupByMonth) format = 'MMM';
    if (options.spansMultipleYears) format = 'MMM YYYY';

    return {
        label: moment(item.date).format(format),
        value: options.withVat ? item.totalWithVat : item.total,
    };
}

// Function to be passed to array.filter, notice the currying.
// You can use it like this: invoices.filter(invoiceDateFilter(dateRange))
// You can also pass multiple date ranges as an array.
export const invoiceDateFilter =
    (dateRange: DateFilter | DateFilter[]) =>
    (invoice: StatisticInvoice): boolean => {
        if (Array.isArray(dateRange)) {
            return dateRange.some((range) => invoiceDateFilter(range)(invoice));
        }

        return (
            moment(invoice.date).isSameOrAfter(dateRange.from, 'date') &&
            moment(invoice.date).isSameOrBefore(dateRange.to, 'date')
        );
    };

export const statisticInvoicesToGraphData = (
    invoices: StatisticInvoice[],
    dateRange: DateFilter,
    color: ChartColor,
    options: InvoiceUtilOptions
): SalesPageStatisticData => {
    const baseStatisticData = {
        dateRangeString: dateRangeToString(dateRange),
        name: getTimeLabel(dateRange),
        color,
        dateRange: dateRange,
    };

    const byGroups = groupInvoices(invoices.filter(invoiceDateFilter(dateRange)), (item) =>
        moment(item.date).format(options.groupByMonth ? 'YYYY-MM' : 'YYYY-MM-DD')
    );

    const chartData = groupedInvoicesToChartData(byGroups, dateRange, { groupByYear: options.groupByMonth });

    return {
        ...baseStatisticData,
        invoices: chartData,
        items: chartData.map((i) =>
            mapInvoiceToChartItem(i, { ...options, isWithinMonth: isWithinSingleMonth(dateRange) })
        ),
    };
};
