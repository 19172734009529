import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { customError } from 'actions/auth';
import {
    ERROR_BINARY_GENERAL,
    ERROR_BINARY_INVALID_REGISTRATION_DATE,
    ERROR_BINARY_MISSING_PERSON,
    ERROR_BINARY_MISSING_PIC,
} from 'utils/error';

/*
    Component that handles the /error/:errorCode? route.
*/

interface IMatchParams {
    errorCode?: string;
}

interface IProps extends IMatchParams {
    showError: (errorType: string) => void;
}

const convertErrorCode = (errorCode?: string): string => {
    switch (errorCode) {
        case 'missing_person':
            return ERROR_BINARY_MISSING_PERSON;
        case 'invalid_registration_date':
            return ERROR_BINARY_INVALID_REGISTRATION_DATE;
        case 'missing_pic':
            return ERROR_BINARY_MISSING_PIC;
        default:
            return ERROR_BINARY_GENERAL;
    }
};

const ErrorUrlHandler = (props: IProps) => {
    const params = useParams();
    const errorCode = params?.errorCode;

    React.useEffect(() => {
        props.showError(convertErrorCode(errorCode));
    }, [props, errorCode]);

    return null;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        showError: (errorType: string) => {
            dispatch(customError(errorType));
        },
    };
};

export default connect(null, mapDispatchToProps)(ErrorUrlHandler);
