import { useTranslation } from 'react-i18next';
import { Bold, P, UniqueTitleCenter } from '../../components/textElements';
import { COLOR_BLUM } from '../../styles/variables';

interface IProps {
    email: string;
}

const PasswordLinkSent = (props: IProps) => {
    const { t } = useTranslation();
    window.dataLayer = window.dataLayer || [];

    return (
        <>
            <UniqueTitleCenter>{t('login.confirmation')}</UniqueTitleCenter>
            <Bold color={COLOR_BLUM}>{t('login.email-sent')}</Bold>
            <P>
                {t('login.email-sent-to')}
                {props.email}
            </P>
            <P>{t('login.click-n-login')}</P>
            <P>
                {t('login.ps1')}
                <a href="mailto:tuki.kevytyrittajat@eezy.fi">tuki.kevytyrittajat@eezy.fi</a>
                {t('login.ps2')}
            </P>
            <P>
                {t('login.ps3')}
                <a href="/login" className="link-eezy-fi-u-register">
                    {t('login.ps3-link')}
                </a>
                .
            </P>
        </>
    );
};

export default PasswordLinkSent;
