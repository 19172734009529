import React from 'react';
import styled from 'styled-components';
import { IReceiptCost } from '../../../../shared/src/types/costs';
import { EezyButton } from '../../components/Buttons';
import { FormSection, SplitRow } from '../../components/form';
import { FormLabel } from '../../components/form/FormLabel';
import { Icon } from '../../components/Icon';
import { SingleText } from '../../components/textElements';
import { ValidationMessageBlock } from '../../components/ValidationMessageBlock';
import { formatCents, trans } from '../../utils';
import {
    getCostTotal,
    getTravelReceiptById,
    getTravelReceiptByIndex,
    ICostComponentProps,
    INITIAL_TRAVEL_COST,
    RECEIPT,
} from '../../utils/costs/costLogic';

const ReceiptRow = styled.div`
    & {
        align-items: baseline;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px 0;
    }
    & div:first-of-type {
        display: flex;
        white-space: nowrap;

        button,
        div {
            margin-left: 5px;
        }
    }

    ${SingleText} {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 5px;
        white-space: nowrap;
    }
`;

const renderReceipt = (
    id: number,
    receiptName: string,
    totalCents: number,
    index: number,
    handleEdit: (id: number, index: number) => void,
    handleRemove: (index: number) => void
) => {
    return (
        <ReceiptRow key={`receipt-${index}-${receiptName}`}>
            <SingleText color="black">{receiptName}</SingleText>
            <div>
                <SingleText style={{ display: 'inline' }}>
                    {trans('form.eurs', {
                        eurs: formatCents(totalCents, true),
                    })}
                </SingleText>

                <EezyButton
                    aria-label={trans('costs.travel-receipt-edit', {
                        name: receiptName,
                    })}
                    color="purple"
                    onClick={() => handleEdit(id, index)}
                    square
                    style={{ padding: 0 }}
                    width={75}
                >
                    {trans('general.edit')}
                </EezyButton>
                <EezyButton
                    aria-label={trans('costs.travel-receipt-remove', {
                        name: receiptName,
                    })}
                    color="purple"
                    onClick={() => handleRemove(index)}
                    square
                    style={{ padding: 0 }}
                    width={30}
                >
                    <Icon icon={['far', 'trash-alt']} />
                </EezyButton>
            </div>
        </ReceiptRow>
    );
};

export const TravelCosts = (props: ICostComponentProps) => {
    const { dispatch } = props;

    const handleNextStep = (step: number, payload?: any) => {
        dispatch({ type: 'GO_STEP', payload: { step, ...payload } });
    };

    const handleNewReceipt = () => {
        handleNextStep(RECEIPT, {
            travelCostTemp: {
                cost: INITIAL_TRAVEL_COST,
                isNew: true,
                errors: {},
            },
        });
    };

    const handleReceiptEdit = (id: number, index: number) => {
        if (!props.currentTravel) {
            return;
        }
        if (id === 0) {
            // Temp id for costs that have not yet been saved
            handleNextStep(RECEIPT, {
                travelCostTemp: {
                    cost: getTravelReceiptByIndex(index, props.currentTravel),
                    tempId: index,
                },
            });
        } else {
            handleNextStep(RECEIPT, {
                travelCostTemp: {
                    cost: getTravelReceiptById(id, props.currentTravel),
                    id,
                },
            });
        }
    };

    const handleReceiptRemoval = (index: number) => {
        const receiptCosts = props.currentTravel?.receiptCosts.filter((r: IReceiptCost, i) => {
            return i !== index;
        });
        dispatch({ payload: { receiptCosts }, type: 'SAVE_TRAVEL' });
    };

    const disabled = props.currentTravel?.repeatingDates && props.currentTravel.repeatingDates.length > 0;

    return (
        <div>
            <FormSection>
                <SplitRow style={{ marginBottom: 15 }}>
                    <FormLabel id="add-travel-receipt">{trans('costs.travel-receipt')}</FormLabel>
                    <EezyButton
                        aria-labelledby="add-travel-receipt"
                        color="purple"
                        disabled={disabled}
                        onClick={handleNewReceipt}
                        square
                    >
                        {trans('form.add')}
                    </EezyButton>
                </SplitRow>

                {disabled && (
                    <ValidationMessageBlock type={'warning'} style={{ position: 'relative', top: '-10px' }}>
                        {trans('costs.travel-time.repeating-date-costs')}
                    </ValidationMessageBlock>
                )}

                {props.currentTravel?.receiptCosts.map((r: IReceiptCost, index) => {
                    return renderReceipt(
                        r.id,
                        r.description || '',
                        getCostTotal(r.amountsWithVats),
                        index,
                        handleReceiptEdit,
                        handleReceiptRemoval
                    );
                })}

                {}
            </FormSection>
        </div>
    );
};
