import { ApolloError, useApolloClient, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { IInvoice, IInvoiceItem, ItemType } from '../../../../../shared/src/types/invoice';
import { EezyButton } from '../../../components/Buttons';
import EscIcon from '../../../components/EscIcon';
import { Flex } from '../../../components/Flex';
import {
    DatePicker,
    FormNumber,
    FormNumberInput,
    FormRadio,
    FormRow,
    FormSection,
    FormSelect,
    FormTextArea,
    HoursMinutes,
    SplitRow,
} from '../../../components/form';
import { IDropdownOption } from '../../../components/form/AutocompleteDropdown';
import { FormBackground } from '../../../components/form/FormBackground';
import { FormLabel } from '../../../components/form/FormLabel';
import { StrongLine } from '../../../components/Lines';
import LoadingSpinner from '../../../components/Loading';
import { LabelMedium, SectionTitle } from '../../../components/textElements';
import { ValidationMessageBlock } from '../../../components/ValidationMessageBlock';
import { COLOR_BLACKWATER, COLOR_IMPORTANT } from '../../../styles/variables';
import { formatCents, formatDateISO, trans, xNotAfterYInDays } from '../../../utils';
import { getErrors } from '../../../utils/apolloErrors';
import { addVat, notEmpty } from '../../../utils/calc';
import {
    calcTypeOptions,
    getInvoiceItemCopy,
    getRowUpdates,
    getTemplate,
    initialRowData,
    quantityUnitOptions,
    vatOptions,
} from '../../../utils/invoice/invoiceLogic';
import validators from '../../../utils/invoice/validators';
import { offsetFromTop } from '../../../utils/ui';
import { IDocument } from '../../../utils/user/userUtils';
import { formatValidationResult } from '../../../utils/validation';
import { ADD_INVOICE_ITEM, DELETE_INVOICE_ITEM, GET_INVOICE, UPDATE_INVOICE_ITEM } from '../queries';
import FillHelper from './FillHelper';
import { closeAllFillHelpers, openFillHelper } from './utils';
import { useTranslation } from 'react-i18next';

const descriptionTitle = (itemType?: ItemType): string => {
    switch (itemType) {
        case 'work':
            return trans('invoice.rows-form.description-work-q');
        case 'travel':
            return trans('invoice.rows-form.description-travel-q');
        case 'material':
            return trans('invoice.rows-form.description-material-q');
        default:
            return '';
    }
};
const descriptionPlaceholder = (itemType?: ItemType): string => {
    switch (itemType) {
        case 'work':
            return trans('invoice.rows-form.description-work-placeholder');
        case 'travel':
            return trans('invoice.rows-form.description-travel-placeholder');
        case 'material':
            return trans('invoice.rows-form.description-material-placeholder');
        default:
            return '';
    }
};

const vatHelpTexts = (itemType?: ItemType, lang?: string) => {
    switch (itemType) {
        case 'work':
            return {
                helpLink:
                    lang === 'en'
                        ? 'https://kevytyrittajat.eezy.fi/en/faq/#is-vat-added-to-the-price-of-my-work'
                        : 'https://kevytyrittajat.eezy.fi/usein-kysyttya/#lisataanko-tyoni-hintaan-arvonlisavero',
                helpTexts: ['invoice.rows-form.help.vat.work.1', 'invoice.rows-form.help.vat.work.2'],
            };
        case 'material':
            return {
                helpLink:
                    lang === 'en'
                        ? 'https://kevytyrittajat.eezy.fi/en/faq/#is-vat-added-to-the-price-of-my-work'
                        : 'https://kevytyrittajat.eezy.fi/usein-kysyttya/#lisataanko-tyoni-hintaan-arvonlisavero',
                helpTexts: ['invoice.rows-form.help.vat.material.1'],
            };
        case 'travel':
            return {
                helpLink:
                    lang === 'en'
                        ? 'https://kevytyrittajat.eezy.fi/en/when-am-i-able-to-get-expenses-reimbursed/'
                        : 'https://kevytyrittajat.eezy.fi/milloin-voin-saada-kulukorvauksia/',
                helpTexts: ['invoice.rows-form.help.vat.travel.1', 'invoice.rows-form.help.vat.travel.2'],
            };
        default:
            return {};
    }
};

interface IFillHelperProps {
    invoiceId?: number;
    rowId?: string;
    showModals?: (modals: string[]) => void;
}

const InvoiceRowsFillHelper = (props: IFillHelperProps) => {
    const { i18n } = useTranslation();
    const { data: invoiceData } = useQuery(GET_INVOICE, {
        variables: { id: props.invoiceId },
    });
    const invoice = invoiceData?.invoice;

    const client = useApolloClient();
    const showArrows = true;

    const rowId = props.rowId ? parseInt(props.rowId, 10) : null;
    const template = getTemplate(invoice?.template);

    const formData: IInvoiceItem | undefined = invoice?.invoiceItems.find(
        (item: IInvoiceItem) => item.id === rowId
    );

    const [calcType, setCalcType] = React.useState('units');
    const [vatType, setVatType] = React.useState('without-vat');

    React.useEffect(() => {
        setCalcType(formData?.quantityUnit === 'h' ? 'h' : 'units');
    }, [props.rowId]);

    const [deleteInvoiceItem] = useMutation(DELETE_INVOICE_ITEM, {
        onError: () => toast.error(trans('errors.general')),
    });

    const [updateInvoiceItem, { loading: updateLoading }] = useMutation(UPDATE_INVOICE_ITEM, {
        onError: (e: ApolloError) => {
            const errors = getErrors(e);
            if (errors?.mustAccept && props.showModals) {
                props.showModals(errors?.mustAccept.map((t: IDocument) => t.document));
            } else if (errors?.error === 'BAD_USER_INPUT') {
                toast.error(trans('errors.negativeInvoiceItem'));
            } else {
                toast.error(trans('errors.general'));
            }
        },
        update(cache, { data: { updateInvoiceItem: updatedInvoiceData } }) {
            const invoiceCache: {
                invoice: IInvoice;
            } | null = cache.readQuery({
                query: GET_INVOICE,
                variables: { id: props.invoiceId },
            });

            if (updatedInvoiceData.totalVats && invoiceCache) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...invoiceCache.invoice,
                            invoiceItems: updatedInvoiceData.invoiceItems,
                            total: updatedInvoiceData.total,
                            totalVats: updatedInvoiceData.totalVats,
                            totalWithVat: updatedInvoiceData.totalWithVat,
                        },
                    },
                    query: GET_INVOICE,
                    variables: { id: invoice?.id },
                });
            }
        },
    });

    const [addInvoiceItem, { loading: addingLoading }] = useMutation(ADD_INVOICE_ITEM, {
        onCompleted: (data) => {
            const item = data?.updateInvoiceItem?.invoiceItems.slice(-1);
            openFillHelper(client, 'invoiceRow', item?.newItem?.id);
            toast(trans('form.copied'));
        },
        onError: () => toast.error(trans('errors.general')),
        update(cache, { data: { updateInvoiceItem: invoiceItems } }) {
            const invoiceData1: {
                invoice: IInvoice;
            } | null = cache.readQuery({
                query: GET_INVOICE,
                variables: { id: props.invoiceId },
            });
            if (invoiceData1?.invoice) {
                cache.writeQuery({
                    data: {
                        invoice: {
                            ...invoiceData1.invoice,
                            invoiceItems: invoiceItems.invoiceItems,
                        },
                    },
                    query: GET_INVOICE,
                    variables: { id: props.invoiceId },
                });
            }
        },
    });

    const handleUpdate = (changedProperties: any) => {
        const updatedItems = invoice.invoiceItems.map((item: IInvoiceItem) => {
            if (item.id !== rowId) {
                return {
                    ...initialRowData,
                    ...item,
                    __typename: 'InvoiceItem',
                };
            }
            return {
                ...item,
                ...changedProperties,
                __typename: 'InvoiceItem',
            };
        });

        updateInvoiceItem({
            optimisticResponse: {
                updateInvoiceItem: {
                    __typename: 'Invoice',
                    invoiceItems: updatedItems,
                    total: invoice.total,
                    totalVats: invoice.totalVats,
                    totalWithVat: invoice.totalWithVat,
                },
            },
            variables: {
                id: rowId,
                invoiceId: invoice.id,
                ...changedProperties,
            },
        });
    };

    const handleChange = (val: string | number, name: string) => {
        if (formData) {
            let changedProperties = getRowUpdates(
                { [name]: val },
                {
                    ...formData,
                    quantity: name === 'totalPrice' || name === 'totalPriceWithVat' ? 1 : formData?.quantity,
                },
                vatType === 'with-vat'
            );
            if (name === 'totalPrice' || name === 'totalPriceWithVat') {
                changedProperties = { ...changedProperties, quantity: 1 };
            }
            handleUpdate(changedProperties);
        }
    };

    const handleDelete = () => {
        closeAllFillHelpers(client);
        deleteInvoiceItem({
            optimisticResponse: {
                deleteInvoiceItem: {
                    __typename: 'Invoice',
                    id: props.invoiceId,
                    invoiceItems: invoice?.invoiceItems.filter((r: IInvoiceItem) => r.id !== rowId),
                    total: invoice?.total,
                    totalVats: invoice?.totalVats,
                    totalWithVat: invoice?.totalWithVat,
                },
            },
            variables: { id: rowId, invoiceId: props.invoiceId },
        });
    };

    const handleCopy = () => {
        if (!formData || !props.invoiceId) {
            return;
        }
        addInvoiceItem({
            variables: {
                invoiceId: props.invoiceId,
                ...getInvoiceItemCopy(formData),
            },
        });
    };

    const handleTypeChange = (val: string) => {
        if (!updateLoading && !addingLoading) {
            //  pcs is valid for every row type
            handleUpdate({ itemType: val, quantityUnit: 'pcs' });
            setCalcType('units');
        }
    };

    const onStartDateChange = (date: Date | null) => {
        const d = date ? formatDateISO(date) : '';
        handleChange(d, 'startDate');
    };

    const onEndDateChange = (date: Date | null) => {
        const d =
            date && (!formData?.startDate || xNotAfterYInDays(new Date(formData?.startDate), date))
                ? formatDateISO(date)
                : '';
        handleChange(d, 'endDate');
    };

    const handleCalcTypeChange = (val: string) => {
        setCalcType(val);
        if (val === 'h') {
            handleChange('h', 'quantityUnit');
        } else if (val === 'units' && formData?.itemType === 'work') {
            handleUpdate({ quantityUnit: 'pcs' });
        }
    };

    const rowQuantityUnitOptions: IDropdownOption[] = quantityUnitOptions(formData?.itemType);

    const possibleWorkItemVats = invoice?.invoiceItems
        .filter((t: IInvoiceItem) => t.itemType === 'work')
        .map((t: IInvoiceItem) => t.vat)
        .filter(notEmpty);

    const rowVatoptions: IDropdownOption[] = vatOptions(formData?.itemType, possibleWorkItemVats, template);

    const rowErrors = formatValidationResult(
        validators.invoiceItem.validate({
            ...formData,
            template: template.id,
        })
    );

    return (
        <FillHelper
            arrowOffset={offsetFromTop(`invoice-row-${rowId}`)}
            renderMenuLeft={() => (
                <SectionTitle color="white">{trans('invoice.fillhelper.title')}</SectionTitle>
            )}
            renderMenuRight={(handleClose) => (
                <EezyButton color="important" onClick={handleClose} style={{ minWidth: '110px' }}>
                    {updateLoading || addingLoading ? (
                        <LoadingSpinner size="1em" />
                    ) : (
                        <>
                            {trans('general.ready')}
                            <EscIcon style={{ marginLeft: 10 }} />
                        </>
                    )}
                </EezyButton>
            )}
        >
            <FormBackground as={'form'}>
                <FormSection>
                    <FormRadio
                        helpTexts={[
                            'invoice.rows-form.help.type.1',
                            'invoice.rows-form.help.type.2',
                            'invoice.rows-form.help.type.3',
                        ]}
                        helpLink={
                            i18n.language === 'en'
                                ? 'https://kevytyrittajat.eezy.fi/en/what-situations-is-light-entrepreneurship-not-suitable-for/'
                                : 'https://kevytyrittajat.eezy.fi/rajoitetut-tyot/'
                        }
                        label={trans('invoice.rows-form.type-q')}
                        name="itemType"
                        onChange={handleTypeChange}
                        onTwoLines
                        options={[
                            {
                                label: trans('invoice.tableColumns.work'),
                                value: 'work',
                            },
                            {
                                label: trans('invoice.tableColumns.travel'),
                                value: 'travel',
                            },
                            {
                                label: trans('invoice.tableColumns.material'),
                                value: 'material',
                            },
                        ]}
                        value={formData?.itemType}
                    />
                </FormSection>

                <FormSection>
                    <FormTextArea
                        error={showArrows && rowErrors?.description}
                        label={descriptionTitle(formData?.itemType)}
                        name="description"
                        noNewLinesAllowed
                        onChange={handleChange}
                        placeholder={descriptionPlaceholder(formData?.itemType)}
                        required
                        value={formData?.description || ''}
                    />
                </FormSection>

                {formData?.itemType === 'work' && (
                    <FormSection>
                        <FormLabel error={showArrows && rowErrors?.startDate}>
                            {trans('invoice.rows-form.time-q')}
                        </FormLabel>
                        <SplitRow>
                            <DatePicker
                                disableToolbar
                                icon={['far', 'calendar-alt']}
                                label={trans('dates.select-startDate')}
                                maxDate={formData?.endDate ? new Date(formData?.endDate) : undefined}
                                placeholder={trans('dates.select-startDate')}
                                id="row-startDate"
                                onChange={onStartDateChange}
                                style={{ flexGrow: 1 }}
                                value={formData?.startDate}
                            />
                            <DatePicker
                                disableToolbar
                                icon={['far', 'calendar-alt']}
                                label={trans('dates.select-endDate')}
                                minDate={formData?.startDate ? new Date(formData?.startDate) : undefined}
                                placeholder={trans('dates.select-endDate')}
                                id="row-endDate"
                                onChange={onEndDateChange}
                                value={formData?.endDate}
                            />
                        </SplitRow>
                    </FormSection>
                )}

                <FormSection>
                    <FormRadio
                        helpTexts={['invoice.rows-form.help.vatType.1', 'invoice.rows-form.help.vatType.2']}
                        label={trans('invoice.rows-form.vat-type-q')}
                        name="vatType"
                        onChange={(val) => setVatType(val)}
                        onTwoLines
                        options={[
                            {
                                label: trans('invoice.rows-form.vat-type-without-vat'),
                                value: 'without-vat',
                            },
                            {
                                label: trans('invoice.rows-form.vat-type-with-vat'),
                                value: 'with-vat',
                            },
                        ]}
                        value={vatType}
                    />
                </FormSection>

                <FormSection>
                    <FormRadio
                        helpTexts={
                            formData?.itemType === 'work'
                                ? [
                                      'invoice.rows-form.help.calcTypeWork.1',
                                      'invoice.rows-form.help.calcTypeWork.2',
                                  ]
                                : ['invoice.rows-form.help.calcType.1', 'invoice.rows-form.help.calcType.2']
                        }
                        label={
                            formData?.itemType === 'work'
                                ? trans('invoice.rows-form.calc-type-q-work')
                                : trans('invoice.rows-form.calc-type-q')
                        }
                        name="calcType"
                        onChange={handleCalcTypeChange}
                        onTwoLines
                        options={calcTypeOptions(formData?.itemType)}
                        value={calcType}
                    />
                </FormSection>

                <FormSection>
                    <FormRow>
                        {calcType === 'h' && (
                            <SplitRow>
                                <FormRow>
                                    <FormLabel
                                        error={showArrows && rowErrors?.quantity}
                                        value={formData?.quantity}
                                    >
                                        {trans('invoice.rows-form.workTime')}
                                    </FormLabel>
                                    <HoursMinutes
                                        error={showArrows && rowErrors?.quantity}
                                        name="quantity"
                                        onChange={handleChange}
                                        required
                                        value={formData?.quantity}
                                    />
                                </FormRow>
                                <FormRow style={{ minWidth: '50%' }}>
                                    <FormLabel error={showArrows && rowErrors?.price} value={formData?.price}>
                                        {trans('invoice.rows-form.hourly-price')}
                                    </FormLabel>
                                    <Flex center>
                                        <FormNumberInput
                                            allowNegative
                                            disableRounding
                                            error={showArrows && rowErrors?.price}
                                            onChange={handleChange}
                                            isEur={true}
                                            endAdornment={trans(`form.x-e-${formData?.quantityUnit}`).trim()}
                                            name="price"
                                            required
                                            value={
                                                vatType === 'without-vat'
                                                    ? formData?.price
                                                    : addVat(formData?.price || 0, formData?.vat || 0)
                                            }
                                        />
                                    </Flex>
                                </FormRow>
                            </SplitRow>
                        )}

                        {calcType === 'units' &&
                            (formData?.itemType === 'work' ? (
                                <SplitRow>
                                    <FormNumber
                                        allowNegative
                                        label={trans('invoice.tableColumns.quantity')}
                                        endAdornment={trans(`form.x-${formData?.quantityUnit}`).trim()}
                                        error={showArrows && rowErrors?.quantity}
                                        onChange={handleChange}
                                        name="quantity"
                                        required
                                        value={formData?.quantity}
                                    />
                                    <FormNumber
                                        allowNegative
                                        style={{ flexGrow: 1 }}
                                        label={trans('invoice.tableColumns.pcsprice')}
                                        disableRounding
                                        error={showArrows && rowErrors?.price}
                                        onChange={handleChange}
                                        isEur={true}
                                        endAdornment={trans('form.eurs', { eurs: '' }).trim()}
                                        name="price"
                                        required
                                        value={
                                            vatType === 'without-vat'
                                                ? formData?.price
                                                : addVat(formData?.price || 0, formData?.vat || 0)
                                        }
                                    />
                                </SplitRow>
                            ) : (
                                <SplitRow>
                                    <FormRow>
                                        <FormLabel
                                            error={
                                                showArrows && (rowErrors?.quantity || rowErrors?.quantityUnit)
                                            }
                                            value={formData?.quantity && formData?.quantityUnit}
                                        >
                                            {trans('invoice.rows-form.quantity-units-q')}
                                        </FormLabel>

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <FormNumberInput
                                                allowNegative
                                                error={showArrows && rowErrors?.quantity}
                                                onChange={handleChange}
                                                name="quantity"
                                                required
                                                style={{ marginRight: 10 }}
                                                value={formData?.quantity}
                                            />
                                            <FormSelect
                                                error={showArrows && rowErrors?.quantityUnit}
                                                name="quantityUnit"
                                                onChange={handleChange}
                                                options={rowQuantityUnitOptions}
                                                required
                                                showIcon
                                                style={{ maxWidth: 60 }}
                                                value={formData?.quantityUnit}
                                            />
                                        </div>
                                    </FormRow>
                                    <FormNumber
                                        allowNegative
                                        disableRounding
                                        label={trans('invoice.tableColumns.pcsprice')}
                                        error={showArrows && rowErrors?.price}
                                        onChange={handleChange}
                                        isEur={true}
                                        endAdornment={trans('form.eurs', { eurs: '' }).trim()}
                                        name="price"
                                        required
                                        value={
                                            vatType === 'without-vat'
                                                ? formData?.price
                                                : addVat(formData?.price || 0, formData?.vat || 0)
                                        }
                                        style={{ width: '50%' }}
                                    />
                                </SplitRow>
                            ))}
                        {calcType === 'sum' && vatType === 'without-vat' && (
                            <FormNumber
                                allowNegative
                                endAdornment={'€'}
                                error={showArrows && rowErrors?.totalPrice}
                                isEur={true}
                                label={trans('invoice.rows-form.total-q')}
                                name="totalPrice"
                                onChange={handleChange}
                                required
                                style={{ width: '50%' }}
                                value={formData?.totalPrice}
                            />
                        )}
                        {calcType === 'sum' && vatType === 'with-vat' && (
                            <FormNumber
                                allowNegative
                                endAdornment={'€'}
                                error={showArrows && rowErrors?.totalPriceWithVat}
                                isEur={true}
                                label={trans('invoice.rows-form.total-q')}
                                name="totalPriceWithVat"
                                onChange={handleChange}
                                required
                                style={{ width: '50%' }}
                                value={formData?.totalPriceWithVat}
                            />
                        )}
                    </FormRow>
                </FormSection>

                <FormSection>
                    <FormRadio
                        error={showArrows && rowErrors?.vat}
                        helpTexts={vatHelpTexts(formData?.itemType)?.helpTexts}
                        helpLink={vatHelpTexts(formData?.itemType, i18n.language)?.helpLink}
                        label={trans('invoice.rows-form.vat-q')}
                        name="vat"
                        options={rowVatoptions}
                        onChange={(val, name) => {
                            if (!updateLoading && !addingLoading) {
                                handleChange(parseInt(val, 10), name);
                            }
                        }}
                        onTwoLines
                        required
                        value={formData?.vat !== undefined ? formData?.vat?.toString() : ''}
                    />
                </FormSection>

                <StrongLine />

                <SplitRow>
                    <SectionTitle>{trans('invoice.sum')}</SectionTitle>
                    <SectionTitle>
                        {trans('form.eurs', {
                            eurs: formatCents(formData?.totalPrice, true),
                        })}
                    </SectionTitle>
                </SplitRow>
                <SplitRow>
                    <SectionTitle>{trans('invoice.sum-with-vat')}</SectionTitle>
                    <SectionTitle>
                        {trans('form.eurs', {
                            eurs: formatCents(formData?.totalPriceWithVat, true),
                        })}
                    </SectionTitle>
                </SplitRow>

                {formData?.totalPriceWithVat && formData?.totalPriceWithVat < 0 && (
                    <ValidationMessageBlock
                        style={{ marginTop: 8 }}
                        title={trans('invoice.warning-minus-title')}
                        type="warning"
                    >
                        {trans('invoice.warning-minus-text')}
                    </ValidationMessageBlock>
                )}

                <Flex column style={{ marginTop: 60 }}>
                    <LabelMedium style={{ marginBottom: 16 }} color={COLOR_BLACKWATER}>
                        {trans('invoice.tableColumns.copy-row-label')}:
                    </LabelMedium>
                    <Flex>
                        <EezyButton
                            color="black"
                            onClick={handleCopy}
                            style={{ backgroundColor: 'white', marginRight: 8 }}
                            type="button"
                        >
                            {trans('invoice.tableColumns.copy-row')}
                        </EezyButton>
                        <EezyButton
                            color="important"
                            onClick={handleDelete}
                            style={{ border: `1px solid ${COLOR_IMPORTANT}` }}
                            type="button"
                        >
                            {trans('invoice.tableColumns.delete-row')}
                        </EezyButton>
                    </Flex>
                </Flex>
            </FormBackground>
        </FillHelper>
    );
};

export default InvoiceRowsFillHelper;
