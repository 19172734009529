import { Hidden } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import BonusCenter from '../../assets/images/notifications/bonus-center.svg';
import BonusLeft from '../../assets/images/notifications/bonus-left.svg';
import BonusRight from '../../assets/images/notifications/bonus-right.svg';
import { Flex } from '../../components/Flex';
import { RowTitle, SingleTextHalf } from '../../components/textElements';
import { BORDER_RADIUS, COLOR_LILA_MEDIUM } from '../../styles/variables';
import { formatCents, trans } from '../../utils';

const StyledNotification = styled(Flex).attrs({
    center: true,
    spread: true,
})`
    background-color: ${COLOR_LILA_MEDIUM};
    border-radius: ${BORDER_RADIUS};
    height: 100px;
    padding: 15px 10px;
`;

interface INotificationProps {
    bonus: number;
}

export const NotificationEezyBonus = (props: INotificationProps) => {
    return (
        <StyledNotification className="custom-notification">
            <img src={BonusLeft} alt="" />
            <Flex column center>
                <RowTitle color="black">
                    {trans('dashboard.messages.bonus.title', {
                        eurs: formatCents(props.bonus, true),
                    })}
                </RowTitle>
                <SingleTextHalf
                    style={{ textAlign: 'center' }}
                    title={trans('dashboard.messages.bonus.subtitle')}
                >
                    {trans('dashboard.messages.bonus.subtitle')}
                </SingleTextHalf>
                <Hidden smDown>
                    <img src={BonusCenter} height={20} alt="" style={{ marginTop: 10 }} />
                </Hidden>
            </Flex>

            <div>
                <Hidden mdDown>
                    <img src={BonusRight} alt="" />
                </Hidden>
            </div>
        </StyledNotification>
    );
};
