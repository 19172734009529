import { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IRootState } from 'reducers';
import queryString from 'query-string';
import styled from 'styled-components';
import { hideError, IRegisterData, register, showModals, strongAuth } from 'actions/auth';
import { logoFull } from 'assets/images/eezylogo';
import { EezyButton, InlineButtonLink } from 'components/Buttons';
import ErrorBox from 'components/ErrorBox';
import Footer from 'components/Footer';
import { FormSection } from 'components/form';
import { Icon } from 'components/Icon';
import LanguageOptions from 'components/LanguageOptions';
import { Line2 } from 'components/Lines';
import LoadingSpinner from 'components/Loading';
import { P, PLight, UniqueTitleCenter } from 'components/textElements';
import { EmailVerificationStatus } from 'constants/user';
import { IAuth } from 'reducers/authReducer';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_LILA,
    COLOR_WHITE_WALKER,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M,
    TOPBAR_HEIGHT,
} from 'styles/variables';
import API from '../../utils/API';
import {
    errorReason,
    getI18NextLang,
    getLocalStorage,
    saveBonusCode,
    saveResetEmail,
} from 'utils/user/userUtils';
import { registrationValidator } from 'utils/user/validators';
import { EMAIL_REGEX, formatValidationResult } from 'utils/validation';
import Bar, { BarContent } from '../nav/Bar';
import PurpleBackground from '../nav/PurpleBackground';
import PasswordLinkSent from './PasswordLinkSent';
import RegistrationForm from './RegistrationForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useChangeUserLanguageMutation, useUser } from 'queries/useUserQuery';

const BONUSCODE_VALID_IN_LOCAL_STORAGE_MINUTES = 5;
export const VERIFY_EMAIL_TIMEOUT_MS = 5000;

const ContentWrapper = styled.div`
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
    @media (min-width: ${SCREEN_M}px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

const RegisterWrapper = styled.div`
    align-items: start;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px;
    margin: 30px auto;
    text-align: left;
    max-width: 450px;
    width: 100%;

    h1 {
        margin-bottom: 30px;
    }
    form,
    .auth-section {
        width: 100%;
    }
`;

const TextLineWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    hr {
        width: 100%;
    }
    p {
        margin: 15px 20px 0 20px;
        text-align: center;
    }
`;

const RegisterSubtitleWrapper = styled.div`
    padding: 0 20px;
    margin: 32px auto;
    text-align: center;
    max-width: 750px;
    width: 100%;
    color: ${COLOR_BLUM};

    h1 {
        margin-bottom: 30px;
    }
    form,
    .auth-section {
        width: 100%;
    }
`;

interface IFormData extends IRegisterData {
    authenticated: boolean;
}

const initialFormData: IFormData = {
    authenticated: false,
    bonusCode: '',
    email: '',
    firstName: '',
    language: 'fi',
    lastName: '',
    phone: '',
    phoneCountryCode: 358,
    referralInfo: '',
    referralOther: '',
};

export const didEmailVerificationFailed = (status: string) =>
    status === EmailVerificationStatus.mailbox_full ||
    status === EmailVerificationStatus.syntax_error ||
    status === EmailVerificationStatus.typo ||
    status === EmailVerificationStatus.mailbox_not_found ||
    status === EmailVerificationStatus.disposable ||
    status === EmailVerificationStatus.mailbox_blocked;

type EmailValidationMessageProps = {
    errors: any;
};

export const RenderEmailValidationMessage: FC<EmailValidationMessageProps> = ({ errors }) => {
    const { t } = useTranslation();
    let validationMessage;
    if (errors?.email?.type === 'string.pattern.base') {
        validationMessage = 'login.invalid-email';
    } else if (didEmailVerificationFailed(errors?.email?.type)) {
        validationMessage = `login.email-verification.${errors?.email?.type.replaceAll('_', '-')}`;
    }
    return validationMessage ? (
        <ErrorBox style={{ marginBottom: '20px' }}>{t(validationMessage)}</ErrorBox>
    ) : null;
};

interface IRegisterProps {
    type?: 'only_form';
}

const Register: FC<IRegisterProps> = ({ type }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();
    const user = useUser();
    const changeUserLanguage = useChangeUserLanguageMutation();

    const link =
        currentLanguage === 'fi' ? 'https://kevytyrittajat.eezy.fi/' : 'https://kevytyrittajat.eezy.fi/en/';

    const auth: IAuth = useSelector((state: IRootState) => state.auth);
    const loading: boolean = useSelector((state: IRootState) => state.auth.loading);
    const passwordRequested: boolean | null = useSelector(
        (state: IRootState) => state.auth.passwordRequested
    );

    const [formData, setFormData] = useState<IFormData>(initialFormData);
    const [errors, setErrors] = useState<any>(null);
    const [emailVerifyError, setEmailVerifyError] = useState<string>();

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(hideError());
        const bonusCode = getPreservedBonusCode();
        const urlParams: any = queryString.parse(location.search);
        if (urlParams.existingEmail) {
            navigate(`/login?existingEmail=${urlParams.existingEmail}`);
        }
        setFormData({ ...formData, bonusCode, ...urlParams });

        if (location.pathname === '/register/error') {
            // authentication aborted
            setErrors({ ...errors, auth: errorReason(urlParams?.reason) });
        }
        if (urlParams.lang) {
            if (urlParams.lang === 'en') {
                changeUserLanguage('en');
            } else {
                changeUserLanguage('fi');
            }
        }
    }, []);

    useEffect(() => {
        if (user?.email) {
            navigate('/');
        }
    }, [user]);

    const validateEmail = async (val: string) => {
        if (EMAIL_REGEX.test(val)) {
            try {
                const result = await API.get('/auth/verify-email', {
                    params: { email: val },
                    timeout: VERIFY_EMAIL_TIMEOUT_MS,
                });
                const status = result?.data?.status;
                let validationFailedStatus;
                if (didEmailVerificationFailed(status)) {
                    validationFailedStatus = result.data.status;
                }
                if (validationFailedStatus) {
                    setEmailVerifyError(validationFailedStatus);
                    setErrors({
                        ...errors,
                        email: {
                            path: ['email'],
                            type: validationFailedStatus,
                        },
                    });
                } else {
                    setErrors({ ...errors, email: undefined });
                    setEmailVerifyError(undefined);
                }
            } catch (error) {
                setErrors({ ...errors, email: undefined });
                setEmailVerifyError(undefined);
            }
        } else {
            setErrors({
                ...errors,
                email: {
                    path: ['email'],
                    type: 'string.pattern.base',
                },
            });
        }
    };

    const getPreservedBonusCode = () => {
        // bonusCode is stored in the localstorage for the duration of strong auth
        const localData = getLocalStorage('bonusCode');
        if (localData) {
            const { bonusCode, minutesSince } = localData;
            if (minutesSince <= BONUSCODE_VALID_IN_LOCAL_STORAGE_MINUTES) {
                return bonusCode;
            } else {
                localStorage.removeItem('bonusCode');
            }
        }
        return '';
    };

    const validate = (data: any) => {
        return formatValidationResult(registrationValidator.validate(data));
    };

    const handleStrongAuth = () => {
        const urlParams: any = queryString.parse(location.search);
        if (formData.bonusCode) {
            saveBonusCode(formData.bonusCode);
        } else if (urlParams.bonusCode) {
            saveBonusCode(urlParams.bonusCode);
        }
        dispatch(strongAuth({ action: 'signup', lang: getI18NextLang() }));
    };

    const handleChange = (val: string | number, name: string) => {
        const newFormData = { ...formData, [name]: val };
        setFormData(newFormData);
        if (errors && !errors.email) {
            setErrors(validate(newFormData) || null);
        }
    };

    const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let err = validate(formData);
        if (!err?.email && emailVerifyError) {
            err = {
                ...errors,
                email: {
                    path: ['email'],
                    type: emailVerifyError,
                },
            };
        }
        if (err) {
            setErrors(err);
        } else {
            saveResetEmail(formData.email);
            setErrors(null);
            dispatch(register(formData));
        }
    };

    return (
        <>
            <Bar>
                <BarContent>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flex: 1 }}>
                            <InlineButtonLink onClick={() => navigate('/login')}>
                                <Icon
                                    icon={['far', 'arrow-left']}
                                    style={{
                                        color: COLOR_LILA,
                                        fontWeight: 600,
                                    }}
                                />
                            </InlineButtonLink>
                        </div>
                        <LanguageOptions />
                    </div>
                </BarContent>
            </Bar>
            <PurpleBackground>
                <ContentWrapper>
                    <a href={link}>
                        <img src={logoFull} alt="Eezy Kevytyrittäjät" />
                    </a>
                    <RegisterSubtitleWrapper>{t('login.register-subtitle-1')}</RegisterSubtitleWrapper>
                    <RegisterSubtitleWrapper>{t('login.register-subtitle-2')}</RegisterSubtitleWrapper>
                    <RegisterWrapper>
                        {passwordRequested ? (
                            <PasswordLinkSent email={formData.email} />
                        ) : (
                            <>
                                <UniqueTitleCenter>{t('login.registration')}</UniqueTitleCenter>

                                {formData.authenticated && !auth.error && (
                                    <P style={{ marginBottom: 15 }}>{t('login.auth-done')}</P>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                >
                                    {loading && <LoadingSpinner />}

                                    {auth.error && !auth.error.place && (
                                        <ErrorBox
                                            style={{
                                                marginBottom: '20px',
                                            }}
                                        >
                                            {auth.error.title}
                                        </ErrorBox>
                                    )}
                                    {(errors?.firstName || errors?.lastName || errors?.phone) && (
                                        <ErrorBox style={{ marginBottom: '20px' }}>
                                            {t('login.validation-message')}
                                        </ErrorBox>
                                    )}
                                    {errors && <RenderEmailValidationMessage errors={errors} />}
                                    {errors?.auth && (
                                        <ErrorBox style={{ marginBottom: '20px' }}>
                                            {t(`login.auth-aborted.${errors?.auth}`)}
                                        </ErrorBox>
                                    )}
                                </div>

                                {type !== 'only_form' &&
                                    (!formData.authenticated || (formData.authenticated && auth.error)) && (
                                        <div className="auth-section">
                                            <FormSection style={{ marginTop: 0 }}>
                                                <EezyButton
                                                    color="purple"
                                                    dark
                                                    hasIcon
                                                    iconAlignment="right"
                                                    type="button"
                                                    style={{
                                                        alignSelf: 'center',
                                                    }}
                                                    onClick={handleStrongAuth}
                                                >
                                                    {t('login.register-with-sa')}
                                                </EezyButton>
                                            </FormSection>
                                            <TextLineWrapper>
                                                <Line2 color={COLOR_BLACKWATER} />
                                                <P>{t('general.or')}</P>
                                                <Line2 color={COLOR_BLACKWATER} />
                                            </TextLineWrapper>
                                        </div>
                                    )}

                                <RegistrationForm
                                    handleChange={handleChange}
                                    handleSubmit={handleSubmit}
                                    errors={errors}
                                    formData={formData}
                                    showTerms={() => dispatch(showModals(['TERMS']))}
                                    validateEmail={validateEmail}
                                />

                                <PLight style={{ marginTop: '5px' }}>
                                    {t('login.already-registered')}{' '}
                                    <InlineButtonLink onClick={() => navigate('/login')}>
                                        {t('login.login-here')}
                                    </InlineButtonLink>
                                </PLight>
                            </>
                        )}
                    </RegisterWrapper>
                    <RegisterSubtitleWrapper>
                        {t('login.register-subtitle-3')}
                        <a href={t('login.register-subtitle-link') || ''} target="_blank" rel="noreferrer">
                            eezy.fi
                        </a>
                    </RegisterSubtitleWrapper>
                </ContentWrapper>
            </PurpleBackground>
            <Footer noLogo />
        </>
    );
};

export default Register;
