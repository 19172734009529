import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLOR_LILA } from 'styles/variables';
import { useChangeUserLanguageMutation } from 'queries/useUserQuery';

const LanguageSection = styled.div`
    button {
        font-weight: bold;
        padding: 0 8px;

        &:hover,
        &.selected {
            text-decoration: underline;
        }

        &:focus {
            border-bottom: 1px solid ${COLOR_LILA};
        }
    }
    button:not(:first-child) {
        border-left: 2px solid ${COLOR_LILA};
    }
    button {
        color: ${COLOR_LILA};
        font-size: 16px;
    }
`;

const LanguageOptions = () => {
    const { t, i18n } = useTranslation();
    const changeUserLanguage = useChangeUserLanguageMutation();

    return (
        <LanguageSection>
            <button
                className={i18n.language === 'fi' ? 'selected' : ''}
                aria-label={t('menu.lang-change-fi') || ''}
                onClick={() => changeUserLanguage('fi')}
            >
                FI
            </button>
            <button
                className={i18n.language === 'en' ? 'selected' : ''}
                aria-label={t('menu.lang-change-en') || ''}
                onClick={() => changeUserLanguage('en')}
            >
                EN
            </button>
        </LanguageSection>
    );
};

export default LanguageOptions;
