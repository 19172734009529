import React from 'react';
import { ValidationMessageBlock } from '../../components/ValidationMessageBlock';
import { COLOR_BLACK, COLOR_WARINING_BACKGROUND } from '../../styles/variables';
import { EezyButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';

interface IProps {
    onClose?: () => void;
    handleAddCostInvoice?: () => void;
}

const ExpenseReimbursementWarning = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <ValidationMessageBlock
            iconColor={COLOR_BLACK}
            type={'other'}
            style={{
                backgroundColor: COLOR_WARINING_BACKGROUND,
                borderRadius: 4,
                marginTop: 30,
                padding: 10,
            }}
            title={t('costs.warning.expense-reimbursement-title')}
            onClose={props.onClose}
        >
            <div className="flex flex-col gap-2">
                <div className="m-1 ml-0">
                    {t('costs.warning.expense-reimbursement-explanation.1')} "
                    <span style={{ fontWeight: 600 }}>{t('costs.attach-receipt')}</span>".
                    <span className="ml-1">{t('costs.warning.expense-reimbursement-explanation.3')}</span>
                </div>
                <div className="m-1 ml-0">{t('costs.warning.expense-reimbursement-explanation.2')}</div>
                {props.handleAddCostInvoice && (
                    <div className="self-end">
                        <EezyButton color="black" onClick={props.handleAddCostInvoice}>
                            {t('costs.warning.apply-for-reimbursements')}
                        </EezyButton>
                    </div>
                )}
            </div>
        </ValidationMessageBlock>
    );
};

export default ExpenseReimbursementWarning;
