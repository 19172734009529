import styled from 'styled-components';
import { TOPBAR_HEIGHT } from './../../styles/variables';

// Button wrapper to raise the button from the content to navbar area
export const MoveButtonUpToBar = styled.div`
    align-items: center;
    display: flex;
    height: ${TOPBAR_HEIGHT}px;
    position: absolute;
    right: 55px;
    top: 0;
`;
