import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { IInvoice, IInvoiceKeys } from '../../../../shared/src/types/invoice';
import { Flex } from '../../components/Flex';
import { DatePicker, FormRadio, FormRow, FormSection, FormSelect } from '../../components/form';
import { Icon } from '../../components/Icon';
import { P } from '../../components/textElements';
import { COLOR_BLUM } from '../../styles/variables';
import { addDays, formatDateISO, trans } from '../../utils';
import { getMaxDispatchDate, getMinDuedate, getPaymentTermOptions } from '../../utils/invoice/invoiceLogic';
import { formatValidationResult } from '../../utils/validation';

interface IInvoicePaymentProps {
    handleChange: (changedProperty: IInvoiceKeys) => void;
    invoice?: IInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
}

export enum DispatchDateType {
    Now = 'NOW',
    Later = 'LATER',
}

export const strToDispatchDateType = (val: string): DispatchDateType => {
    return val === DispatchDateType.Now ? DispatchDateType.Now : DispatchDateType.Later;
};

const InvoicePaymentPanel = (props: IInvoicePaymentProps) => {
    const { invoice } = props;
    const paymentTermOptions = getPaymentTermOptions(invoice);

    const [inputType, setInputType] = React.useState(invoice?.paymentTerm ? 'paymentTerm' : 'dueDate');

    const [dispatchDateType, setDispatchDateType] = useState(
        invoice?.dispatchDate ? DispatchDateType.Later : DispatchDateType.Now
    );

    const [pickerOpen, setPickerOpen] = useState(0);

    useEffect(() => {
        setInputType(R.isNil(invoice?.paymentTerm) ? 'dueDate' : 'paymentTerm');
        setDispatchDateType(invoice?.dispatchDate ? DispatchDateType.Later : DispatchDateType.Now);
    }, [invoice]);

    return (
        <div>
            <Flex column spread>
                <FormRadio
                    name="calcType"
                    onChange={setInputType}
                    onTwoLines
                    options={[
                        {
                            label: trans('invoice.paymentTerm'),
                            value: 'paymentTerm',
                        },
                        {
                            label: trans('general.duedate'),
                            value: 'dueDate',
                        },
                    ]}
                    value={inputType}
                />
                {inputType === 'paymentTerm' && (
                    <FormSelect
                        name="invoice-payment-term"
                        options={paymentTermOptions}
                        value={!R.isNil(invoice?.paymentTerm) ? invoice?.paymentTerm.toString() : ''}
                        onChange={(val: string) => {
                            props.handleChange({
                                paymentTerm: parseInt(val, 10),
                            });
                        }}
                        showIcon
                    />
                )}
                {inputType === 'dueDate' && (
                    <FormRow>
                        <DatePicker
                            disablePast={true}
                            disableToolbar
                            label={trans('general.select-duedate')}
                            minDate={getMinDuedate(invoice)}
                            placeholder=""
                            icon={['far', 'calendar-day']}
                            id="invoice-due-date"
                            onOpen={() => {
                                setPickerOpen(Date.now());
                            }}
                            onChange={(dueDate: Date | null) => {
                                // Ignore event if it occurs immediately after opening the picker.
                                // It means user haven't actually selected any date but datepicker
                                // is setting value to minDate
                                const now = Date.now();
                                const diff = now - pickerOpen;
                                if (dueDate && diff > 500) {
                                    props.handleChange({
                                        dueDate: formatDateISO(dueDate),
                                    });
                                }
                            }}
                            value={invoice?.dueDate}
                        />
                    </FormRow>
                )}
            </Flex>

            <FormSection style={{ position: 'relative' }}>
                <FormRadio
                    label={trans('invoice.debtCollection.question')}
                    helpTexts={[
                        'invoice.debtCollection.help1',
                        'invoice.debtCollection.help2',
                        'invoice.debtCollection.help3',
                        'invoice.debtCollection.help4',
                        'invoice.debtCollection.help5',
                        'invoice.debtCollection.help6',
                        'invoice.debtCollection.help7',
                        'invoice.debtCollection.help8',
                        'invoice.debtCollection.help9',
                        'invoice.debtCollection.help10',
                    ]}
                    name="fh-debtCollection"
                    onChange={(val) => props.handleChange({ debtCollection: val === 'yes' })}
                    onTwoLines
                    options={[
                        {
                            label: trans('general.no-I'),
                            value: 'no',
                        },
                        {
                            label: trans('general.yes'),
                            value: 'yes',
                        },
                    ]}
                    value={invoice?.debtCollection ? 'yes' : 'no'}
                />

                <div
                    style={{
                        alignSelf: 'flex-end',
                        bottom: 5,
                        position: 'absolute',
                        right: 10,
                    }}
                >
                    <Icon
                        icon={['far', 'user-cowboy']}
                        color={COLOR_BLUM}
                        title={trans('invoice.debtCollection.on')}
                    />
                </div>
            </FormSection>
            {invoice?.debtCollection && <P>{trans('invoice.debtCollection.help-yes1')}</P>}
            <FormSection>
                <FormRadio
                    label={trans('invoice.dispatchDate.question')}
                    name="payment-dispatch-date"
                    onChange={(val) => {
                        if (val === DispatchDateType.Now) {
                            props.handleChange({ dispatchDate: '' });
                        }
                        if (val === DispatchDateType.Later) {
                            props.handleChange({
                                dispatchDate: formatDateISO(addDays(1)),
                            });
                        }
                        setDispatchDateType(strToDispatchDateType(val));
                    }}
                    onTwoLines
                    options={[
                        {
                            label: trans('invoice.dispatchDate.immediately'),
                            value: DispatchDateType.Now,
                        },
                        {
                            label: trans('invoice.dispatchDate.chooseDate'),
                            value: DispatchDateType.Later,
                        },
                    ]}
                    value={dispatchDateType}
                />

                {dispatchDateType === DispatchDateType.Later && (
                    <FormRow>
                        <DatePicker
                            disablePast={true}
                            disableToolbar
                            label={trans('invoice.dispatchDate.pickDate')}
                            maxDate={invoice ? getMaxDispatchDate(invoice) : addDays(365)} // One year
                            minDate={addDays(1)} // Tomorrow
                            placeholder={trans('invoice.dispatchDate.placeholder')}
                            icon={['far', 'calendar-alt']}
                            id="invoice-dispatch-date"
                            onChange={(dispatchDate: Date | null) => {
                                if (dispatchDate) {
                                    props.handleChange({
                                        dispatchDate: formatDateISO(dispatchDate),
                                    });
                                }
                            }}
                            value={invoice?.dispatchDate}
                        />
                    </FormRow>
                )}
            </FormSection>
        </div>
    );
};

export default InvoicePaymentPanel;
