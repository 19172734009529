import { useApolloClient } from '@apollo/client';
import { Hidden } from '@mui/material';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IInvoice, IInvoiceItem, IInvoiceItemKeys } from '../../../../shared/src/types/invoice';
import ErrorPointer from '../../components/ErrorPointer';
import { PopoverInput, PopoverInputField } from '../../components/form/PopoverInput';
import { Icon } from '../../components/Icon';
import { PenButton } from '../../components/PenButton';
import { COLOR_GREYJOY } from '../../styles/variables';
import { formatCents, formatFloat, trans } from '../../utils';
import { addVat } from '../../utils/calc';
import { getRowUpdates } from '../../utils/invoice/invoiceLogic';
import { IInvoiceTemplate } from '../../utils/invoice/invoiceTemplates';
import validators from '../../utils/invoice/validators';
import { formatValidationResult } from '../../utils/validation';
import { openFillHelper } from './fillHelpers/utils';
import {
    Description,
    DescriptionPopOver,
    InvoiceDates,
    QuantityAndPricePopOver,
    TotalPrice,
    TotalPriceWitVat,
    VatPopOver,
} from './tableData';
import { Flex } from '../../components/Flex';

interface IInvoiceRowProps {
    editable: boolean;
    handleCopy: (row: IInvoiceItem) => void;
    handleDelete: (id: number) => void;
    handleUpdate: (id: number, changedProperties: IInvoiceItemKeys) => void;
    item: IInvoiceItem;
    template: IInvoiceTemplate;
    vatOptions?: number[];
    invoice?: IInvoice;
}

const Cell = styled.div`
    padding: 4px 10px 4px 10px;
    white-space: normal;
`;

const InvoiceRow = (props: IInvoiceRowProps) => {
    const { editable, item, template, vatOptions } = props;

    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
    const [calcType, setCalcType] = useState('units');
    const [vatType, setVatType] = useState(props.item.withVat ? 'with-vat' : 'without-vat');

    const client = useApolloClient();
    const showArrows = true;

    const updateRow = (changedProperty: IInvoiceItemKeys) => {
        const changedProperties = getRowUpdates(changedProperty, item);
        props.handleUpdate(item.id, changedProperties);
    };

    const deleteRow = () => {
        props.handleDelete(item.id);
    };

    const rowErrors = formatValidationResult(
        validators.invoiceItem.validate({
            ...props.item,
            template: template.id,
        })
    );

    const openRowOnFH = () => {
        if (props.editable) {
            openFillHelper(client, 'invoiceRow', item.id.toString());
        }
    };

    const itemProps = {
        editable,
        handleFocus: openRowOnFH,
        handleUpdate: updateRow,
        item,
    };

    const handleChange = (val: string | number, name: string) => {
        if (!props.item) {
            return;
        }

        const quantity = name === 'totalPrice' || name === 'totalPriceWithVat' ? 1 : props.item.quantity;

        let changedProperties = getRowUpdates(
            { [name]: val },
            { ...props.item, quantity },
            vatType === 'with-vat'
        );

        if (name === 'totalPrice' || name === 'totalPriceWithVat') {
            changedProperties = { ...changedProperties, quantity: 1 };
        }

        updateRow(changedProperties);
    };

    const handleSetVatType = (val: string) => {
        const row = props.item;
        const price = row?.price || 0;
        const vat = row?.vat || 0;
        const withVat = val === 'with-vat';
        const updatedPrice = withVat ? price : addVat(price, vat);

        if (row) {
            const changedProperties = getRowUpdates({ price: updatedPrice, withVat }, row, withVat);
            updateRow(changedProperties);
        }

        setVatType(val);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    const togglePopover = (e: MouseEvent) => {
        if (!props.editable) {
            return;
        }
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleTab = useCallback((event: KeyboardEvent) => {
        if (event.code === 'CapsLock') {
            // TODO: Tab opens next popup
        }
    }, []);

    const QuantityAndPrice = () => (
        <QuantityAndPricePopOver
            style={{ width: 363 }}
            handleUpdate={updateRow}
            calcType={calcType}
            setCalcType={setCalcType}
            item={item}
            vatType={vatType}
            rowErrors={rowErrors}
            showArrows={showArrows}
            handleChange={handleChange}
        />
    );

    useEffect(() => {
        document.addEventListener('keydown', handleTab, false);
        return () => {
            document.removeEventListener('keydown', handleTab, false);
        };
    }, [handleTab]);

    return (
        <tr id={`invoice-row-${item.id}`}>
            <Hidden smDown>
                <td>
                    {rowErrors && showArrows && editable && <ErrorPointer style={{ marginTop: 0 }} />}

                    {props.editable ? (
                        <PopoverInput
                            id="description"
                            anchorEl={anchorEl}
                            editable={props.editable}
                            onClick={togglePopover}
                            onClose={closePopover}
                            required
                            showArrow={false}
                            style={{
                                padding: '4px 10px 4px 10px',
                            }}
                            popoverContent={
                                <DescriptionPopOver
                                    style={{ width: 363 }}
                                    handleUpdate={updateRow}
                                    item={item}
                                    error={showArrows && rowErrors?.description}
                                />
                            }
                        >
                            <PopoverInputField
                                placeholder={trans('invoice.tableColumns.description-placeholder')}
                                value={item.description}
                            />
                        </PopoverInput>
                    ) : (
                        <Cell data-mf-replace="**REMOVED**">{item.description}</Cell>
                    )}

                    {item.itemType === 'work' && (
                        <InvoiceDates {...itemProps} error={showArrows && rowErrors?.startDate} />
                    )}
                    {item.itemType === 'material' && (
                        <span className="light">{trans('invoice.tableColumns.material')}</span>
                    )}
                    {item.itemType === 'travel' && (
                        <>
                            <span className="light" style={{ paddingRight: '5px' }}>
                                {trans('invoice.tableColumns.travel')}
                            </span>
                            <InvoiceDates {...itemProps} error={showArrows && rowErrors?.startDate} />
                        </>
                    )}
                </td>
                <td>
                    {props.editable ? (
                        <PopoverInput
                            id="quantity"
                            anchorEl={anchorEl}
                            editable={props.editable}
                            onClick={togglePopover}
                            onClose={closePopover}
                            required
                            showArrow={false}
                            style={{
                                padding: '4px 10px 4px 10px',
                                whiteSpace: 'nowrap',
                            }}
                            popoverContent={<QuantityAndPrice />}
                        >
                            {item.quantity
                                ? trans(`form.x-${props.item.quantityUnit}`, {
                                      x: item.quantity,
                                  }).trim()
                                : trans(`form.x-${props.item.quantityUnit}`, {
                                      x: 0,
                                  }).trim()}
                        </PopoverInput>
                    ) : (
                        <Cell>
                            {item.quantity
                                ? trans(`form.x-${props.item.quantityUnit}`, {
                                      x: item.quantity,
                                  }).trim()
                                : trans(`form.x-${props.item.quantityUnit}`, {
                                      x: 0,
                                  }).trim()}
                        </Cell>
                    )}
                </td>
                <td>
                    {props.editable ? (
                        <PopoverInput
                            id="price"
                            anchorEl={anchorEl}
                            editable={props.editable}
                            onClick={togglePopover}
                            onClose={closePopover}
                            required
                            showArrow={false}
                            style={{
                                padding: '4px 10px 4px 10px',
                                whiteSpace: 'nowrap',
                            }}
                            popoverContent={<QuantityAndPrice />}
                        >
                            {`${formatCents(item.price, true)} €`}
                        </PopoverInput>
                    ) : (
                        <Cell>{`${formatCents(item.price, true)} €`}</Cell>
                    )}
                </td>
                <td style={{ maxWidth: '60px', whiteSpace: 'nowrap' }}>
                    {props.editable ? (
                        <PopoverInput
                            id="vat"
                            anchorEl={anchorEl}
                            editable={props.editable}
                            onClick={togglePopover}
                            onClose={closePopover}
                            required
                            showArrow={false}
                            style={{
                                padding: '4px 10px 4px 10px',
                                whiteSpace: 'nowrap',
                            }}
                            popoverContent={
                                <VatPopOver
                                    style={{ width: 363 }}
                                    vatType={vatType}
                                    setVatType={handleSetVatType}
                                    item={item}
                                    handleChange={handleChange}
                                    error={showArrows && rowErrors?.vat}
                                    vatList={vatOptions}
                                    template={template}
                                />
                            }
                        >
                            {item.vat ? `${item.vat} %` : `0 %`}
                        </PopoverInput>
                    ) : (
                        <Cell>{item.vat ? `${item.vat} %` : `0 %`}</Cell>
                    )}
                </td>
                <td>
                    {editable ? (
                        <TotalPrice {...itemProps} />
                    ) : (
                        <Cell>
                            {item.totalPrice
                                ? `${formatCents(item.totalPrice, true)} €`
                                : `${formatCents(0, true)} €`}
                        </Cell>
                    )}
                </td>
                <td>
                    {editable ? (
                        <TotalPriceWitVat {...itemProps} />
                    ) : (
                        <Cell>
                            {item.totalPriceWithVat
                                ? `${formatCents(item.totalPriceWithVat, true)} €`
                                : `${formatCents(0, true)} €`}
                        </Cell>
                    )}
                </td>
                <td className="td-del">
                    {editable && (
                        <>
                            <button
                                aria-label={trans('invoice.tableColumns.copy-row')}
                                onClick={() => {
                                    props.handleCopy(item);
                                }}
                                style={{ marginRight: '5px' }}
                            >
                                <Icon icon={['far', 'copy']} color={COLOR_GREYJOY} />
                            </button>
                            <button aria-label={trans('invoice.tableColumns.delete-row')} onClick={deleteRow}>
                                <Icon icon={['far', 'trash-alt']} color={COLOR_GREYJOY} />
                            </button>
                        </>
                    )}
                </td>
            </Hidden>

            <Hidden smUp>
                <td>
                    {rowErrors && showArrows && editable && <ErrorPointer style={{ marginTop: 0 }} />}
                    <Flex column>
                        <Flex>
                            <Description {...itemProps} />{' '}
                            {props.editable ? (
                                <div onClick={openRowOnFH}>
                                    {trans(`form.x-${props.item.quantityUnit}`, {
                                        x: formatFloat(item.quantity || 0),
                                    })}
                                </div>
                            ) : (
                                <div style={{ margin: '0 15px' }}>
                                    {trans(`form.x-${item.quantityUnit}`, {
                                        x: formatFloat(itemProps.item?.quantity || 0),
                                    })}
                                    <br />
                                </div>
                            )}
                        </Flex>
                        {item.itemType === 'work' && (
                            <InvoiceDates {...itemProps} error={showArrows && rowErrors?.startDate} />
                        )}
                    </Flex>
                    {item.itemType === 'material' && (
                        <span className="light">{trans('invoice.tableColumns.material')}</span>
                    )}
                    {item.itemType === 'travel' && (
                        <span className="light">{trans('invoice.tableColumns.travel')}</span>
                    )}
                </td>
                <td>
                    {trans(`form.x-e-${itemProps.item?.quantityUnit}`, {
                        x: formatCents(itemProps.item?.price, true),
                    })}
                    <br />
                    {trans('form.eurs', {
                        eurs: formatCents(itemProps.item?.totalPrice, true),
                    })}
                </td>
                {editable && (
                    <td>
                        <PenButton onClick={openRowOnFH} />
                    </td>
                )}
            </Hidden>
        </tr>
    );
};

export default InvoiceRow;
