import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';
import { COLOR_BLUM, COLOR_WHITE_WALKER, FOCUS_COLOR } from '../../styles/variables';
import { tokenize } from '../../utils';

const StyledLabel = styled.label`
    border: 2px solid ${COLOR_BLUM};
    border-radius: 4px;
    color: ${COLOR_BLUM};
    cursor: pointer;
    display: block;
    font-weight: bold;
    padding: 10px;
    text-align: center;

    &:hover {
        background-color: ${COLOR_BLUM};
        color: ${COLOR_WHITE_WALKER};
    }

    &:focus {
        border: ${FOCUS_COLOR};
    }
`;

const StyledInput = styled.input.attrs(() => ({
    type: 'radio',
}))`
    height: 1px;
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0px;

    &:checked + label {
        background-color: ${COLOR_BLUM};
        color: ${COLOR_WHITE_WALKER};
    }
    &:focus + label {
        outline: 1px solid ${FOCUS_COLOR};
        outline-offset: 3px;
    }
`;

interface IRadioProps {
    checked: boolean;
    label: string;
    name: string;
    onChange: (arg0: any) => void;
    value: any;
}

export const RadioInput = (props: IRadioProps) => {
    const { checked, label, name, onChange, value } = props;
    const inputId = tokenize(label);

    const enterPressed = (e: KeyboardEvent<HTMLInputElement>) => {
        const code = e.key;
        if (code === 'Enter') {
            // enter pressed, needed in a case when a radio input opens a modal
            // enter opens the modal again
            onChange(e);
        }
    };

    return (
        <div>
            <StyledInput
                aria-labelledby={`label-${inputId}`}
                id={inputId}
                name={name}
                checked={checked}
                onChange={onChange}
                onKeyPress={enterPressed}
                value={value}
            />
            <StyledLabel id={`label-${inputId}`} htmlFor={inputId}>
                {label}
            </StyledLabel>
        </div>
    );
};
