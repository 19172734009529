import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IContractTemplate } from '../../../../shared/src/types/user';
import { EezyButton, EezyLink } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { BodyP, LabelMedium } from 'components/textElements';
import { COLOR_BLUM } from 'styles/variables';
import { GET_CONTRACT_TEMPLATES } from './queries';
import SearchField from 'components/SearchField';

interface IProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const ContractsModal = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const { data: contractsData } = useQuery(GET_CONTRACT_TEMPLATES, {
        onError: () => null,
        variables: { lang: i18n.language },
    });

    const filteredAndSortedContractTemplates = useMemo(() => {
        return contractsData?.contractTemplates
            ?.slice()
            .filter((contract: IContractTemplate) =>
                contract.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .sort((a: IContractTemplate, b: IContractTemplate) => a.name.localeCompare(b.name));
    }, [contractsData, searchQuery]);

    return (
        <Modal
            id="modal-contracts"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('profile:important.contracts.title') || ''}
        >
            <ModalContent>
                <BodyP>{t('profile:important.contracts.modal-1')}</BodyP>

                <BodyP style={{ margin: '16px 0' }}>{t('profile:important.contracts.modal-2')}</BodyP>

                <div style={{ marginBottom: '20px' }}>
                    <SearchField
                        onChange={setSearchQuery}
                        placeholder={t('general.search')}
                        query={searchQuery}
                    />
                </div>

                {filteredAndSortedContractTemplates?.map((contract: IContractTemplate, i: number) => (
                    <Flex spread fullWidth style={{ marginTop: 22 }} key={`contract-${i}`}>
                        <LabelMedium color={COLOR_BLUM}>{contract.name}</LabelMedium>
                        <EezyLink
                            color="purple"
                            href={contract.url}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {t('profile:important.download')}
                        </EezyLink>
                    </Flex>
                ))}

                {filteredAndSortedContractTemplates?.length === 0 && <BodyP>{t('general.no-options')}</BodyP>}
            </ModalContent>

            <ModalActions style={{ justifyContent: 'center' }}>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default ContractsModal;
