import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import NestedPageWrapper from 'components/layout/NestedPageWrapper';
import StatisticsBillingDetail from 'components/statistics/StatisticsBillingDetail';
import StatisticsBillingSuggestions from 'components/statistics/StatisticsBillingSuggestions';
import NestedPageNavigationTabs from 'components/layout/NestedPageNavigationTabs';
import {
    briefInvoiceToStatisticInvoice,
    checkInvoiceAcceptForStatistics,
    invoiceDateFilter,
    statisticInvoicesToGraphData,
} from './statisticUtils';
import { SalesPageStatisticData, StatisticInvoice } from './statisticsPageTypes';
import { useQuery } from '@apollo/client';
import { GET_INVOICES } from 'containers/dashboard/queries';
import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { ITransaction } from '../eezypay/Transactions';
import { cn, priceWithoutVat } from 'utils';
import { capitalize } from 'utils/str';
import StatisticsBillingChart from 'components/statistics/StatisticsBillingChart';
import { DateRange } from 'react-day-picker';
import { DateFilter, getTimeLabel, getTimeRange } from 'utils/statistics/dateUtils';
import { CheckBox } from 'components/ui/checkbox';
import { GET_PAYMENTS } from '../eezypay/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CHART_COMPARE_COLORS, ChartColor } from './statisticConstants';
import StatisticPageFilters from 'components/statistics/StatisticPageFilters';
import { SalesPageStatisticSuggestion } from 'hooks/statistics/useStatisticSuggestions';
import StatisticBillingSuggestionsMobile from 'components/statistics/StatisticBillingSuggestionsMobile';
import { useUser } from 'queries/useUserQuery';

enum SalesTabEnum {
    Laskutus = 'billing',
    EezyPay = 'eezypay',
}

// If period is longer than 60 days, we group by month
const DAY_CUTOFF = 60;

const StatisticsPage = () => {
    const user = useUser();
    const [activeSalesTab, setActiveSalesTab] = useState<SalesTabEnum>(SalesTabEnum.Laskutus);

    // Mobile date change needs to be confirmed, desktop doesn't
    const [unconfirmedDateFilter, setUnconfirmedDateFilter] = useState({
        to: moment().endOf('date').toDate(),
        from: moment().startOf('year').toDate(),
    });

    const [dateFilter, setDateFilter] = useState<DateFilter>(unconfirmedDateFilter);

    const [selectedGraphs, setSelectedGraphs] = useState<{ dateFilter: DateFilter; color: ChartColor }[]>([]);
    const [withVat, setWithVat] = useState(false);
    const [clientFilter, setClientFilter] = useState<string | null>(null);

    const { t } = useTranslation();
    const { data: allInvoicesData, loading: loadingInvoices } = useQuery<{
        allInvoices: { items: IBriefInvoice[] };
    }>(GET_INVOICES);

    // We could skip this if we are not in the right tab, but that causes
    // the query to be refetched every time we change tabs
    const { data: eezyPayData, loading: eezyPayDataLoading } = useQuery(GET_PAYMENTS, {
        context: { clientName: 'eezyPayHasura' },
        fetchPolicy: 'cache-and-network',
        variables: {
            searchTerm: '%%',
        },
    });

    const subNavigationLinks = [
        { label: t('statistic.tabs.invoices'), value: SalesTabEnum.Laskutus },
        { label: t('statistic.tabs.eezy-page'), value: SalesTabEnum.EezyPay },
    ];

    // If period is more than 60 days, we group by month
    const groupByMonth = moment(dateFilter.to).diff(moment(dateFilter.from), 'days') > DAY_CUTOFF;
    const spansMultipleYears = moment(dateFilter.from).year() !== moment(dateFilter.to).year();

    const billingInvoices: StatisticInvoice[] | undefined = useMemo(
        () => allInvoicesData?.allInvoices.items.map(briefInvoiceToStatisticInvoice),
        [allInvoicesData, user, clientFilter]
    );

    const eezyPayInvoices: StatisticInvoice[] | undefined = useMemo(
        () =>
            eezyPayData?.transactions?.map((i: ITransaction) => ({
                id: i.orderNumber.toString(),
                date: i.completed,
                status: 'completed',
                total: priceWithoutVat(i.servicePrice, i.serviceVat),
                totalWithVat: i.servicePrice,
            })),
        [eezyPayData]
    );

    const invoices = useMemo(
        () =>
            (activeSalesTab === SalesTabEnum.Laskutus
                ? billingInvoices?.filter((i) => !clientFilter || i.clientName === clientFilter)
                : eezyPayInvoices) ?? [],
        [activeSalesTab, billingInvoices, eezyPayInvoices]
    );

    const graphDataOptions = useMemo(
        () => ({ groupByMonth, spansMultipleYears, withVat }),
        [groupByMonth, spansMultipleYears, withVat]
    );

    const chartData = useMemo(
        () =>
            selectedGraphs.map((graph) =>
                statisticInvoicesToGraphData(invoices, graph.dateFilter, graph.color, graphDataOptions)
            ),
        [selectedGraphs, invoices, graphDataOptions]
    );

    const mainGraphData = useMemo(
        // Note, the color is not used for the main graph, as it's hardcoded in SalesStatsBillingChart
        () => statisticInvoicesToGraphData(invoices, dateFilter, '#0369A1', graphDataOptions),
        [activeSalesTab, dateFilter, invoices, graphDataOptions]
    );

    const clients = useMemo(
        () =>
            Array.from(
                new Set(
                    billingInvoices
                        ?.filter(invoiceDateFilter([dateFilter, ...selectedGraphs.map((g) => g.dateFilter)]))
                        ?.filter(checkInvoiceAcceptForStatistics)
                        .map((i) => i.clientName ?? '') ?? []
                )
            ),
        [mainGraphData, chartData, dateFilter, selectedGraphs]
    );

    useEffect(() => {
        if (clientFilter && !clients.includes(clientFilter)) {
            setClientFilter(null);
        }
    }, [clients]);

    const minDatePicker = useMemo(() => {
        if (!billingInvoices) return new Date();
        const invoicesWithDate = billingInvoices.filter((i) => i.date);
        const firstInvoice = invoicesWithDate[invoicesWithDate.length - 1];
        if (!firstInvoice) {
            return new Date();
        }

        return moment(firstInvoice.date).toDate();
    }, [billingInvoices]);

    const visibleLabelText = (() => {
        const timeRangeId = getTimeRange(dateFilter);

        if (timeRangeId)
            return t(
                activeSalesTab == SalesTabEnum.Laskutus
                    ? 'statistic.stats-billing'
                    : 'statistic.eezypay-billing',
                { when: t(`statistic.date-period.${timeRangeId}`) }
            );

        return capitalize(getTimeLabel(dateFilter));
    })();

    useEffect(() => {
        setSelectedGraphs([]);
    }, [dateFilter, activeSalesTab]);

    const onSelectSuggestionRange = (item: SalesPageStatisticSuggestion) => {
        // Check duplicate
        const alreadyExist = chartData.some((i) => i.dateRangeString == item.dateRangeString);
        if (alreadyExist) {
            return;
        }

        // Find unused color
        const color =
            CHART_COMPARE_COLORS.find((c) => !selectedGraphs.some((g) => g.color == c)) || '#0369A1';

        setSelectedGraphs([...selectedGraphs, { dateFilter: item.value, color }]);
    };

    const onRemoveSuggestionRange = (item: SalesPageStatisticData) => {
        setSelectedGraphs(selectedGraphs.filter((g) => g.color !== item.color));
    };

    const onChangeFilterDateRange = (dateRange: DateRange, fromMobile?: boolean) => {
        if (!dateRange.from || !dateRange.to) return;
        const newDateFilter = {
            ...dateFilter,
            from: moment(dateRange.from).toDate(),
            to: moment(dateRange.to).toDate(),
        };

        // Mobile date change needs to be confirmed, desktop doesn't
        setUnconfirmedDateFilter(newDateFilter);
        if (!fromMobile) setDateFilter(newDateFilter);
    };

    const suggestionsComponentProps = {
        dateFilter,
        chartDataDateStrings: [mainGraphData, ...chartData].map((i) => i.dateRangeString),
        onSelect: onSelectSuggestionRange,
        minDate: minDatePicker,
    };

    const loading =
        (loadingInvoices && activeSalesTab === SalesTabEnum.Laskutus) ||
        (eezyPayDataLoading && activeSalesTab === SalesTabEnum.EezyPay);

    // This is in different place in mobile view, so we should have it here.
    const vatCheckBox = (
        <CheckBox
            checked={withVat}
            label={t('statistic.stats-include-vat')}
            onCheckedChange={(checked) => setWithVat(!!checked.valueOf())}
        />
    );

    return (
        <NestedPageWrapper name={t('statistic.page-title')}>
            <NestedPageNavigationTabs
                links={subNavigationLinks}
                activeValue={activeSalesTab}
                onChange={setActiveSalesTab}
            />

            {loading ? (
                <div className="flex flex-col justify-center items-center gap-4 mt-8">
                    <span>{t('statistic.loading-statistics')}</span>
                    <FontAwesomeIcon
                        icon={['fas', 'spinner-third']}
                        className="animate-spin size-8 text-violet-600"
                    />
                </div>
            ) : (
                <>
                    <div className="flex flex-col gap-8">
                        <div className="flex gap-8 flex-col xl:flex-row">
                            <StatisticPageFilters
                                dateFilter={dateFilter}
                                onChangeDateFilter={onChangeFilterDateRange}
                                clients={clients}
                                clientFilter={clientFilter}
                                onChangeClientFilter={setClientFilter}
                                minDate={minDatePicker}
                                showClientFilter={activeSalesTab === SalesTabEnum.Laskutus}
                            />

                            <div className="w-px min-w-px hidden xl:block bg-gray-200" />
                            <div className="hidden md:block">
                                <div className="tg-caption-bold text-gray-800 mb-2">
                                    {t('statistic.compare')}
                                </div>
                                <StatisticsBillingSuggestions {...suggestionsComponentProps} />
                            </div>
                        </div>
                        <div className="block md:hidden">{vatCheckBox}</div>
                    </div>

                    <div className="md:flex md:justify-between">
                        <div className="flex flex-col md:flex-row gap-7 md:gap-11">
                            <div className="flex justify-between">
                                <StatisticsBillingDetail
                                    data={mainGraphData}
                                    subtext={visibleLabelText}
                                    isMainData
                                />
                                <StatisticBillingSuggestionsMobile {...suggestionsComponentProps} />
                            </div>
                            <div
                                className={cn(
                                    'flex gap-4 md:gap-11 pb-4 pt-2 md:pb-0',
                                    chartData.length >= 3 &&
                                        'overflow-x-auto md:overflow-x-visible -mr-[26px] md:mr-0'
                                )}
                            >
                                {chartData.map((data) => (
                                    <StatisticsBillingDetail
                                        key={data.dateRangeString}
                                        data={data}
                                        isMainData={false}
                                        onRemove={() => onRemoveSuggestionRange(data)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="hidden md:block">{vatCheckBox}</div>
                    </div>
                    <div className="md:mx-0">
                        <StatisticsBillingChart mainGraph={mainGraphData} data={chartData} />
                    </div>
                </>
            )}
        </NestedPageWrapper>
    );
};

export default StatisticsPage;
