import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import Login from 'containers/login/Login';
import Root from 'containers/Root';
import PasswordReset from 'containers/login/PasswordReset';
import { LoginProtected } from 'containers/LoginProtected';
import Register from 'containers/login/Register';
import ErrorUrlHandler from 'containers/error/ErrorUrlHandler';
import ModalOpener from 'containers/ModalOpener';
import Dashboard from 'containers/dashboard/Dashboard';
import NewInvoice from 'containers/invoice/NewInvoice';
import Invoice from 'containers/invoice/Invoice';
import InvoiceCosts from 'containers/costs/InvoiceCosts';
import InvoiceWrapper from 'containers/invoice/InvoiceWrapper';
import Logout from 'containers/login/Logout';
import Terms from 'containers/Terms';
import SalarySummary from 'containers/salaries/SalarySummary';
import SalaryPreview from 'containers/salaries/SalaryPreview';
import SalaryList from 'containers/salaries/SalaryList';
import { DrawerWrapper } from 'routes/DrawerWrapper';
import Profile from 'containers/profile/Profile';
import AuthCallback from 'containers/dashboard/AuthCalback';
import { YelWrapper } from 'routes/YelWrapper';
import Yel from 'containers/yel/Yel';
import YelEdit from 'containers/yel/YelEdit';
import InsuranceInfoDocument from 'containers/yel/InsuranceInfoDocument';
import YelAuthCallback from 'containers/yel/YelAuthCallback';
import ErrorView from 'containers/error/Error';
import { SalesPagesWrapper } from 'routes/SalesPagesWrapper';
import SalesPages from 'containers/eezypay/SalesPages';
import Transactions from 'containers/eezypay/Transactions';
import SalesPageCreate from 'containers/eezypay/SalesPageCreate';
import StatisticsPage from 'containers/statistics/StatisticsPage';
import SalaryCalculatorWrapper from 'containers/salary-calculator/SalaryCalculator';
import SalaryCalculatorPlainFi from 'containers/salary-calculator/SalaryCalculatorPlainFi';
import SalaryCalculatorPlainEn from 'containers/salary-calculator/SalaryCalculatorPlainEn';
import RestrictedWorks from 'containers/docs/RestrictedWorks';
import TermsFi from 'components/modals/TermsFi';
import TermsEn from 'components/modals/TermsEn';
import GdprFi from 'containers/dashboard/GdprFi';
import GdprEn from 'containers/dashboard/GdprEn';
import InvoicePreview from 'containers/dashboard/InvoicePreview';
import GroupInvoicePreview from 'containers/invoice/GroupInvoicePreview';
import RegisterAuth from 'containers/login/RegisterAuth';
import RegisterWrapper from 'routes/RegisterWrapper';
import MessageList from 'containers/support/MessageList';
import ContactPage from 'containers/support/ContactPage';
import NewMessageView from 'containers/support/NewMessage';
import ThreadView from 'containers/support/Thread';
import Salary from 'containers/salaries/Salary';
import InvoiceList from 'containers/dashboard/InvoiceList';
import EezyPayTermsFi from 'components/modals/EezyPayTermsFi';
import EezyPayTermsEn from 'components/modals/EezyPayTermsEn';
import DashboardWrapper from 'containers/dashboard/DashboardWrapper';
import StatisticsWrapper from './StatisticsWrapper';
import { SupportWrapper } from 'routes/SupportWrapper';

const RouterRewrite = () => {
    const params = useParams();
    return <Navigate to={`/${params['*']}`} replace />;
};

const routes = [
    {
        element: <Root />,
        children: [
            {
                // Makes /u/foo/bar redirect to /foo/bar
                path: '/u/*',
                element: <RouterRewrite />,
            },
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/reset-password/:token',
                element: <PasswordReset />,
            },
            {
                element: <RegisterWrapper />,
                children: [
                    {
                        path: '/register',
                        element: (
                            <ModalOpener>
                                <Register />
                            </ModalOpener>
                        ),
                    },
                    {
                        path: '/register_auth',
                        element: <RegisterAuth />,
                    },
                    {
                        path: '/register_form',
                        element: (
                            <ModalOpener>
                                <Register type="only_form" />
                            </ModalOpener>
                        ),
                    },
                ],
            },
            {
                path: '/salary-calculator',
                element: <SalaryCalculatorWrapper />,
            },
            {
                path: '/salary-calc-fi',
                element: <SalaryCalculatorPlainFi />,
            },
            {
                path: '/salary-calc-en',
                element: <SalaryCalculatorPlainEn />,
            },
            {
                path: '/docs',
                children: [
                    {
                        path: 'restricted_works_fi',
                        element: (() => {
                            return <RestrictedWorks lang="fi" />;
                        })(),
                    },
                    {
                        path: 'restricted_works_en',
                        element: (() => {
                            return <RestrictedWorks lang="en" />;
                        })(),
                    },
                    {
                        path: 'terms_fi',
                        element: <TermsFi />,
                    },
                    {
                        path: 'terms_en',
                        element: <TermsEn />,
                    },
                    {
                        path: 'gdpr_fi',
                        element: <GdprFi />,
                    },
                    {
                        path: 'gdpr_en',
                        element: <GdprEn />,
                    },
                    {
                        path: 'eezypay_fi',
                        element: <EezyPayTermsFi />,
                    },
                    {
                        path: 'eezypay_en',
                        element: <EezyPayTermsEn />,
                    },
                ],
            },
            {
                element: (
                    <ModalOpener>
                        <LoginProtected />
                    </ModalOpener>
                ),
                children: [
                    {
                        path: '/logout',
                        element: <Logout />,
                    },
                    {
                        path: '/authenticate',
                        children: [
                            {
                                index: true,
                                element: <AuthCallback result="modal" />,
                            },
                            {
                                path: 'user',
                                children: [
                                    {
                                        path: 'success',
                                        element: <AuthCallback result="success" />,
                                    },
                                    {
                                        path: 'error',
                                        element: <AuthCallback result="error" />,
                                    },
                                ],
                            },
                            {
                                path: '*',
                                element: <Navigate to="/authenticate" />,
                            },
                        ],
                    },
                    {
                        path: '/terms-of-service',
                        element: (
                            <ModalOpener>
                                <Terms />
                            </ModalOpener>
                        ),
                    },
                    {
                        path: '/error/not_authenticated',
                        element: <Navigate to="/" />,
                    },
                    {
                        path: '/error/:errorCode?',
                        element: <ErrorUrlHandler />,
                    },
                    {
                        path: '/profile',
                        element: <DrawerWrapper />,
                        children: [
                            {
                                index: true,
                                element: <Profile />,
                            },
                            {
                                path: 'contact',
                                element: <Profile cardOpen="contact" />,
                            },
                            {
                                path: 'salary',
                                element: <Profile cardOpen="salary" />,
                            },
                            {
                                path: 'documents',
                                element: <Profile cardOpen="" />,
                            },
                        ],
                    },
                    {
                        path: '/salaries',
                        element: <DrawerWrapper />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <>
                                        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                            <SalaryList />
                                        </Box>
                                        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                            <Salary>
                                                <SalarySummary />
                                            </Salary>
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                path: '/salaries/:salaryId',
                                element: (
                                    <>
                                        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                            <SalaryPreview />
                                        </Box>
                                        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                            <Salary>
                                                <SalaryPreview />
                                            </Salary>
                                        </Box>
                                    </>
                                ),
                            },
                        ],
                    },
                    {
                        path: '/sales-pages',
                        element: <SalesPagesWrapper />,
                        children: [
                            {
                                index: true,
                                element: <SalesPages />,
                            },
                            {
                                path: '/sales-pages/payments',
                                element: <Transactions />,
                            },
                            {
                                path: '/sales-pages/create',
                                element: <SalesPageCreate />,
                            },
                            {
                                path: '/sales-pages/edit/:publicPath',
                                element: <SalesPageCreate />,
                            },
                        ],
                    },
                    {
                        path: '/support',
                        element: <SupportWrapper />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <>
                                        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                            <MessageList />
                                        </Box>
                                        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                            <ContactPage />
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                path: 'contact',
                                element: <ContactPage />,
                            },
                            {
                                path: 'new-message',
                                element: <NewMessageView />,
                            },
                            {
                                path: 'messages/:threadId',
                                element: <ThreadView />,
                            },
                            {
                                path: '*',
                                element: <Navigate to="/support" />,
                            },
                        ],
                    },
                    {
                        path: '/yel',
                        element: <YelWrapper />,
                        errorElement: <ErrorView yelPage />,
                        children: [
                            {
                                index: true,
                                element: <Yel />,
                            },
                            {
                                path: 'edit',
                                element: <YelEdit />,
                            },
                            {
                                path: 'update',
                                element: <YelAuthCallback authAction="updateYel" />,
                            },
                            {
                                path: 'create',
                                element: <YelAuthCallback authAction="createYel" />,
                            },
                            {
                                path: 'transfer',
                                element: <YelAuthCallback authAction="transferYel" />,
                            },
                            {
                                path: 'withdraw',
                                element: <YelAuthCallback authAction="yelWithdrawal" />,
                            },
                            {
                                path: 'terminate',
                                element: <YelAuthCallback authAction="terminateYel" />,
                            },
                            {
                                path: 'insurance-info',
                                element: <InsuranceInfoDocument />,
                            },
                            {
                                path: '*',
                                element: <Navigate to="/yel" />,
                            },
                        ],
                    },
                    {
                        path: '/invoices',
                        children: [
                            {
                                path: 'new',
                                element: <NewInvoice />,
                            },
                            {
                                path: '/invoices/:invoiceId/expenses/:costId',
                                element: (
                                    <InvoiceCosts type="cost">
                                        <Invoice />
                                    </InvoiceCosts>
                                ),
                            },
                            {
                                path: '/invoices/:invoiceId/expenses/new',
                                element: (
                                    <InvoiceCosts type="cost">
                                        <Invoice />
                                    </InvoiceCosts>
                                ),
                            },
                            {
                                path: '/invoices/:invoiceId/travels/:costId',
                                element: (
                                    <InvoiceCosts type="travel">
                                        <Invoice />
                                    </InvoiceCosts>
                                ),
                            },
                            {
                                path: '/invoices/:invoiceId',
                                element: <Invoice />,
                            },
                            {
                                element: <InvoiceWrapper />,
                                children: [
                                    {
                                        path: 'view/:invoiceId',
                                        element: <InvoicePreview />,
                                    },
                                    {
                                        index: true,
                                        element: (
                                            <>
                                                <Box sx={{ display: { md: 'none', xs: 'block' } }}>
                                                    <InvoiceList />
                                                </Box>
                                                <Box sx={{ display: { md: 'block', xs: 'none' } }}>
                                                    <Dashboard />
                                                </Box>
                                            </>
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: '/statistics',
                        element: <StatisticsWrapper />,
                        children: [
                            {
                                index: true,
                                element: <StatisticsPage />,
                            },
                        ],
                    },
                    {
                        path: '/group',
                        children: [
                            {
                                path: '/group/:invoiceId/expenses/:costId',
                                element: (
                                    <InvoiceCosts type="cost">
                                        <Invoice />
                                    </InvoiceCosts>
                                ),
                            },
                            {
                                path: '/group/:invoiceId/expenses/new',
                                element: (
                                    <InvoiceCosts type="cost">
                                        <Invoice />
                                    </InvoiceCosts>
                                ),
                            },
                            {
                                path: '/group/:invoiceId/travels/:costId',
                                element: (
                                    <InvoiceCosts type="travel">
                                        <Invoice />
                                    </InvoiceCosts>
                                ),
                            },
                            {
                                path: '/group/:invoiceId',
                                element: <Invoice />,
                            },
                            {
                                element: <InvoiceWrapper />,
                                children: [
                                    {
                                        path: '/group/view/:invoiceId',
                                        element: <GroupInvoicePreview />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        element: <InvoiceWrapper />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <DashboardWrapper>
                                        <Dashboard />
                                    </DashboardWrapper>
                                ),
                            },
                            {
                                path: '/notifications',
                                element: (
                                    <DashboardWrapper>
                                        <Dashboard />
                                    </DashboardWrapper>
                                ), //TODO: Only on mobile should be...
                            },
                        ],
                    },
                ],
            },
            {
                path: '*',
                element: <Navigate to="/" />,
            },
        ],
    },
];

export default routes;
