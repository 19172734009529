import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { COLOR_BLACKWATER, COLOR_WHITE_WALKER } from '../styles/variables';
import { Icon } from './Icon';

const StyledTag = styled.div`
    align-items: center;
    background-color: ${COLOR_BLACKWATER};
    border-radius: 100px;
    color: ${COLOR_WHITE_WALKER};
    display: inline-flex;
    font-size: 13px;
    font-weight: normal;
    height: 20px;
    justify-content: space-between;
    letter-spacing: 0.15px;
    padding: 0 5px 0 10px;
    text-align: center;
    text-transform: none;
    vertical-align: text-bottom;
    width: 115px;

    && svg {
        font-size: 15px;
        height: 15px;
        margin-right: 4px;
        width: 15px;
    }
`;

interface ITagProps {
    children: ReactNode;
    style?: CSSProperties;
}
const Tag = (props: ITagProps) => {
    return <StyledTag style={props.style}>{props.children}</StyledTag>;
};

export const ExpressTag = ({ style }: { style?: CSSProperties }) => {
    return (
        <Tag style={style}>
            EezyExpress <Icon color="white" icon={['fal', 'euro-sign']} />
        </Tag>
    );
};
