import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IUserBasicData } from '../../../../shared/src/types/user';
import { EezyButton } from 'components/Buttons';
import { ManualCard } from 'components/cards';
import { ExampleImage } from 'components/ExampleImage';
import { Flex } from 'components/Flex';
import { FormInput, FormRadio, FormRow } from 'components/form';
import FileInput from 'components/form/FileInput';
import { FormLabel } from 'components/form/FormLabel';
import LoadingSpinner from 'components/Loading';
import { BodyP, PSmall } from 'components/textElements';
import config from 'config';
import { COLOR_BLUM, COLOR_DARK_GRAY, SCREEN_S, COLOR_IMPORTANT } from 'styles/variables';
import { COST_RECEIPT_FILETYPES } from 'utils/costs/costLogic';
import { getNameOptions, WHOLE_NAME } from 'utils/profile/marketing';
import { GET_USER_DATA, UPDATE_USER_DATA } from './queries';
import { InfoTooltip } from 'components/ToolTip';
import { Icon } from 'components/Icon';
import { useUserQuery } from 'queries/useUserQuery';

const ImageWrapper = styled.div`
    margin-right: 10px;
`;

const IconWrapper = styled.span`
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
`;

const MarketingWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
    }
`;

const MarketingCopyLink = styled.div`
    display: flex;
    flex-direction: column;

    button {
        margin-top: 10px;
    }

    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
        justify-content: space-between;

        button {
            margin-top: 0;
        }
    }
`;

const CopyButtonWrapper = styled.div`
    text-align: left;

    @media (min-width: ${SCREEN_S}px) {
        text-align: right;
    }
`;

const ErrorMessage = styled.div`
    color: ${COLOR_IMPORTANT};
`;

const bonusLink =
    config.env === 'prod'
        ? 'https://extra.eezy.fi/register?bonusCode='
        : 'https://eezy.test.aavaohjelmistot.fi/register?bonusCode=';
interface IProps {
    deleteAttachment: (fileId: number) => void;
    deleteLoading: boolean;
    fileLoading: boolean;
    profileLoading: boolean;
    uploadLogo: (data: FormData) => void;
    userData?: IUserBasicData;
}

const ProfileMarketing: FC<IProps> = ({
    deleteAttachment,
    deleteLoading,
    fileLoading,
    profileLoading,
    uploadLogo,
    userData,
}) => {
    const { t } = useTranslation();
    const { data: user, refetch } = useUserQuery();

    const client = useApolloClient();

    const [companyName, setCompanyName] = useState(''); // Declare state inside the component
    const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);

    const [closeF, setCloseF] = useState<any>(null);
    const [firstNameForInvoice, setFirstNameForInvoice] = useState(WHOLE_NAME);

    const firstName = firstNameForInvoice === WHOLE_NAME ? user?.firstName : firstNameForInvoice;

    const nameOnInvoice = () => `${companyName && companyName + ' / '}` + firstName + ' ' + user?.lastName;

    const handleCompanyNameChange = (val: string) => {
        setCompanyName(val.slice(0, 35)); // Limit the input to 35 characters
        setIsCompanyNameValid(val.length !== 1);
    };

    const getReferralLink = (recruitmentCode: string): string => {
        return `${bonusLink}${recruitmentCode}`;
    };

    // Local version of update is needed as we want to close the card only if the mutation
    // is completed, and there are currently no other ways to call onCompleted
    const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            client.refetchQueries({ include: [GET_USER_DATA] });
            refetch();
            if (closeF) {
                closeF();
            }
            toast(t('general.saved'));
            handleReset();
        },
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const handleSave = (callback: () => void) => {
        if (!isCompanyNameValid) return;
        // callback-fn (that closes the card) is available here but needs to be called in
        // onCompleted of the mutation, thus the hack
        setCloseF(() => () => callback());

        updateUserData({
            variables: {
                companyName,
                firstNameForInvoice: firstName,
            },
        });
    };

    const handleReset = useCallback(() => {
        setCompanyName(userData?.companyName || '');
        if (userData?.firstNameForInvoice === user?.firstName) {
            setFirstNameForInvoice(WHOLE_NAME);
        } else {
            setFirstNameForInvoice(userData?.firstNameForInvoice || WHOLE_NAME);
        }
    }, [user, userData]);

    useEffect(() => {
        handleReset();
    }, [userData, handleReset]);

    const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const newFile = e.target.files[0];
            const uploadData = new FormData();
            uploadData.append('file', newFile);
            uploadLogo(uploadData);
        }
    };

    const onCopy = () => {
        toast(t('form.copied'));
    };

    const CopyButton = (
        <EezyButton color="purple" style={{}} type="button" width={120}>
            {t('profile:marketing.recruitmentCode.copyLink')}
        </EezyButton>
    );

    const imageId = userData?.pendingInvoiceLogoId || userData?.invoiceLogoId;

    const actualLengthWithoutSpaces = companyName.replace(/\s+/g, '').length;

    return (
        <ManualCard
            title={t('profile:marketing.title')}
            editableContent={
                <Flex column>
                    <FormInput
                        data-mf-replace="**REMOVED**"
                        label={t('profile:marketing.recruitmentCode.label') || ''}
                        helpTexts={['profile:marketing.recruitmentCode.help']}
                        name="recruitmentCode"
                        readonly
                        value={userData?.recruitmentCode}
                    />

                    {getNameOptions(user?.firstName ?? '').length > 2 && (
                        <FormRadio
                            label={t('profile:marketing.name.label') || ''}
                            onTwoLines
                            options={getNameOptions(user?.firstName ?? '')}
                            name="firstNameForInvoice"
                            onChange={(val) => setFirstNameForInvoice(val)}
                            value={firstNameForInvoice}
                        />
                    )}

                    <FormInput
                        label={t('profile:marketing.marketingName.label') || ''}
                        helpTexts={[
                            'profile:marketing.marketingName.help1',
                            'profile:marketing.marketingName.help2',
                        ]}
                        onChange={handleCompanyNameChange}
                        name="companyName"
                        required
                        value={companyName}
                        maxLength={35}
                    />

                    {actualLengthWithoutSpaces < 2 && companyName.length > 0 && (
                        <ErrorMessage>{t('profile:marketing.marketingName.tooShort')}</ErrorMessage>
                    )}

                    {companyName.length === 35 && (
                        <ErrorMessage>{t('profile:marketing.marketingName.tooLong')}</ErrorMessage>
                    )}

                    <FormRow>
                        <Flex spread>
                            <FormLabel style={{ lineHeight: '30px' }}>
                                {t('profile:marketing.logo.title')}
                            </FormLabel>
                            {!imageId && (
                                <FileInput
                                    accept={COST_RECEIPT_FILETYPES}
                                    onChange={handleAddFile}
                                    style={{
                                        borderRadius: 100,
                                    }}
                                >
                                    {fileLoading || profileLoading ? (
                                        <LoadingSpinner color={COLOR_BLUM} size="1em" />
                                    ) : (
                                        <span aria-hidden>{t('file.upload')}</span>
                                    )}
                                </FileInput>
                            )}
                        </Flex>
                        <BodyP style={{ marginTop: 20 }}>{t('profile:marketing.logo.description')}</BodyP>
                        {imageId && (
                            <Flex spread>
                                <ImageWrapper>
                                    <img
                                        aria-label={'logo'}
                                        src={`${config.backendUrl3}/logo/${imageId}/picture.png`}
                                        alt="logo"
                                        style={{
                                            height: '102px',
                                            marginRight: 10,
                                            width: '102px',
                                        }}
                                    />
                                    {imageId !== userData?.invoiceLogoId && (
                                        <>
                                            <br />
                                            <span
                                                style={{
                                                    color: COLOR_DARK_GRAY,
                                                    fontSize: 12,
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                {t('invoice.statuses.unaccepted')}
                                            </span>
                                        </>
                                    )}
                                </ImageWrapper>
                                <EezyButton
                                    color="purple"
                                    disabled={deleteLoading || fileLoading || profileLoading}
                                    onClick={() => deleteAttachment(imageId)}
                                >
                                    {deleteLoading || profileLoading ? (
                                        <LoadingSpinner size="1em" color={COLOR_BLUM} />
                                    ) : (
                                        <>{t('form.delete')}</>
                                    )}
                                </EezyButton>
                            </Flex>
                        )}
                    </FormRow>

                    <FormRow>
                        <FormLabel style={{ lineHeight: '30px' }}>
                            {t('profile:marketing.example.title')}
                        </FormLabel>
                        <ExampleImage>
                            <Flex column>
                                <FormLabel>{t('invoice.recipient')}</FormLabel>

                                <PSmall color={COLOR_DARK_GRAY}>
                                    {t('profile:marketing.example.company')}
                                </PSmall>
                                <PSmall color={COLOR_DARK_GRAY}>
                                    {t('profile:marketing.example.address1')}
                                </PSmall>
                                <PSmall color={COLOR_DARK_GRAY}>
                                    {t('profile:marketing.example.address2')}
                                </PSmall>
                            </Flex>
                            <Flex style={{ marginTop: 30 }}>
                                {imageId && (
                                    <ImageWrapper>
                                        <img
                                            aria-label={'logo'}
                                            alt={'logo'}
                                            src={`${config.backendUrl3}/logo/${imageId}/picture.png`}
                                            style={{
                                                height: '102px',
                                                marginRight: 10,
                                                width: '102px',
                                            }}
                                        />
                                    </ImageWrapper>
                                )}
                                <Flex column>
                                    <FormLabel>{t('profile:marketing.example.contractor')}</FormLabel>
                                    <PSmall data-mf-replace="**REMOVED**" color={COLOR_DARK_GRAY}>
                                        {nameOnInvoice()}
                                    </PSmall>
                                </Flex>
                            </Flex>
                        </ExampleImage>
                    </FormRow>
                </Flex>
            }
            onClose={handleReset}
            onSave={handleSave}
            saveLoading={loading}
            viewContent={
                <MarketingWrapper>
                    {imageId && (
                        <ImageWrapper>
                            <img
                                aria-label={'logo'}
                                alt={'logo'}
                                src={`${config.backendUrl3}/logo/${imageId}/picture.png`}
                                style={{
                                    height: '102px',
                                    marginRight: 10,
                                    width: '102px',
                                }}
                            />
                        </ImageWrapper>
                    )}
                    <Flex column style={{ flexGrow: 1 }}>
                        <MarketingCopyLink>
                            <div style={{ paddingRight: 15 }}>
                                <BodyP>
                                    <b>
                                        {t('profile:marketing.recruitmentCode.label')}:
                                        <InfoTooltip
                                            title={t('profile:marketing.recruitmentCode.tooltip')}
                                            placement="top"
                                            enterTouchDelay={0}
                                            arrow
                                        >
                                            <IconWrapper>
                                                <Icon
                                                    icon={['fas', 'info-circle']}
                                                    color={COLOR_BLUM}
                                                    style={{ width: '15px' }}
                                                />
                                            </IconWrapper>
                                        </InfoTooltip>
                                    </b>
                                </BodyP>
                                <BodyP>
                                    {t('profile:marketing.recruitmentCode.yourCode')}:{' '}
                                    <span data-mf-replace="**REMOVED**">{userData?.recruitmentCode}</span>
                                </BodyP>
                            </div>
                            <CopyButtonWrapper style={{ flexGrow: 1 }}>
                                {userData?.recruitmentCode && (
                                    <CopyToClipboard
                                        onCopy={onCopy}
                                        text={getReferralLink(userData?.recruitmentCode)}
                                    >
                                        {CopyButton}
                                    </CopyToClipboard>
                                )}
                            </CopyButtonWrapper>
                        </MarketingCopyLink>

                        <BodyP style={{ marginTop: 10 }}>
                            <b>{t('profile:marketing.name-on-the-invoice')}:</b>
                        </BodyP>
                        <BodyP data-mf-replace="**REMOVED**">{nameOnInvoice()}</BodyP>

                        {!imageId && (
                            <>
                                <BodyP style={{ marginTop: 10 }}>
                                    <b>{t('profile:marketing.logo.nologo-title')}:</b>
                                </BodyP>
                                <BodyP>{t('profile:marketing.logo.nologo-description')}</BodyP>
                            </>
                        )}
                    </Flex>
                </MarketingWrapper>
            }
        />
    );
};

export default ProfileMarketing;
