import { useEffect } from 'react';
import queryString from 'query-string';
import { IRootState } from 'reducers';
import { Cookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { usePrevious } from 'utils/hooks';
import ConfirmAction from './ConfirmAction';
import WithholdingTax from './withholding-tax/WithholdingTax';
import EezyPayUserLoader from 'containers/eezypay/EezyPayUserLoader';
import GlobalStyles from '../styles/global';
import ErrorView from 'containers/error/Error';
import PageTitle from 'components/routes/PageTitle';
import AlertAction from './AlertAction';
import { useUserQuery } from 'queries/useUserQuery';
import { changeLanguage } from 'utils';

const Root = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data: userData, refetch } = useUserQuery();

    const error = useSelector((state: IRootState) => state.auth.error);
    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    const handleLogoutEvent = (event: any) => {
        if (event.key === 'logout-event') {
            navigate('/logout');
        }
    };

    useEffect(() => {
        window.addEventListener('storage', handleLogoutEvent);
        // UUID cookie
        const cookie = new Cookies();
        const uuid = cookie.get('uuid');
        if (!uuid) {
            cookie.set('uuid', uuidv4(), {
                expires: new Date(2050, 1, 1),
                path: '/',
                secure: true,
            });
        }

        return () => {
            window.removeEventListener('storage', handleLogoutEvent);
        };
    }, []);

    const prevIsLoggedIn = usePrevious(isLoggedIn);

    useEffect(() => {
        //  Redirect the user to the next-url after login
        const isLoggingIn = isLoggedIn && prevIsLoggedIn === false;
        if (isLoggingIn) {
            const urlParams: any = queryString.parse(location.search);
            refetch();
            if (urlParams && urlParams.next) {
                navigate(urlParams.next);
            } else {
                navigate('/');
            }
        }
    }, [prevIsLoggedIn, isLoggedIn]);

    useEffect(() => {
        if (!userData) return;

        changeLanguage(userData.language);
    }, [userData]);

    return (
        <>
            <ErrorView wholePage />
            <GlobalStyles />
            <PageTitle />
            {error && error.place === 'wholePage' ? null : (
                <>
                    <WithholdingTax />
                    <EezyPayUserLoader />
                    <Outlet />
                    <AlertAction />
                    <ConfirmAction />
                </>
            )}
        </>
    );
};

export default Root;
