import { useLazyQuery } from '@apollo/client';
import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';
import { IClient, IClientChoiceInput, IYtjBriefCompany } from '../../../../../shared/src/types/invoice';
import { FormInput, FormRow } from '../../../components/form';
import { FormLabel } from '../../../components/form/FormLabel';
import { COLOR_GREY_FOG, COLOR_GREYHOUND } from '../../../styles/variables';
import { trans } from '../../../utils';
import GET_YTJ_COMPANIES from '../queries/getYtjCompanies';

interface ICompanyNameProps {
    disableRecipientChange?: boolean;
    errors: any;
    formData: IClient;
    handleChange: (val: string, name: string) => void;
    handleRecipientChange: (recipient: IClientChoiceInput) => void;
    handleYtjRecipientChange: (r: IYtjBriefCompany) => void;
    invoiceId?: number;
    readOnly?: boolean;
    recipientId?: string;
    required?: boolean;
    showErrors?: boolean;
}

const useStyles = makeStyles()({
    input: {
        borderBottom: `1px solid ${COLOR_GREY_FOG}`,
        '& fieldSet': {
            border: 'none',
        },
    },
});

const NameField = styled.div`
    font-weight: 600;
    width: 100%;
`;

const BusinessIdField = styled.div`
    color: ${COLOR_GREYHOUND};
    width: 100%;
`;

const CompanyNameField = (props: ICompanyNameProps) => {
    const [options, setOptions] = useState<IYtjBriefCompany[]>([]);
    const { classes } = useStyles();

    const [ytjSearch, { data: ytjData, loading }] = useLazyQuery(GET_YTJ_COMPANIES);

    const changeQuery = (q: string) => {
        props.handleChange(q, 'name');
        if (q.length > 2) {
            ytjSearch({ variables: { query: q } });
        }
    };

    useEffect(() => {
        if (ytjData?.ytjSearch?.items) {
            setOptions(ytjData?.ytjSearch?.items);
        }
    }, [ytjData]);

    if (props.disableRecipientChange) {
        return (
            <FormInput
                error={props.errors?.name}
                label={trans(`invoice.form.recipient.company`)}
                name="name"
                onChange={props.handleChange}
                readonly={props.readOnly}
                required
                value={props.formData.name}
            />
        );
    }

    return (
        <FormRow>
            <FormLabel error={props.errors} value={props.recipientId || props.formData.name}>
                {trans(`invoice.form.recipient.company`)}
            </FormLabel>
            <Autocomplete
                id={'company-name-autocomplete'}
                freeSolo
                inputValue={props.formData?.name || ''}
                options={options}
                onChange={(_e, value) => value && props.handleYtjRecipientChange(value as IYtjBriefCompany)}
                onInputChange={(e, val) => {
                    changeQuery(val);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={classes.input}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props: any, option: any) => {
                    return (
                        <div {...props} style={{ display: 'block' }}>
                            <NameField>{option.name}</NameField>
                            <BusinessIdField>{option.businessId}</BusinessIdField>
                        </div>
                    );
                }}
            />
        </FormRow>
    );
};

export default CompanyNameField;
