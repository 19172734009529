import { UiLanguage } from '../../../../shared/src/types/user';
import { IDropdownOption } from '../../components/form/AutocompleteDropdown';
import { IAddress } from './../../../../shared/src/types/address';
import i18n, { DEFAULT_LANGUAGE } from './../i18n';

export enum WarningType {
    Travel = 'travel',
    ExpenseReimbursement = 'expenseReimbursement',
}

export interface IDocument {
    document: string;
    documentId: number;
}

export const getDocumentId = (documents: IDocument[], type: string) => {
    return documents && documents.find((d: IDocument) => d.document === type)?.documentId;
};

export const communicationsLanguegOptions = (): IDropdownOption[] => {
    return [
        { label: 'languages.fi', value: 'fi' },
        { label: 'languages.en', value: 'en' },
    ];
};

export const referralOptions = [
    { label: 'login.referral-option-search', value: 'search' },
    {
        label: 'login.referral-option-recommendation',
        value: 'recommendation',
    },
    { label: 'login.referral-option-facebook', value: 'facebook' },
    { label: 'login.referral-option-instagram', value: 'instagram' },
    { label: 'login.referral-option-twitch', value: 'twitch' },
    { label: 'login.referral-option-digital-ad', value: 'digital-ad' },
    { label: 'login.referral-option-outside-ad', value: 'outside-ad' },
    { label: 'login.referral-option-radio', value: 'radio' },
    { label: 'login.referral-option-event', value: 'event' },
    { label: 'login.referral-option-campwire', value: 'campwire' },
    { label: 'login.referral-option-other', value: 'other' },
];

export const setLocalStorage = (name: string, data: object) => {
    if (localStorage) {
        localStorage.setItem(name, JSON.stringify({ time: Date.now(), ...data }));
    }
};

export const getLocalStorage = (name: string) => {
    if (localStorage) {
        const data = localStorage.getItem(name);
        if (data) {
            const { time, ...restData } = JSON.parse(data);
            const minutesSince = (Date.now() - time) / 1000 / 60;
            return { minutesSince, ...restData };
        }
    }
    return null;
};

export const dismissWarning = (warningType: string, id: number): void => {
    const noWarningFromLocalStorage = getLocalStorage('dismiss-warning');
    let idByWarningType = noWarningFromLocalStorage?.idByWarningType;
    if (idByWarningType) {
        const index = idByWarningType.findIndex((warning: any) => warning[warningType] === id);
        if (index === -1) {
            idByWarningType.push({ [warningType]: id });
        }
    } else {
        idByWarningType = [{ [warningType]: id }];
    }
    setLocalStorage('dismiss-warning', { idByWarningType });
};

export const isWarningHidden = (warningType: string, id: number | undefined): boolean => {
    const noWarningFromLocalStorage = getLocalStorage('dismiss-warning');
    const idByWarningType = noWarningFromLocalStorage?.idByWarningType;
    if (idByWarningType) {
        const index = idByWarningType.findIndex((warning: any) => warning[warningType] === id);
        if (index !== -1) {
            return true;
        }
    }
    return false;
};

export const saveResetEmail = (email: string) => {
    setLocalStorage('reset-email', { email });
};

export const saveBonusCode = (bonusCode: string) => {
    setLocalStorage('bonusCode', { bonusCode });
};

export const getI18NextLang = (): UiLanguage => {
    const lang = i18n.language;
    return (lang ? lang.split('-', 1)[0] : DEFAULT_LANGUAGE) as UiLanguage;
};

export const errorReason = (reason?: string) => {
    switch (reason) {
        case 'already_identified':
        case 'under_age':
            return reason;
        case 'failed_to_save':
        default:
            return 'aborted';
    }
};

export const formatShortAddress = (address?: IAddress) => {
    if (!address) {
        return '';
    }
    const { street1, street2, town } = address;
    return `${street1} ${street2}, ${town}`;
};
