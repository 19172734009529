import { gql } from '@apollo/client';

const GET_USER_DATA = gql`
    query GetUserData {
        userData {
            address {
                country
                street1
                zipCode
                town
            }
            homeCountry
            bankAccountNumber
            birthday
            companyName
            email
            finnishCitizen
            firstNameForInvoice
            invoiceLogoId
            id
            nextTaxCardStartDate
            notifyWhenInvoicePaid
            longTermStorageConsent
            pendingInvoiceLogoId
            phoneCountryCode
            phone
            recruitmentCode
            salaryPaymentRuleId
            servicePercentage
            taxCardDetails {
                addPercentage
                basicPercentage
                basicPercentageLimit
                endDate
                startDate
            }
            taxCardUploadedAt
            yelInsured
            cumulativeInvoicing
        }
    }
`;

export default GET_USER_DATA;
