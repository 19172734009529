import { CSSProperties, FormEvent, KeyboardEvent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IInvoiceItemKeys } from '../../../../shared/src/types/invoice';
import { Flex } from 'components/Flex';
import { TextArea } from 'components/form';
import { AutocompleteDropdown, IDropdownOption } from 'components/form/AutocompleteDropdown';
import { Icon } from 'components/Icon';
import { WarningTooltip } from 'components/ToolTip';
import {
    BORDER_REQUIRED_FIELD,
    COLOR_DARK_GRAY,
    COLOR_GREYJOY,
    COLOR_GREYS_ANATOMY,
    COLOR_IMPORTANT,
    COLOR_STATUS_WAITING,
    FOCUS_COLOR,
} from 'styles/variables';
import {
    formatCents,
    formatCentsWithoutRounding,
    formatFloat,
    strToCentsOrZero,
    strToCentsOrZeroWithoutRounding,
} from 'utils';
import { commaToPeriod, isValidNumber, isValidNumberNeg, NUMBER_REGEX, NUMBER_REGEX_NEG } from 'utils/calc';
import { addLeadingZero, convertStrToFloat, strToFloatOrZero } from 'utils/str';

const StyledTableDataInput = styled.input`
    && {
        background-color: ${COLOR_GREYS_ANATOMY};
        border-bottom: ${BORDER_REQUIRED_FIELD};
        color: ${COLOR_DARK_GRAY};
        height: auto;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;

        &::placeholder {
            color: ${COLOR_GREYJOY};
        }

        &.hasError,
        &.hasError::placeholder {
            color: ${COLOR_IMPORTANT};
        }

        &:focus {
            border-color: ${FOCUS_COLOR} !important;
        }

        &:focus ~ span {
            border-color: ${FOCUS_COLOR} !important;
        }

        &.hasWarning,
        &.hasWarning ~ span.endAdornment {
            color: ${COLOR_STATUS_WAITING};
        }

        & ~ span {
            position: absolute;
            right: 4px;
        }
    }
`;

export type DataInputType = 'text' | 'number';

interface IInvoiceTableDataInputProps {
    allowNegative?: boolean;
    autoFocus?: boolean;
    endAdornment?: string;
    handleFocus?: () => void;
    handleUpdate: (changedProperty: IInvoiceItemKeys) => void;
    disableRounding?: boolean;
    error?: any;
    forceUpdateOnFocus?: boolean;
    isEur?: boolean;
    leadingZero?: boolean;
    name: string;
    options?: IDropdownOption[];
    placeholder?: string;
    style?: CSSProperties;
    type: DataInputType;
    value?: string | number;
    warning?: string | ReactElement;
}

export const formatVisibleValue = (props: IInvoiceTableDataInputProps) => {
    if (props.isEur) {
        if (props.disableRounding) return formatCentsWithoutRounding(props.value as number);
        return formatCents(props.value as number, true);
    }

    if (props.value === undefined) {
        return props.type === 'number' ? undefined : '';
    }

    if (props.type === 'number') {
        if (typeof props.value === 'number') return formatFloat(props.value);
        if (typeof props.value === 'string') return formatFloat(strToFloatOrZero(props.value));
    }

    return props.value;
};

export const formatValue = (props: IInvoiceTableDataInputProps, value: string) => {
    if (props.isEur) {
        if (props.disableRounding) return strToCentsOrZeroWithoutRounding(value);
        return strToCentsOrZero(value);
    }

    if (props.type === 'number') {
        return strToFloatOrZero(value);
    }

    return value;
};

const InvoiceTableDataInput = (props: IInvoiceTableDataInputProps) => {
    const [valueChanged, setValueChanged] = useState(false);
    const [value, setValue] = useState<string | number | undefined>(() => formatVisibleValue(props));
    const [focused, setFocused] = useState(false);

    const changeValue = (val: string | number) => {
        setValue(val);
        setValueChanged(true);
    };

    useEffect(() => {
        const newValue = formatVisibleValue(props);
        if (newValue && newValue !== value && (!focused || props.forceUpdateOnFocus)) {
            setValue(newValue);
        }
    }, [props]);

    const update = (val: string | number) => {
        props.handleUpdate({ [props.name]: val });
    };

    const handleNumberInputLeave = (e: FormEvent<HTMLInputElement>) => {
        setFocused(false);
        if (valueChanged) {
            const val = formatValue(props, commaToPeriod(e.currentTarget.value));
            update(val);
            setValue(formatVisibleValue(props));
            setValueChanged(false);
        }
    };

    const handleNumberChange = (e: FormEvent<HTMLInputElement>) => {
        if (
            (props.allowNegative && !isValidNumberNeg(e.currentTarget.value)) ||
            (!props.allowNegative && !isValidNumber(e.currentTarget.value))
        ) {
            return false;
        }
        changeValue(e.currentTarget.value);
    };

    const handleNumberKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        // mimic native number input
        const code = e.keyCode || e.which;
        if (code === 38) {
            // key up
            if (typeof value === 'string') {
                changeValue(convertStrToFloat(commaToPeriod(value)) + 1);
            } else if (value) {
                changeValue(value + 1);
            } else {
                changeValue(1);
            }
        } else if (code === 40) {
            // key down
            if (typeof value === 'string') {
                changeValue(convertStrToFloat(commaToPeriod(value)) - 1);
            } else if (value) {
                changeValue(value - 1);
            } else {
                changeValue(-1);
            }
        }
    };

    const handleTextareaLeave = (e: FormEvent<HTMLTextAreaElement>) => {
        if (valueChanged) {
            const val = formatValue(props, e.currentTarget.value);
            update(val);
            setValue(val);
            setValueChanged(false);
        }
    };

    const handleTextareaChange = (e: FormEvent<HTMLTextAreaElement>) => {
        changeValue(e.currentTarget.value);
    };

    const formatLeadingZero = (val: number | string) => {
        if (val === 0 || val === '0' || val === '') {
            return '00';
        }
        if (typeof val === 'number') {
            return addLeadingZero(val.toString());
        } else {
            return addLeadingZero(val);
        }
    };

    const className =
        (props.error ? 'hasError' : '') +
        (props.warning ? 'hasWarning' : '') +
        (props.endAdornment ? ' endAdornment' : '');

    if (props.options) {
        return (
            <AutocompleteDropdown
                endAdornment={props.endAdornment}
                error={props.error}
                name={props.name}
                options={props.options || []}
                onChange={(val: string | number) => {
                    setValue(val);
                    update(val);
                }}
                onFocus={props.handleFocus}
                style={props.style}
                type={props.type || 'text'}
                value={value ? value.toString() : ''}
            />
        );
    } else if (props.type === 'text') {
        return (
            <TextArea
                autoComplete="off"
                autoFocus={props.autoFocus}
                aria-invalid={!!props.error}
                aria-labelledby={props.name}
                className={className}
                maxLength={255}
                onBlur={handleTextareaLeave}
                onChange={handleTextareaChange}
                onFocus={props.handleFocus}
                placeholder={props.placeholder}
                style={{ ...props.style }}
                value={value || ''}
                $variant="plain"
            />
        );
    } else if (props.type === 'number') {
        const input = (
            <StyledTableDataInput
                autoComplete="off"
                autoFocus={props.autoFocus}
                aria-invalid={!!props.error}
                aria-labelledby={props.name}
                className={className}
                data-lpignore={true}
                onBlur={handleNumberInputLeave}
                onChange={handleNumberChange}
                onClick={(e) => {
                    e.currentTarget.select();
                }}
                onFocus={() => {
                    setFocused(true);
                    if (props.handleFocus) {
                        props.handleFocus();
                    }
                }}
                onKeyUp={handleNumberKeyPress}
                maxLength={255}
                pattern={props.allowNegative ? NUMBER_REGEX_NEG.source : NUMBER_REGEX.source}
                placeholder={props.placeholder || ''}
                step="any"
                style={{ ...props.style }}
                type={'text'}
                value={props.leadingZero && value ? formatLeadingZero(value) : value}
            />
        );
        return (
            <WarningTooltip title={props.warning || ''} arrow placement="bottom">
                <Flex center fullWidth style={{ position: 'relative' }}>
                    {props.warning && (
                        <Icon
                            icon={['far', 'exclamation-circle']}
                            className="small"
                            color={COLOR_STATUS_WAITING}
                        />
                    )}
                    {input}
                    {props.endAdornment && (
                        <span
                            className="endAdornment"
                            style={{
                                ...props.style,
                                width: 'auto',
                            }}
                        >
                            {props.endAdornment}
                        </span>
                    )}
                </Flex>
            </WarningTooltip>
        );
    } else {
        return null;
    }
};

export default InvoiceTableDataInput;
