import moment from 'moment';

export const isWeekday = (date: string, weekday: number) => {
    return moment(date).day() === weekday;
};

export const isWeekend = (date?: string) => {
    if (!date) {
        date = moment().toISOString();
    }
    return isWeekday(date, 6) || isWeekday(date, 0);
};
