import { validator as v } from '../validation';
import { EMAIL_REGEX } from './../validation';
import { addressValidator } from './addressValidator';

const emailValidator = v.string().regex(EMAIL_REGEX);

export const bankAccountValidator = v.string().regex(/^FI[0-9]{16}$/);

export const contactValidator = v.object({
    address: addressValidator,
    email: emailValidator,
    email1: emailValidator.allow(''),
    email2: v.valid(v.ref('email1')),
    finnishCitizen: v.bool(),
    homeCountry: v.string(),
    phone: v.string(),
    phoneCountryCode: v.number(),
});
