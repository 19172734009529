import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IRootState } from 'reducers';
import { IWithholdingTax } from '../../../../shared/src/types/user';
import { Toast } from 'components/Toast';
import { isMobile } from 'utils';
import GET_WITHHOLDING_TAX from 'containers/profile/queries/getWithholdingTax';
import { useUser } from 'queries/useUserQuery';
import { useQuery } from '@apollo/client';
import WithholdingTaxToast from './WithholdingTaxToast';

const timeoutToAvoidFlickering = 2000;
const WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID = 'withholding-tax-status-toast';

const WithholdingTax = () => {
    const { t } = useTranslation();
    const user = useUser();

    const isLoggedIn: boolean | null = useSelector((state: IRootState) => state.auth.loggedIn);

    useQuery<{ userWithholdingTax: IWithholdingTax }>(GET_WITHHOLDING_TAX, {
        skip: !isLoggedIn || !user?.isIdentified,
        onCompleted: (data) => {
            const { status, addPercentage, basicPercentageLimit, basicPercentage, text, startDate, error } =
                data.userWithholdingTax;

            if (!status || text === 'no_change' || error?.reason === 'request_recently_sent') return;

            const toastProps = {
                taxApiMessage: '',
                isDataError: false,
                isTechError: false,
            };

            if (
                status === 'ok' &&
                (addPercentage || basicPercentageLimit || basicPercentage || startDate) &&
                text !== 'no_change'
            ) {
                toastProps.taxApiMessage = t('withholdingTax.successMessage', {
                    percentage: basicPercentage,
                });
                toastProps.isTechError = false;
                toastProps.isDataError = false;
            } else if (
                status === 'error' &&
                (error?.reason === 'tax_card_save_failed' ||
                    error?.reason === 'api_call_failed' ||
                    error?.reason === 'bad_request' ||
                    error?.reason === 'request_recently_sent')
            ) {
                toastProps.taxApiMessage = t('withholdingTax.technicalErrorMessage', {
                    percentage: basicPercentage,
                });
                toastProps.isTechError = true;
            } else if (status === 'error' && error?.reason === 'no_data') {
                toastProps.taxApiMessage = t('withholdingTax.noDataErrorMessage', {
                    percentage: basicPercentage,
                });
                toastProps.isDataError = true;
            } else {
                return;
            }

            setTimeout(() => {
                toast(<WithholdingTaxToast {...toastProps} />, {
                    autoClose: toastProps.isTechError ? 6000 : false,
                    closeOnClick: true,
                    containerId: WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID,
                    position: isMobile() ? 'bottom-center' : 'top-center',
                });
            }, timeoutToAvoidFlickering);
        },
    });

    return (
        <>
            <Toast
                style={{ width: isMobile() ? '96%' : 641, margin: isMobile() ? '0 2%' : '' }}
                containerId={WITHHOLDING_TAX_STATUS_TOAST_CONTAINER_ID}
                isLarge
                position={isMobile() ? 'bottom-center' : 'top-center'}
                hideIcon
            />
        </>
    );
};

export default WithholdingTax;
