import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IStrongAuthData, strongAuth } from '../../actions/auth';
import { EezyButton } from '../../components/Buttons';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { P } from '../../components/textElements';
import { COLOR_GREYHOUND, COLOR_BLUM } from '../../styles/variables';
import { Icon } from '../../components/Icon';

const Li = styled.li`
    color: ${COLOR_GREYHOUND};
    margin-top: 15px;
`;

const List = styled.ul`
    list-style: disc;
    margin-left: 15px;
`;

interface IModalProps {
    answered?: boolean;
    handleModalClose: () => void;
    isOpen: boolean;
    strongAuth: (data: IStrongAuthData) => void;
}

const AuthModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();

    const handleConfirm = () => {
        props.strongAuth({ action: 'profile', lang: i18n.language ?? 'en' });
    };

    const velloLink =
        i18n.language === 'fi'
            ? 'https://vello.fi/eezykevytyrittajat/'
            : 'https://vello.fi/eezykevytyrittajat/call';

    return (
        <Modal
            id="modal-strong-auth"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('dashboard.messages.auth.title')}
        >
            <ModalContent>
                <P color="black">{t('dashboard.messages.auth.1')}</P>
                <P color="black">{t('dashboard.messages.auth.2')}</P>
                <List>
                    <Li>
                        <P color="black">
                            {t('dashboard.messages.auth.3')}{' '}
                            <a href={velloLink} target="_blank" rel="noopener noreferrer">
                                {velloLink}
                                <Icon
                                    className="small"
                                    color={COLOR_BLUM}
                                    icon={['far', 'arrow-up-right-from-square']}
                                    style={{ marginLeft: 10, marginRight: 5 }}
                                />
                            </a>{' '}
                            {t('dashboard.messages.auth.or')}
                        </P>
                    </Li>
                    <Li>
                        <P color="black">
                            {t('dashboard.messages.auth.4')} <a href="tel:+358942475630">09 4247 5630</a>{' '}
                            {t('dashboard.messages.auth.or')}{' '}
                            <a href="mailto:info.kevytyrittajat@eezy.fi">info.kevytyrittajat@eezy.fi</a>.
                        </P>
                    </Li>
                </List>
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" dark onClick={props.handleModalClose}>
                    {t('menu.close')}
                </EezyButton>

                <EezyButton color="purple" dark onClick={handleConfirm}>
                    {t('dashboard.messages.auth.modal-button')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        strongAuth: (data: IStrongAuthData) => {
            dispatch(strongAuth(data));
        },
    };
};

export default connect(null, mapDispatchToProps)(AuthModal);
