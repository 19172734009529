import { useMemo } from 'react';
import { IInvoice, IProvision } from '../../../../../shared/src/types/invoice';
import { useUser } from 'queries/useUserQuery';

export const useInvoiceProvisions = (invoice: IInvoice | undefined) => {
    const user = useUser();
    return useMemo<IProvision[]>(() => {
        if (!invoice || !user) {
            return [];
        }

        if (!invoice.provisions?.length) {
            return [
                {
                    personId: user.id,
                    name: `${user.firstName} ${user.lastName}`,
                    occupationId: invoice.occupation,
                    percentage: 100,
                    fixedAmount: invoice.total,
                },
            ];
        }

        const provisions = invoice.provisions || [];
        const provisionContainsOwner = provisions.some((p) => p.personId === invoice.createdBy?.id);
        const rows = provisionContainsOwner
            ? provisions
            : [
                  {
                      name: invoice.createdBy?.name,
                      personId: invoice.createdBy?.id || 0,
                  },
                  ...provisions,
              ];

        return rows;
    }, [invoice, user]);
};
