import { ApolloError } from '@apollo/client';
import { GraphQLErrorExtensions } from 'graphql';

interface IGraphQLFilteredErrors {
    basicInfo?: any;
    costs?: any;
    error?: any;
    express?: any;
    mustAccept?: any;
    provisions?: any;
}

interface Extensions extends GraphQLErrorExtensions {
    code: string;
    errors: {
        basicInfo: unknown;
        costs: unknown;
        eezyExpress: unknown;
        mustAccept: unknown;
        provisions: unknown;
    };
    backendResponse: {
        messages: {
            key: string;
        }[];
    };
    response: {
        body: {
            messages: {
                key: string;
            }[];
        };
    };
}

export const getErrors = (e: ApolloError): IGraphQLFilteredErrors | undefined => {
    if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const extensions = e.graphQLErrors[0].extensions as Extensions;
        return {
            basicInfo: extensions?.errors?.basicInfo,
            costs: extensions?.errors?.costs,
            error: extensions?.code,
            express: extensions?.errors?.eezyExpress,
            mustAccept: extensions?.errors?.mustAccept,
            provisions: extensions?.errors?.provisions,
        };
    }
};

export const getErrorKey = (e: ApolloError): string | null => {
    if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        const extensions = e.graphQLErrors[0].extensions as Extensions;
        return (
            extensions?.backendResponse?.messages[0].key ||
            extensions?.response?.body?.messages[0]?.key ||
            null
        );
    }
    return null;
};
