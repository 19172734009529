import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IContactList, ICoworker } from '../../../../shared/src/types/user';
import { showModals } from '../../actions/auth';
import { setSelectedCoworker } from '../../actions/coworker';
import { EezyButton } from '../../components/Buttons';
import { AutoSaveCard } from '../../components/cards';
import { Flex } from '../../components/Flex';
import { P, RowTitle } from '../../components/textElements';
import { COLOR_BLUM, COLOR_GREYHOUND } from '../../styles/variables';
import { useTranslation } from 'react-i18next';

interface IGroupInvoicingProps {
    contacts: IContactList;
    showModals: (modals: string[]) => void;
    setSelectedCoworker: (coworker: ICoworker) => void;
}

const GroupInvoicing = (props: IGroupInvoicingProps) => {
    const { t } = useTranslation();
    return (
        <AutoSaveCard title={t('profile:group-invoicing.title')}>
            <P>{t('profile:group-invoicing.description')}</P>
            <Flex spread style={{ marginTop: 15 }}>
                <RowTitle color={COLOR_BLUM}>{t('profile:group-invoicing.invite-paragraph')}</RowTitle>
                <EezyButton color="purple" dark onClick={() => props.showModals(['INVITE_COWORKER'])}>
                    {t('profile:group-invoicing.invite-button')}
                </EezyButton>
            </Flex>
            <ContactsList
                type="admitted"
                contacts={props.contacts?.contacts}
                showModals={props.showModals}
                setSelectedCoworker={props.setSelectedCoworker}
            />
            <ContactsList
                type="pending"
                contacts={props.contacts?.pendingContacts}
                showModals={props.showModals}
                setSelectedCoworker={props.setSelectedCoworker}
            />
        </AutoSaveCard>
    );
};

interface IPendingContactsListProps {
    type: 'admitted' | 'pending';
    contacts: ICoworker[] | undefined;
    showModals: (modal: string[]) => void;
    setSelectedCoworker: (coworker: ICoworker) => void;
}

const ContactsList = (props: IPendingContactsListProps) => {
    const { t } = useTranslation();
    const handleClick = (coworker: ICoworker) => {
        props.setSelectedCoworker(coworker);
        props.showModals(['DELETE_COWORKER']);
    };

    const getColor = (type: typeof props.type) => {
        switch (type) {
            case 'admitted':
                return COLOR_BLUM;
            case 'pending':
                return COLOR_GREYHOUND;
        }
    };

    const contactList = (props.contacts || []).map((contact) => (
        <Flex key={contact.id} spread style={{ marginTop: 15 }}>
            <RowTitle data-mf-replace="**REMOVED**" color={getColor(props.type)}>
                {contact.name} {props.type === 'pending' && t('profile:group-invoicing.pending')}
            </RowTitle>
            <EezyButton color="purple" onClick={() => handleClick(contact)}>
                {t('form.delete')}
            </EezyButton>
        </Flex>
    ));
    return <Fragment>{contactList}</Fragment>;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        setSelectedCoworker: (coworker: ICoworker) => {
            dispatch(setSelectedCoworker(coworker));
        },
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(GroupInvoicing);
