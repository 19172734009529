import { useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { EezyButton } from 'components/Buttons';
import { Icon } from 'components/Icon';
import { Flex } from 'components/Flex';
import CardTitle from 'components/cards/CardTitle';
import GET_PAYMENTS_BY_DATE from 'containers/eezypay/queries/getPaymentsByDate';
import {
    currentMonth,
    currentYear,
    formatMoneyWithTwoDecimals,
    formatPercentage,
    priceWithoutVat,
} from 'utils';
import { BodyPSmall, Bold, LabelThin, RowTitle, Span } from 'components/textElements';
import { DashboardCard } from 'components/cards/DashboardCard';
import { isDesktopSize } from 'containers/invoice/fillHelpers/utils';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_DARK_GRAY,
    COLOR_GREYHOUND,
    COLOR_GREYJOY,
    COLOR_STATUS_DONE,
    COLOR_EEZY_BORDER_GRAY_200,
    COLOR_WHITE_WALKER,
    SCREEN_M,
    SCREEN_S,
} from 'styles/variables';
import TabHeaders from 'components/tabs/TabHeaders';
import SalesStatsCard from 'components/cards/SalesStatsCard';
import LoadingSpinner from 'components/Loading';
import { ITransaction } from 'containers/eezypay/Transactions';
import { LabelValueType } from 'containers/statistics/statisticsPageTypes';
import {
    briefInvoiceToStatisticInvoice,
    handleInvoicesForStatistics,
    invoiceDateFilter,
} from 'containers/statistics/statisticUtils';
import { GET_USER_DATA } from 'containers/profile/queries';
import { IUserBasicData } from '../../../../shared/src/types/user';
import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { GET_INVOICES } from './queries';
interface IServiceFeeProps {
    current: number;
    minLimit: number;
    maxLimit?: number;
    serviceFee: number;
}

enum SalesTabEnum {
    Laskutus,
    EezyPay,
}

const serviceFeeSteps = [
    {
        maxLimit: 50_000,
        minLimit: 0,
        serviceFee: 7,
    },
    {
        maxLimit: 150_000,
        minLimit: 50_000,
        serviceFee: 6,
    },
    {
        maxLimit: 300_000,
        minLimit: 150_000,
        serviceFee: 5.5,
    },
    {
        minLimit: 300_000,
        serviceFee: 5,
    },
];

const Ball = styled.div`
    width: 35px;
    height: 35px;
    background-color: ${COLOR_GREYJOY};
    font-size: 13px;
    font-weight: 600;
    color: ${COLOR_WHITE_WALKER};
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    &.active {
        width: 45px;
        height: 45px;
        line-height: 45px;
        background-color: ${COLOR_STATUS_DONE};
        color: ${COLOR_BLUM};
        margin-right: -8px;
    }
`;

const Connector = styled.div`
    width: 4px;
    background-color: ${COLOR_GREYJOY};
    position: absolute;
    z-index: 0;
    right: 30px;
    top: 30px;
    bottom: 30px;
`;

const ServiceFeeBox = styled(Flex)`
    padding: 10px 15px;
    z-index: 1;

    &.active {
        background-color: rgba(255, 255, 255, 0.8);
        border: 2px solid ${COLOR_STATUS_DONE};
        border-radius: ${BORDER_RADIUS};
    }
`;

const ServiceFeeBlock = (props: IServiceFeeProps) => {
    const { t } = useTranslation();
    const previous = props.maxLimit && props.current >= props.maxLimit;
    const active = props.current >= props.minLimit && (!props.maxLimit || props.current < props.maxLimit);
    const upcoming = props.current < props.minLimit;
    const classes = active ? 'active' : '';
    return (
        <ServiceFeeBox className={classes} spread>
            <Flex column>
                <LabelThin
                    color={upcoming ? COLOR_GREYHOUND : COLOR_GREYJOY}
                    style={{
                        marginTop: !active ? 8 : 0,
                        textDecoration: previous ? 'line-through' : 'none',
                    }}
                >
                    {!props.maxLimit
                        ? t('salaries.moreThan300000')
                        : props.maxLimit === 50_000
                        ? t('salaries.lessThan50000')
                        : props.maxLimit === 150_000
                        ? t('salaries.moreThan50000')
                        : t('salaries.moreThan150000')}
                </LabelThin>
                {active && (
                    <>
                        <div>
                            <Money id="income-year">{formatMoneyWithTwoDecimals(props?.current)}</Money>
                            <Unit>{t('form.eurs', { eurs: '' })}</Unit>
                        </div>
                        <LabelThin color={COLOR_DARK_GRAY}>{t('salaries.cumulativeInvoicing')}</LabelThin>
                    </>
                )}
            </Flex>
            <Flex column justifyCenter>
                <Ball className={classes}>
                    <span>{formatPercentage(props.serviceFee)}%</span>
                </Ball>
            </Flex>
        </ServiceFeeBox>
    );
};

const Money = styled(Bold)`
    color: ${COLOR_BLUM};
    font-size: 16px;
    line-height: 16px;
`;
const Unit = styled(Span)`
    color: ${COLOR_BLACKWATER};
    font-size: 16px;
    line-height: 16px;
`;
const SectionSeparator = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        width: 1px;
        background: #e3e3e3;
        margin: 0 32px;
    }
`;
const DashboardSalesBillingHistoryWrapper = styled.div`
    @media (max-width: ${SCREEN_M}px) {
        margin-top: 8px;
        border-radius: ${BORDER_RADIUS};
        border: 1px solid ${COLOR_EEZY_BORDER_GRAY_200};
        padding: 24px;
    }
`;

const DashboardSalesBillingHistory = () => {
    const { t } = useTranslation();

    const defaultServiceFee = serviceFeeSteps[0].serviceFee;

    const { data } = useQuery<{ userData: IUserBasicData }>(GET_USER_DATA, {
        variables: { year: currentYear(), month: currentMonth() },
    });

    const serviceFee = data?.userData?.servicePercentage
        ? parseFloat(data?.userData?.servicePercentage)
        : defaultServiceFee;
    const cumulativeInvoicing = data?.userData?.cumulativeInvoicing ?? 0;

    const useCustomPercentage = useMemo(() => {
        if (!data?.userData?.servicePercentage) return false;

        const currentStep = serviceFeeSteps.find(
            (s) => cumulativeInvoicing >= s.minLimit && (!s.maxLimit || cumulativeInvoicing < s.maxLimit)
        );
        return serviceFee < (currentStep?.serviceFee ?? 0);
    }, [data?.userData]);

    if (!data?.userData) {
        return (
            <div className="text-center">
                <LoadingSpinner color="inherit" />
            </div>
        );
    }

    return (
        <DashboardSalesBillingHistoryWrapper>
            <RowTitle className="hidden md:block">{t('salaries.cumulativeInvoicingTitle')}</RowTitle>
            <BodyPSmall>
                {useCustomPercentage && serviceFee
                    ? t('salaries.customServiceFee', {
                          percentage: formatPercentage(serviceFee),
                      })
                    : t('salaries.serviceFee')}
            </BodyPSmall>

            <Flex column style={{ marginTop: 10, position: 'relative' }}>
                {data.userData.cumulativeInvoicing !== null &&
                    data.userData.cumulativeInvoicing !== undefined && (
                        <>
                            {serviceFeeSteps.map((conf) => (
                                <ServiceFeeBlock
                                    key={conf.minLimit}
                                    minLimit={conf.minLimit}
                                    maxLimit={conf.maxLimit}
                                    current={data.userData.cumulativeInvoicing ?? defaultServiceFee}
                                    serviceFee={conf.serviceFee}
                                />
                            ))}
                            <Connector />
                        </>
                    )}
            </Flex>
        </DashboardSalesBillingHistoryWrapper>
    );
};

const SalesTabs: LabelValueType<SalesTabEnum>[] = [
    { label: 'statistic.tabs.invoices', value: SalesTabEnum.Laskutus },
    { label: 'statistic.tabs.eezy-page', value: SalesTabEnum.EezyPay },
];

const DashboardSalesCardContent = () => {
    const { t } = useTranslation();

    const [activeSalesTab, setActiveSalesTab] = useState<SalesTabEnum>(SalesTabEnum.Laskutus);
    const [visibleBillingHistory, setVisibleBillingHistory] = useState(isDesktopSize());

    const { data: allInvoicesData } = useQuery<{
        allInvoices: { items: IBriefInvoice[] };
    }>(GET_INVOICES);

    const { data: eezypayYearPayments } = useQuery(GET_PAYMENTS_BY_DATE, {
        context: { clientName: 'eezyPayHasura' },
        variables: {
            yearStartTimestamp: moment().startOf('year').toISOString(),
            yearEndTimestamp: moment().endOf('year').toISOString(),
        },
    });
    const { data: eezypayMonthPayments } = useQuery(GET_PAYMENTS_BY_DATE, {
        context: { clientName: 'eezyPayHasura' },
        variables: {
            yearStartTimestamp: moment().startOf('month').toISOString(),
            yearEndTimestamp: moment().endOf('month').toISOString(),
        },
    });

    const monthPaymentsTotal = useMemo(() => {
        if (activeSalesTab === SalesTabEnum.EezyPay) {
            return eezypayMonthPayments?.transactions?.reduce(
                (prev: number, curr: ITransaction) =>
                    prev + priceWithoutVat(curr.servicePrice, curr.serviceVat),
                0
            );
        }

        return handleInvoicesForStatistics(
            allInvoicesData?.allInvoices.items.map(briefInvoiceToStatisticInvoice) ?? []
        )
            .filter(
                invoiceDateFilter({
                    from: moment().startOf('month').toDate(),
                    to: moment().endOf('month').toDate(),
                })
            )
            .reduce((prev, curr) => prev + curr.total, 0);
    }, [allInvoicesData, eezypayMonthPayments, activeSalesTab]);

    const yearPaymentsTotal = useMemo(() => {
        if (activeSalesTab === SalesTabEnum.EezyPay) {
            return eezypayYearPayments?.transactions?.reduce(
                (prev: number, curr: ITransaction) =>
                    prev + priceWithoutVat(curr.servicePrice, curr.serviceVat),
                0
            );
        }
        return handleInvoicesForStatistics(
            allInvoicesData?.allInvoices.items.map(briefInvoiceToStatisticInvoice) ?? []
        )
            .filter(
                invoiceDateFilter({
                    from: moment().startOf('year').toDate(),
                    to: moment().endOf('year').toDate(),
                })
            )
            .reduce((prev, curr) => prev + curr.total, 0);
    }, [allInvoicesData, eezypayYearPayments, activeSalesTab]);

    const navigate = useNavigate();

    return (
        <div className="flex flex-col md:flex-row">
            <div className="md:flex-5">
                <div className="flex flex-col gap-9">
                    <TabHeaders
                        items={SalesTabs}
                        activeValue={activeSalesTab}
                        onChange={(v) => setActiveSalesTab(v)}
                    />
                    <div className="flex gap-2 md:gap-3 flex-wrap md:flex-nowrap">
                        <SalesStatsCard
                            amount={monthPaymentsTotal / 100}
                            description={t(
                                activeSalesTab === SalesTabEnum.EezyPay
                                    ? 'dashboard.sales.eezy-pay.paymentsThisMonth'
                                    : 'salaries.incomeMonth'
                            )}
                        />
                        <SalesStatsCard
                            amount={yearPaymentsTotal / 100}
                            description={t(
                                activeSalesTab === SalesTabEnum.EezyPay
                                    ? 'dashboard.sales.eezy-pay.paymentsThisYear'
                                    : 'salaries.incomeYear'
                            )}
                        />
                    </div>
                    <div>
                        <EezyButton
                            className="v2-btn min-w-full md:min-w-0"
                            dark
                            color="eezy-green"
                            hasIcon={true}
                            onClick={() => navigate('/statistics')}
                        >
                            <Icon icon={['fas', 'chart-mixed']} />
                            {t('dashboard.sales.navigate-statistics')}
                        </EezyButton>
                    </div>
                </div>
            </div>
            <SectionSeparator />
            <div className="md:flex-3">
                <EezyButton
                    className="v2-btn w-full border-0 mt-2 md:hidden"
                    transparent
                    color="purple"
                    hasIcon={true}
                    iconAlignment="right"
                    onClick={() => setVisibleBillingHistory(!visibleBillingHistory)}
                >
                    {t('dashboard.sales.billing-history')}
                    {visibleBillingHistory ? (
                        <Icon icon={['far', 'chevron-up']} />
                    ) : (
                        <Icon icon={['far', 'chevron-down']} />
                    )}
                </EezyButton>
                {visibleBillingHistory && <DashboardSalesBillingHistory />}
            </div>
        </div>
    );
};

const DashboardSalesCard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DashboardCard className="w-full">
            <div className="flex flex-col gap-7">
                <div className="flex justify-between">
                    <CardTitle
                        title={t('dashboard.sales.title')}
                        subtitle={t('dashboard.sales.subtitle')}
                        subIcon={<Icon className="small" icon={['far', 'info-circle']} />}
                    />
                    <EezyButton
                        className="v2-btn"
                        color="purple"
                        hasIcon
                        iconAlignment="right"
                        onClick={() => navigate('/salaries')}
                    >
                        {t('salaries.salaries')}
                        <Icon icon={['far', 'arrow-right']} />
                    </EezyButton>
                </div>
                <DashboardSalesCardContent />
            </div>
        </DashboardCard>
    );
};

export default DashboardSalesCard;
