import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TextField, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { makeStyles } from 'tss-react/mui';

import { IInvoice, IOccupation, IProvision } from '../../../../shared/src/types/invoice';
import { ICoworker } from '../../../../shared/src/types/user';
import { ADD_PROVISIONS, GET_OCCUPATIONS } from '../../containers/invoice/queries';
import { GET_COWORKERS } from '../../containers/profile/queries';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_GRAYS_ANATOMY,
    COLOR_GREY_FOG,
    COLOR_IMPORTANT,
} from 'styles/variables';
import { centsToEur, eursToCents, formatPercentage } from 'utils';
import { getErrorKey, getErrors } from 'utils/apolloErrors';
import { sortObjectsByLabel } from 'utils/str';
import { EezyButton } from '../Buttons';
import { Flex } from '../Flex';
import { FormInput, FormRadio, FormSelect } from '../form';
import { IDropdownOption } from '../form/AutocompleteDropdown';
import { Icon } from '../Icon';
import { P, UniqueTitle } from '../textElements';
import { Modal, ModalActions, ModalContent } from './Modal';
import { useInvoiceProvisions } from 'containers/invoice/hooks/useInvoiceProvisions';
import { useUser } from 'queries/useUserQuery';

const Table = styled.table`
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;
    tbody tr {
        font-size: 15px;
        text-align: right;
        td {
            padding-top: 7px;
            padding-bottom: 4px;
            vertical-align: bottom;
            &:first-child {
                text-align: left;
            }
            &:nth-child(2) {
                max-width: 60px;
                text-align: right;
                padding-right: 5px;
            }
            &:nth-child(3) {
                max-width: 60px;
                text-align: right;
                padding-left: 5px;
            }
        }
    }
    tfoot tr {
        td {
            padding-top: 25px;
            &:first-child {
                vertical-align: top;
            }
            &:nth-child(2) {
                text-align: right;
                vertical-align: bottom;
            }
        }
    }
`;

const useStyles = makeStyles()({
    input: {
        ['&:active, &:focus-within']: {
            borderBottom: `1px solid ${COLOR_IMPORTANT}`,
        },
        borderBottom: `1px solid ${COLOR_GREY_FOG}`,
    },
    inputSpan: {
        position: 'absolute',
        right: 15,
        top: 32,
    },
    numberInput: {
        '&::-webkit-inner-spin-button': {
            appearance: 'none',
            margin: 0,
        },
        '&::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0,
        },
    },
    table: {
        marginTop: '20px',
    },
    travelExpense: {
        backgroundColor: COLOR_GRAYS_ANATOMY,
        padding: '0 10px',
        width: '100px',
    },
});

type Contact = {
    id: number;
    name: string;
    __typename: string;
};

interface IParticipantsModalProps {
    isOpen: boolean;
    invoice?: IInvoice;
    handleModalClose: () => void;
}

const ParticipantsModal = (props: IParticipantsModalProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const { data: contacts } = useQuery(GET_COWORKERS);
    const { data, loading: occupationsLoading } = useQuery(GET_OCCUPATIONS);
    const provisions = useInvoiceProvisions(props.invoice);

    const { classes } = useStyles();
    const [coworkers, setCoworkers] = useState<IProvision[]>([]);
    const [coworker, setCoworker] = useState<IProvision | null>(null);
    const [invoiceDivision, setInvoiceDivision] = useState<string>('euro');
    const [travelExpenses, setTravelExpenses] = useState(0);
    const [defaultOccupation, setDefaultOccupation] = useState<number>();

    const occupationOptions =
        data?.allOccupations?.map((opt: IOccupation) => {
            return {
                label: t(`occupations:${opt.id}`),
                value: opt.id.toString(),
            };
        }) || [];

    const handleDivision = (division: string) => {
        setInvoiceDivision(division);
    };

    const handleInputChange = (val: ICoworker) => {
        setCoworker({
            name: val.name,
            personId: val.id,
        });
    };

    const handleSetCoworker = () => {
        const isExist = coworkers.some((worker: IProvision) => worker.personId === coworker?.personId);
        if (coworker && !isExist) {
            const newCoworker = {
                ...coworker,
                fixedAmount: 0,
                occupationId: defaultOccupation,
                percentage: 0,
            };
            setEqualShares([...coworkers, newCoworker]);
        }
    };

    const setEqualShares = (newCoworkers: IProvision[]) => {
        const finalCoworkers = newCoworkers.map((worker) => {
            return {
                ...worker,
                fixedAmount: parseFloat(((props.invoice?.total ?? 0) / newCoworkers.length).toFixed(0)),
                percentage: parseFloat((100 / newCoworkers.length).toFixed(1)),
            };
        });

        calculateOwnerShare(finalCoworkers);
        setCoworkers(finalCoworkers);
    };

    const handleSubmit = () => {
        const fixedProvisions: IProvision[] = coworkers.map((worker: IProvision) => {
            return {
                [invoiceDivision === 'euro' ? 'fixedAmount' : 'percentage']:
                    invoiceDivision === 'euro' ? worker.fixedAmount : worker.percentage,
                name: worker.name,
                occupationId: worker.occupationId,
                personId: worker.personId,
                travelExpenses: worker.travelExpenses,
            };
        });

        addProvisions({
            variables: {
                id: props.invoice?.id,
                provisions: fixedProvisions,
            },
        });
    };

    const [addProvisions] = useMutation(ADD_PROVISIONS, {
        onCompleted: () => {
            props.handleModalClose();
            toast(t('general.saved'));
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('contact_already_exists')) {
                toast.error(t('profile:group-invoicing.invite-coworker-modal.errors.contact-already-exists'));
            } else if (getErrors(e)?.error.includes('NOT_FOUND')) {
                toast.error(t('profile:group-invoicing.invite-coworker-modal.errors.contact-is-not-exist'));
            } else {
                toast.error(t('errors.general'));
            }
        },
    });

    const handleProvisionNumbers = (provision: IProvision) => {
        const updatedProvision = { ...provision };
        const invoiceTotal = props.invoice?.total ?? 0;
        if (provision.percentage && provision.percentage > 0) {
            updatedProvision.percentage = provision.percentage;
        } else if (provision.fixedAmount && !provision.percentage) {
            updatedProvision.percentage = parseFloat(
                ((provision.fixedAmount / invoiceTotal) * 100).toFixed(1)
            );
        }
        if (provision.fixedAmount && provision.fixedAmount > 0) {
            updatedProvision.fixedAmount = provision.fixedAmount;
        } else if (provision.percentage && !provision.fixedAmount) {
            updatedProvision.fixedAmount = parseFloat(
                ((provision.percentage / 100) * invoiceTotal).toFixed(0)
            );
        }
        return updatedProvision;
    };

    const deleteDeleteCoworker = (provision: IProvision) => {
        const newProvision = coworkers.filter((cowo: IProvision) => cowo.personId !== provision.personId);
        setEqualShares(newProvision);
    };

    const calculateOwnerShare = (provisions: IProvision[]) => {
        let totalPercentage = 0;
        let totalAmount = 0;
        let totalTravelExpenses = 0;
        provisions.forEach((provision) => {
            if (provision.personId === user?.id) {
                return;
            }
            totalPercentage += provision.percentage || 0;
            totalAmount += provision.fixedAmount || 0;
            totalTravelExpenses += provision.travelExpenses || 0;
        });
        const owner = provisions.find((p) => p.personId === user?.id);
        owner!.percentage = 100 - totalPercentage;
        owner!.fixedAmount = props.invoice!.total - totalAmount;
        owner!.travelExpenses = 100 - totalTravelExpenses;
    };

    const handleRowInputChange = (name: string, value: any, row: IProvision) => {
        if (name === 'occupation') {
            const update = coworkers.map((provision) => {
                return provision.personId === row.personId
                    ? {
                          ...provision,
                          occupationId: parseInt(value, 10),
                      }
                    : provision;
            });
            setCoworkers(update);
        }
        if (name === 'percentage' || name === 'fixedAmount') {
            const val = parseFloat(value.replace(',', '.'));
            const cents = eursToCents(val);
            const update = coworkers.map((provision) => {
                return provision.personId === row.personId
                    ? {
                          ...handleProvisionNumbers(
                              name === 'fixedAmount'
                                  ? {
                                        ...provision,
                                        fixedAmount: cents,
                                        percentage: undefined,
                                    }
                                  : {
                                        ...provision,
                                        fixedAmount: undefined,
                                        percentage: val,
                                    }
                          ),
                      }
                    : provision;
            });
            calculateOwnerShare(update);
            setCoworkers(update);
        }
        if (name === 'travelExpense') {
            const val = parseFloat(value.replace(',', '.'));
            const percentage =
                invoiceDivision === 'percent' ? val : (eursToCents(val) / travelExpenses) * 100;
            const update = coworkers.map((provision) => {
                return provision.personId === row.personId
                    ? {
                          ...provision,
                          travelExpenses: percentage,
                      }
                    : provision;
            });
            calculateOwnerShare(update);
            setCoworkers(update);
        }
    };

    const isInvalid = () => {
        const owner = coworkers.find((c) => c.personId === user?.id);
        return (
            (owner?.percentage || 0) < 0 || (owner?.travelExpenses || 0) < 0 || (owner?.fixedAmount || 0) < 0
        );
    };

    useEffect(() => {
        if (!props.invoice?.invoiceItems) {
            return;
        }
        const totalExpenses = props.invoice.invoiceItems
            .filter((i) => i.itemType === 'travel')
            .reduce((total, item) => total + item.totalPrice!, 0);
        setTravelExpenses(totalExpenses);
    }, [props.invoice]);

    useEffect(() => {
        if (provisions) {
            const fixedProvisions = provisions.map((provision) => {
                return {
                    ...handleProvisionNumbers(provision),
                };
            });
            calculateOwnerShare(fixedProvisions);
            setCoworkers(fixedProvisions);
        }
    }, [provisions]);

    useEffect(() => {
        if (coworkers.length) {
            const foundUser = coworkers.find((coworker) => coworker.personId === user?.id);

            setDefaultOccupation(foundUser?.occupationId);
        }
    }, [coworkers, user?.id]);

    return (
        <Modal id="modal-strong-intro-video" isOpen={props.isOpen} onClose={props.handleModalClose}>
            <ModalContent>
                <UniqueTitle color={COLOR_BLACKWATER} style={{ paddingTop: '10px' }}>
                    {t('invoice.groupInvoice.participants-modal.title')}
                </UniqueTitle>
                <FormRadio
                    label={t('invoice.groupInvoice.participants-modal.button-title') || ''}
                    options={[
                        {
                            label: t('invoice.groupInvoice.participants-modal.button1'),
                            value: 'percent',
                        },
                        {
                            label: t('invoice.groupInvoice.participants-modal.button2'),
                            value: 'euro',
                        },
                    ]}
                    name="division"
                    onChange={handleDivision}
                    required
                    value={invoiceDivision}
                />

                <ParticipantRow
                    invoice={props.invoice}
                    invoiceDivision={invoiceDivision}
                    coworkers={coworkers}
                    occupationOptions={occupationOptions}
                    occupationsLoading={occupationsLoading}
                    handleRowInputChange={handleRowInputChange}
                    deleteDeleteCoworker={deleteDeleteCoworker}
                    travelExpenses={travelExpenses || 0}
                />

                <Flex
                    spread
                    style={{
                        alignItems: 'center',
                        paddingTop: 15,
                    }}
                >
                    <Autocomplete
                        id={'name-autocomplete'}
                        options={contacts?.coworkers?.contacts || []}
                        getOptionLabel={(option: Contact) => option.name}
                        renderOption={(props, option) => {
                            return (
                                <Box component="li" sx={{ fontWeight: 600 }} {...props}>
                                    {option?.name}
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder={t('invoice.groupInvoice.participants-modal.placeholder') || ''}
                                className={classes.input}
                            />
                        )}
                        onChange={(e: ChangeEvent<{}>, val: Contact | null) => {
                            val && handleInputChange(val);
                        }}
                        style={{ flexGrow: 1, marginRight: 40 }}
                    />

                    <EezyButton color="purple" onClick={handleSetCoworker}>
                        {t('form.add')}
                    </EezyButton>
                </Flex>
                <P
                    style={{
                        marginTop: 30,
                    }}
                >
                    {t('invoice.groupInvoice.participants-modal.description')}{' '}
                    {t('profile:group-invoicing.invite-coworker-modal.description-2')}
                </P>
                <P style={{ marginTop: 15 }}>
                    <Link to="/profile">{t('invoice.groupInvoice.participants-modal.link')}</Link>
                </P>
            </ModalContent>
            <ModalActions>
                <EezyButton
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    onClick={props.handleModalClose}
                >
                    {t('general.cancel')}
                </EezyButton>

                <EezyButton
                    disabled={isInvalid()}
                    style={{ border: `1px solid ${COLOR_BLUM}` }}
                    color="purple"
                    dark
                    onClick={handleSubmit}
                >
                    {t('general.save')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

interface IParticipantRowProps {
    invoice?: IInvoice;
    invoiceDivision: string;
    coworkers: IProvision[];
    occupationOptions: IDropdownOption[];
    occupationsLoading: boolean;
    handleRowInputChange: (name: string, val: number | string, row: IProvision) => void;
    deleteDeleteCoworker: (coworker: IProvision) => void;
    travelExpenses: number;
}

const getFixedValue = (provision: IProvision, invoice?: IInvoice): string => {
    if (provision.fixedAmount !== undefined && provision.fixedAmount !== null) {
        return centsToEur(provision.fixedAmount) + '';
    }
    if (provision.percentage !== undefined && provision.percentage !== null) {
        return (provision.percentage / 100) * (invoice?.total || 0) + '';
    }
    return '';
};

const getTravelExpenseValue = (
    provision: IProvision,
    totalExpenses: number,
    divisionType: string
): string => {
    const percentage = provision.travelExpenses || 0;
    if (divisionType === 'percent') {
        return formatPercentage(percentage);
    }
    return centsToEur(totalExpenses * (percentage / 100)) + '';
};

const ParticipantRow = (props: IParticipantRowProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const { classes } = useStyles();
    const tableRow = props.coworkers.map((coworker) => (
        <tr key={coworker.personId}>
            <td>
                <FormSelect
                    id={coworker.personId?.toString()}
                    labelIcon={
                        coworker.personId === user?.id && (
                            <Icon
                                icon={['far', 'user-crown']}
                                className="small"
                                style={{ marginLeft: 5 }}
                                color={COLOR_BLACKWATER}
                            />
                        )
                    }
                    label={coworker.name}
                    name="occupation"
                    onChange={(val: string | number, name: string) => {
                        if (typeof val === 'string') {
                            props.handleRowInputChange(name, val, coworker);
                        }
                    }}
                    options={sortObjectsByLabel(props.occupationOptions)}
                    optionsLoading={props.occupationsLoading}
                    required
                    selectStyle={{ maxWidth: 230 }}
                    value={coworker.occupationId?.toString() || ''}
                />
            </td>
            <td style={{ position: 'relative', paddingLeft: '5px' }}>
                <FormInput
                    className={classes.numberInput}
                    type="number"
                    onBlur={(val, name) => {
                        props.handleRowInputChange(name, val, coworker);
                    }}
                    name="percentage"
                    placeholder={'%'}
                    required
                    value={formatPercentage(coworker.percentage || 0).replace(',', '.')}
                    disabled={props.invoiceDivision === 'euro' || coworker.personId === user?.id}
                />
                <span className={classes.inputSpan}>%</span>
            </td>
            <td style={{ position: 'relative' }}>
                <FormInput
                    className={classes.numberInput}
                    type="number"
                    onBlur={(val, name) => {
                        props.handleRowInputChange(name, val, coworker);
                    }}
                    name="fixedAmount"
                    required
                    value={
                        coworker.personId === user?.id
                            ? getFixedValue(coworker, props.invoice)
                            : centsToEur(coworker.fixedAmount || 0) + ''
                    }
                    disabled={props.invoiceDivision === 'percent' || coworker.personId === user?.id}
                />
                <span className={classes.inputSpan}>€</span>
            </td>
            {props.travelExpenses > 0 && (
                <td className={classes.travelExpense} style={{ position: 'relative' }}>
                    <FormInput
                        className={classes.numberInput}
                        type="number"
                        onBlur={(val, name) => {
                            props.handleRowInputChange(name, val, coworker);
                        }}
                        name="travelExpense"
                        required
                        value={getTravelExpenseValue(coworker, props.travelExpenses, props.invoiceDivision)}
                        disabled={coworker.personId === user?.id}
                    />
                    <span className={classes.inputSpan}>
                        {props.invoiceDivision === 'percent' ? '%' : '€'}
                    </span>
                </td>
            )}
            <td>
                {user?.id !== coworker.personId && (
                    <EezyButton
                        color="purple"
                        type="button"
                        square
                        onClick={() => props.deleteDeleteCoworker(coworker)}
                        style={{
                            lineHeight: 0,
                            minWidth: 30,
                            padding: '0 5px 0 5px',
                        }}
                    >
                        <Icon icon={['far', 'trash-alt']} color={COLOR_IMPORTANT} />
                    </EezyButton>
                )}
            </td>
        </tr>
    ));
    return (
        <Table className={classes.table}>
            {props.travelExpenses > 0 && (
                <thead>
                    <tr style={{ position: 'relative', top: '20px', zIndex: 1 }}>
                        <td colSpan={3} />
                        <td
                            className={classes.travelExpense}
                            style={{ fontSize: '12px', lineHeight: '18px' }}
                        >
                            {t('profile:group-invoicing.travel-expense-share')}
                        </td>
                        <td />
                    </tr>
                </thead>
            )}
            <tbody>{tableRow}</tbody>
        </Table>
    );
};

export default ParticipantsModal;
