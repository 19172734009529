import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { showModals } from '../../actions/auth';
import { EezyButton, EezyLink, InlineButtonLink } from '../../components/Buttons';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { P, RowTitle } from '../../components/textElements';
import config from '../../config';
import { trans } from '../../utils';
import { useUser } from 'queries/useUserQuery';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
`;

interface IRegistrationCertModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
    showModals: (modals: string[]) => void;
}

const RegistrationCertModal = (props: IRegistrationCertModalProps) => {
    const user = useUser();
    return (
        <Modal
            id="modal-registration-certification"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={trans('dashboard.messages.registration.modal-title')}
        >
            <ModalContent>
                <P color="black">{trans('dashboard.messages.registration.modal-header')}</P>

                {!user?.isIdentified && (
                    <P color="black">
                        {trans('dashboard.messages.registration.modal-header2')}{' '}
                        <InlineButtonLink
                            onClick={() => {
                                props.showModals(['AUTH']);
                            }}
                        >
                            {trans('dashboard.messages.registration.modal-header2-link-anchor')}
                        </InlineButtonLink>
                        .
                    </P>
                )}
                <P color="black">{trans('dashboard.messages.registration.modal-text')}</P>

                <Row>
                    <RowTitle color="black">
                        {trans('languages.fi')}
                        {': '}
                    </RowTitle>
                    <EezyLink
                        color="purple"
                        href={`${config.backendUrl3}/registrationPdf?lang=fi`}
                        target="_blank"
                        style={{ marginLeft: 10 }}
                    >
                        {trans('dashboard.messages.registration.modal-download')}
                    </EezyLink>
                </Row>
                <Row>
                    <RowTitle color="black">
                        {trans('languages.en')}
                        {': '}
                    </RowTitle>
                    <EezyLink
                        color="purple"
                        href={`${config.backendUrl3}/registrationPdf?lang=en`}
                        style={{ marginLeft: 10 }}
                        target="_blank"
                    >
                        {trans('dashboard.messages.registration.modal-download')}
                    </EezyLink>
                </Row>
            </ModalContent>
            <ModalActions style={{ justifyContent: 'center' }}>
                <EezyButton color="purple" dark onClick={props.handleModalClose}>
                    {trans('menu.close')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        showModals: (modals: string[]) => {
            dispatch(showModals(modals));
        },
    };
};

export default connect(null, mapDispatchToProps)(RegistrationCertModal);
