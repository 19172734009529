import { Icon } from 'components/Icon';
import { CampaignButton } from '../constants';
import { EezyButton } from 'components/Buttons';
import styled from 'styled-components';

interface IMiniCampaignBanner {
    image: string;
    title: string;
    shortTitle?: string;
    description: string;
    shortDescription?: string;
    buttons: CampaignButton[];
    onClose?: () => void;
    onClickButton?: (item: CampaignButton) => void;
    onClickReadMore?: () => void;
}

const TitleWrapper = styled.div`
    @media (max-width: ${1024 - 1}px) {
        * {
            color: #fff !important;
        }
    }
`;

const MiniCampaignBanner = (props: IMiniCampaignBanner) => {
    return (
        <div className="pb-2 bg-violet-100 lg:bg-white lg:pb-0">
            <div className="flex items-center justify-between pr-5 lg:pr-10 lg:mx-auto bg-green-500 lg:bg-white h-16 lg:h-108 lg:max-w-lg text-white lg:text-txt-description">
                <div className="flex items-center h-full">
                    <div className="hidden lg:flex items-center justify-center h-full pl-10">
                        {/* <img src={props.image} className="h-full p-3 pr-0" /> */}
                        <Icon icon={['fas', 'chart-mixed']} className="xl" />
                    </div>
                    <TitleWrapper
                        dangerouslySetInnerHTML={{ __html: props.shortTitle || props.title }}
                        className="hidden lg:block ml-9 text-2xl font-semibold no-underline text-gray-800"
                    />
                    <TitleWrapper
                        dangerouslySetInnerHTML={{ __html: props.shortTitle || props.title }}
                        className="lg:hidden ml-6 text-base font-semibold no-underline text-inherit cursor-pointer"
                        onClick={props.onClickReadMore}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: props.shortDescription || props.description }}
                        className="hidden lg:block ml-6 lg:ml-9 text-sm text-inherit"
                    />
                    <div
                        className="flex lg:hidden items-center ml-3 text-inherit"
                        onClick={props.onClickReadMore}
                    >
                        <button className="inline-flex">
                            <Icon icon={['far', 'arrow-right']} color="inherit" />
                        </button>
                    </div>
                </div>
                <div className="flex items-stretch justify-stretch gap-10">
                    <div className="hidden lg:flex flex-row-reverse gap-5">
                        {props.buttons.map((i) => (
                            <EezyButton
                                key={i.value}
                                className="v2-btn"
                                dark={i.type == 'primary'}
                                color={i.type == 'primary' ? 'eezy-green' : 'purple'}
                                onClick={() => props.onClickButton?.(i)}
                            >
                                {i.label}
                            </EezyButton>
                        ))}
                    </div>
                    <div className="hidden lg:block w-px bg-gray-300" />
                    <div className="flex items-center">
                        <button className="inline-flex" onClick={props.onClose}>
                            <Icon icon={['far', 'xmark']} color="inherit" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
MiniCampaignBanner.displayName = 'MiniCampaignBanner';

export default MiniCampaignBanner;
