import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormInput, FormRow, SplitRow } from '../../components/form';
import { SCREEN_S } from '../../styles/variables';
import { ICostComponentProps } from '../../utils/costs/costLogic';
import { FormCheckmark } from 'components/form/FormCheckmark';
import { useTranslation } from 'react-i18next';
import { useUser } from 'queries/useUserQuery';

const Wrapper = styled.div`
    @media (min-width: ${SCREEN_S}px) {
        display: flex;
        justify-content: flex;
        margin-left: auto;
        padding-left: 5px;
    }
    margin: auto;
`;

export const CarRegistrationNumber = (props: ICostComponentProps) => {
    const { t } = useTranslation();
    const user = useUser();
    const { dispatch } = props;
    const [numberSaved, setNumberSaved] = useState(false);
    const [carNumber, setCarNumber] = useState('');

    const savedCarNumber = user?.preferences?.carNumber;

    const handleNumberInput = React.useCallback(
        (val: string) => {
            setCarNumber(val);
            dispatch({
                payload: { carNumber: val },
                type: 'SAVE_TRAVEL',
            });
        },
        [dispatch]
    );

    const handleNumberSave = React.useCallback(() => {
        dispatch({
            payload: { carNumberSaved: !numberSaved },
            type: 'SAVE_TRAVEL',
        });
    }, [dispatch]);

    useEffect(() => {
        if (!props.currentTravel?.carNumber && savedCarNumber) {
            setCarNumber(savedCarNumber);
            dispatch({
                payload: { carNumber: savedCarNumber },
                type: 'SAVE_TRAVEL',
            });
        } else if (props.currentTravel?.carNumber) {
            setCarNumber(props.currentTravel?.carNumber);
        }
    }, []);

    return (
        <Wrapper>
            <SplitRow style={{ width: '100%' }}>
                <FormRow style={{ flexGrow: 1 }}>
                    <div
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <FormInput
                            label={t('costs.car-number') || ''}
                            onChange={(val) => {
                                handleNumberInput(val);
                            }}
                            name="invite-email"
                            placeholder={'AAA-111'}
                            required
                            value={carNumber}
                        />
                    </div>
                </FormRow>
                <FormRow style={{ flexGrow: 1 }}>
                    <div
                        style={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <FormCheckmark
                            id="save-car-number"
                            label={t('costs.car-number-save') || ''}
                            name="eezy-pay-terms"
                            checked={numberSaved}
                            onChange={() => {
                                setNumberSaved(!numberSaved);
                                handleNumberSave();
                            }}
                            required
                            value={'acceptTerms'}
                        />
                    </div>
                </FormRow>
            </SplitRow>
        </Wrapper>
    );
};
