import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { IMessageReferenceInput } from '../../../../shared/src/types/message';
import { InlineButtonLink } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import { Icon } from '../../components/Icon';
import { BodyP } from '../../components/textElements';
import { COLOR_BLUM } from '../../styles/variables';
import { formatRelatedObject, formatUserId, submitVisitorVariable } from '../../utils/giosg';
import { isAnkkaId } from '../../utils/invoice/invoiceLogic';
import { useTranslation } from 'react-i18next';
import { useUser } from 'queries/useUserQuery';

interface IProps {
    relatedObject?: IMessageReferenceInput;
    style?: CSSProperties;
    displayEezyPayBadge?: boolean;
}

const RelatedObjectMessage = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const user = useUser();
    if (props.relatedObject && isAnkkaId(props.relatedObject.id)) {
        return null;
    }

    const submitChatInfo = () => {
        if (!user) {
            return;
        }

        const visitorVariable = props.relatedObject
            ? {
                  recruitmentCode: user.recruitmentCode,
                  relatedObject: formatRelatedObject(props.relatedObject),
                  userId: formatUserId(user.id),
              }
            : {
                  recruitmentCode: user?.recruitmentCode,
                  userId: formatUserId(user.id),
              };
        submitVisitorVariable(visitorVariable);
    };

    const eezyBadgesUrl =
        i18n.language === 'en'
            ? 'https://kevytyrittajat.eezy.fi/en/download-eezypay-badges/'
            : 'https://kevytyrittajat.eezy.fi/eezypay-badget/';

    return (
        <Flex column style={{ margin: '20px 0 30px', ...props.style }}>
            <BodyP color={COLOR_BLUM}>
                {props.relatedObject?.id ? t(`support:message.attach-${props.relatedObject?.type}`) : ''}
            </BodyP>
            <BodyP color={COLOR_BLUM} className="mt-4">
                <Link
                    to={
                        props.relatedObject
                            ? {
                                  pathname: '/support/new-message',
                              }
                            : {
                                  pathname: '/support/new-message',
                              }
                    }
                    state={{ relatedObject: props.relatedObject }}
                >
                    {t('support:message.attach-link')}
                    <Icon className="small ml-2" color={COLOR_BLUM} icon={['far', 'circle-right']} />
                </Link>

                <InlineButtonLink
                    className="open-chat chat-open-inline ml-4 no-underline"
                    onClick={submitChatInfo}
                >
                    {t('support:message.start-chat')}
                    <Icon
                        className="small ml-4"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                    />
                </InlineButtonLink>
                {props.displayEezyPayBadge && (
                    <a href={eezyBadgesUrl} target="_blank">
                        <InlineButtonLink className="ml-4 no-underline">
                            {t('support:eezypay-badges')}
                            <Icon
                                className="small ml-2"
                                color={COLOR_BLUM}
                                icon={['far', 'arrow-up-right-from-square']}
                            />
                        </InlineButtonLink>
                    </a>
                )}
            </BodyP>
        </Flex>
    );
};

export default RelatedObjectMessage;
