import { useState } from 'react';
import { EezyButton, EezyLink } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { FormSelect } from 'components/form';
import { IDropdownOption } from 'components/form/AutocompleteDropdown';
import { Icon } from 'components/Icon';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { BodyP, Label } from 'components/textElements';
import config from 'config';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_GREYHOUND } from 'styles/variables';
import { currentYear } from 'utils';
import { useTranslation } from 'react-i18next';
import InfoMessage from 'components/InfoMessage';
import { useUser } from 'queries/useUserQuery';
import moment from 'moment';

interface IModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const DownloadTaxReportModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();
    const user = useUser();

    const registrationYear = user ? moment(user.registrationDate).year() : currentYear();

    const [year, setYear] = useState<string>((currentYear() - 1).toString());

    const yearOptions: IDropdownOption[] = Array.from(
        { length: currentYear() - registrationYear + 1 },
        (_, i) => {
            const year = registrationYear + i;
            return { label: `${year}`, value: `${year}` };
        }
    );

    return (
        <Modal
            id="modal-download-tax-report"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('salaries:taxModal.title') || ''}
            noscroll
        >
            <ModalContent>
                <FormSelect
                    label={t('salaries:taxModal.year-label') || ''}
                    labelStyle={{ opacity: 1 }}
                    name="tax-report-year"
                    onChange={(val) => setYear(val)}
                    options={yearOptions}
                    required
                    selectStyle={{ maxWidth: 110 }}
                    showIcon
                    splitRow
                    value={year}
                />

                <Flex spread style={{ marginTop: 24 }}>
                    <Label color={COLOR_BLACKWATER}>{t('salaries:taxModal.net-report-label')}</Label>
                    <EezyLink
                        color="purple"
                        href={`${config.backendUrl3}/net-subtractions/${year}/report.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('file.download')}
                    </EezyLink>
                </Flex>

                <Flex spread style={{ marginTop: 24 }}>
                    <Label color={COLOR_BLACKWATER}>{t('salaries:taxModal.travel-report-label')}</Label>
                    <EezyLink
                        color="purple"
                        href={`${config.backendUrl3}/taxable-costs/${year}/report.pdf`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('file.download')}
                    </EezyLink>
                </Flex>

                <BodyP color={COLOR_GREYHOUND} style={{ marginTop: 24 }}>
                    {t('salaries:taxModal.paragraph-1')}
                    <div style={{ marginTop: 8 }}>
                        <b>{t('salaries:taxModal.bold-1')}</b>
                        {t('salaries:taxModal.paragraph-3-1')}
                    </div>
                    <div style={{ marginTop: 8 }}>
                        <b>{t('salaries:taxModal.bold-2')}</b>
                        {t('salaries:taxModal.paragraph-3-2')}
                    </div>
                    <InfoMessage message={t('salaries:taxModal.deductions-note')} background />
                </BodyP>

                <BodyP style={{ marginTop: 16 }} color={COLOR_BLUM}>
                    <a
                        className="unstyled"
                        href={
                            i18n.language === 'en'
                                ? 'https://kevytyrittajat.eezy.fi/en/faq/#what-work-related-costs-can-a-light-entrepreneur-deduct-in-taxation'
                                : 'https://kevytyrittajat.eezy.fi/usein-kysyttya/#mita-tyohon-liittyvia-kuluja-kevytyrittaja-voi-vahentaa-verotuksessa'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('salaries:taxModal.link-anchor')}{' '}
                        <Icon icon={['far', 'arrow-up-right-from-square']} color={COLOR_BLUM} />
                    </a>
                </BodyP>
            </ModalContent>

            <ModalActions>
                <EezyButton color="purple" onClick={props.handleModalClose}>
                    {t('general.cancel')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default DownloadTaxReportModal;
