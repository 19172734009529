import React, { FormEvent } from 'react';
import styled from 'styled-components';
import ArrowDown from '../../assets/images/arrow-down.svg';
import { COLOR_BLUM, COLOR_DARK_GRAY, COLOR_WHITE_WALKER } from '../../styles/variables';

const Select = styled.select`
    appearance: none;
    background-color: ${COLOR_WHITE_WALKER};
    background: url(${ArrowDown}) no-repeat 95% 50%;
    border: 2px solid ${COLOR_BLUM};
    border-radius: 4px;
    color: ${COLOR_DARK_GRAY};
    padding: 10px;
`;

interface ISelectOption {
    key: string;
    value: string;
}

interface ISelectProps {
    onChange: (e: FormEvent<HTMLSelectElement>) => void;
    options: ISelectOption[];
    disabledValue?: string;
    selectedValue: string;
}

export const Dropdown = (props: ISelectProps) => {
    const { onChange, options, disabledValue, selectedValue } = props;
    const selected = selectedValue || '';
    return (
        <Select defaultValue={selected} onChange={onChange}>
            {disabledValue && (
                <option disabled value={''}>
                    {disabledValue}
                </option>
            )}
            {options.map((opt: ISelectOption) => {
                return (
                    <option key={opt.key} value={opt.key}>
                        {opt.value}
                    </option>
                );
            })}
        </Select>
    );
};
