import React from 'react';

const offsetFromViewportTop = (id: string) => {
    return document?.getElementById(id)?.getBoundingClientRect().top;
};

export const offsetFromTop = (id: string) => {
    return window.pageYOffset + (offsetFromViewportTop(id) || 0);
};

export const splitToParagraphs = (text: string): JSX.Element[] =>
    text.split('\n').map((pText) => <p style={{ margin: '5px 0' }}> {pText}</p>);
