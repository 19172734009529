import React from 'react';
import { IContactPerson } from '../../../../../shared/src/types/invoice';
import { ButtonColor, EezyButton } from '../../../components/Buttons';
import { Flex } from '../../../components/Flex';
import { CheckboxButton, FormButton, Input, SplitRow } from '../../../components/form';
import { Icon } from '../../../components/Icon';
import LoadingSpinner from '../../../components/Loading';
import { COLOR_BLACKWATER, COLOR_BLUM, COLOR_IMPORTANT } from '../../../styles/variables';
import { trans } from '../../../utils';
import { contactPeopleValidator } from '../../../utils/invoice/validators';
import { formatValidationResult } from '../../../utils/validation';

interface IContactPersonsProps {
    showErrors?: boolean;
    contactPeople: IContactPerson[];
    disableSelect?: boolean;
    handleChange: (contacts: IContactPerson[], selectedContactPersonIndex?: number) => void;
    invoiceId?: number;
    loading?: boolean;
    readonly?: boolean;
    selectedContactPersonIndex?: number;
}

const ContactPeopleForm = (props: IContactPersonsProps) => {
    const handleAddContactPerson = () => {
        const newCp: IContactPerson = {
            active: true,
            firstName: '',
            lastName: '',
        };
        props.handleChange([...props.contactPeople, newCp], props.selectedContactPersonIndex);
    };

    const handleDeleteContactPerson = (index: number) => {
        const cps = props.contactPeople.map((cp: IContactPerson, i: number) => {
            return {
                ...cp,
                active: cp.active && index !== i,
            };
        });
        const scps =
            index === props.selectedContactPersonIndex ? undefined : props.selectedContactPersonIndex;
        props.handleChange(cps, scps);
    };

    const handleUpdateName = (index: number, name: object) => {
        const cps = props.contactPeople.map((cp: IContactPerson, i: number) => {
            if (index !== i) {
                return cp;
            }
            return {
                ...cp,
                ...name,
            };
        });
        props.handleChange(cps, props.selectedContactPersonIndex);
    };

    const handleContactPersonSelect = (index: number) => {
        if (index === props.selectedContactPersonIndex) {
            props.handleChange(props.contactPeople);
        } else {
            props.handleChange(props.contactPeople, index);
        }
    };

    const errors = formatValidationResult(
        contactPeopleValidator.validate(props.contactPeople.filter((c: IContactPerson) => c.active))
    );

    if (props.loading) {
        return (
            <Flex column center style={{ margin: '20px 0 0' }}>
                <LoadingSpinner size="1em" color={COLOR_BLACKWATER} />
            </Flex>
        );
    }

    return (
        <>
            <FormButton
                ariaLabel={trans('invoice.form.recipient.contactPerson.add')}
                color={'purple' as ButtonColor}
                disabled={props.readonly}
                error={props.showErrors ? errors : undefined}
                hasIcon
                label={trans(`invoice.form.recipient.contactPerson.label`)}
                name="contactPerson"
                onClick={handleAddContactPerson}
            >
                <Icon color={COLOR_BLUM} icon={['far', 'plus']} />{' '}
                {trans('invoice.form.recipient.contactPerson.button')}
            </FormButton>

            {props.contactPeople.map((c: IContactPerson, index: number) => {
                if (c.active === false) {
                    return null;
                }
                const cSelected = props.selectedContactPersonIndex === index;

                return (
                    <Flex key={`cp-${index}`} center spread style={{ marginTop: 8 }}>
                        <SplitRow style={{ width: '100%' }}>
                            <Input
                                aria-invalid={props.showErrors && !c.firstName}
                                disabled={!!c.id}
                                id={`cp-firstName-${index}`}
                                onChange={(e) => {
                                    handleUpdateName(index, {
                                        firstName: e.currentTarget.value,
                                    });
                                }}
                                placeholder={trans(`invoice.form.recipient.firstName`)}
                                type="text"
                                value={c.firstName}
                            />
                            <Input
                                aria-invalid={props.showErrors && !c.lastName}
                                disabled={!!c.id}
                                id={`cp-lastName-${index}`}
                                onChange={(e) => {
                                    handleUpdateName(index, {
                                        lastName: e.currentTarget.value,
                                    });
                                }}
                                placeholder={trans(`invoice.form.recipient.lastName`)}
                                style={{ margin: '0 10px' }}
                                type="text"
                                value={c.lastName}
                            />
                        </SplitRow>
                        <Flex posEnd style={{ width: 110 }}>
                            {!props.disableSelect && (
                                <CheckboxButton
                                    checked={cSelected}
                                    disabled={props.readonly}
                                    label={cSelected ? trans('file.selected') : trans('file.select')}
                                    id={`cp-${index}`}
                                    inverted
                                    name="contact-person"
                                    onChange={() => {
                                        handleContactPersonSelect(index);
                                    }}
                                    value={c.id}
                                    style={{
                                        width: 75,
                                    }}
                                />
                            )}
                            <EezyButton
                                color={'purple'}
                                disabled={props.readonly}
                                square
                                style={{ marginLeft: 5, padding: '0' }}
                                type="button"
                                onClick={() => {
                                    handleDeleteContactPerson(index);
                                }}
                                width={30}
                            >
                                <Icon color={COLOR_IMPORTANT} icon={['far', 'trash-alt']} />
                            </EezyButton>
                        </Flex>
                    </Flex>
                );
            })}
        </>
    );
};

export default ContactPeopleForm;
