import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import { useNavigate } from 'react-router-dom';
import InvoiceListItem from './InvoiceListItem';

type InvoiceListItems = {
    items: IBriefInvoice[];
    selectedId: number | undefined;
    onMouseOver?: (item: IBriefInvoice, index: number) => void;
};

const InvoiceListItems: React.FC<InvoiceListItems> = (props: InvoiceListItems) => {
    const navigate = useNavigate();

    const sendBriefInvoice = (invoiceBrief: IBriefInvoice) => {
        const url = invoiceBrief.isGroupInvoice
            ? `/group/view/${invoiceBrief.id}`
            : `/invoices/view/${invoiceBrief.id}`;
        if (invoiceBrief.status !== 'sending_pending') {
            navigate(url);
        }
    };

    const items = props.items.map((invoiceBrief) => ({
        data: invoiceBrief,
        selected: props.selectedId === invoiceBrief.id,
        onClick: () => sendBriefInvoice(invoiceBrief),
    }));

    return (
        <div>
            <ul>
                {items.map((item, index) => (
                    <li
                        className={item.selected ? 'selected' : ''}
                        key={`${index}-invoice-${item.data.id}`}
                        tabIndex={0}
                        onClick={item.onClick}
                        onKeyPress={item.onClick}
                    >
                        <div onMouseOver={() => props.onMouseOver?.(item.data, index)}>
                            <InvoiceListItem
                                invoiceBrief={item.data}
                                key={item.data.id}
                                selected={item.selected}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default InvoiceListItems;
