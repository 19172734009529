import { IBriefInvoice } from '../../../../shared/src/types/invoice';
import Fuse from 'fuse.js';

const INVOICE_SEARCH_FIELDS: { name: keyof IBriefInvoice; weight: number }[] = [
    { name: 'name', weight: 10 },
    { name: 'description', weight: 5 },
];

export const searchInvoices = (invoices: IBriefInvoice[], searchTerm: string): IBriefInvoice[] => {
    const invoicesWithMatchingId = invoices.filter(
        (invoice) =>
            invoice.id === parseInt(searchTerm) ||
            invoice.invoiceNumber === searchTerm ||
            invoice.draftNumber === searchTerm
    );

    if (invoicesWithMatchingId.length > 0) {
        return invoicesWithMatchingId;
    }

    const fuse = new Fuse(invoices, { keys: INVOICE_SEARCH_FIELDS, threshold: 0.4 });
    const results = fuse.search(searchTerm);
    return results.map((match) => match.item);
};
