import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { ClientType, IInvoice, IInvoiceKeys, InvoicingMethod } from '../../../../shared/src/types/invoice';
import { EezyButton } from 'components/Buttons';
import { DatePicker, FormRadio, FormSelect, FormTextArea } from 'components/form';
import { FormLabel } from 'components/form/FormLabel';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import SwitchButton from 'components/Switch';
import config from 'config';
import { GtagCommands, GTMEvents, UserEventCategories, UserEventNames } from 'constants/user';
import { COLOR_DARK_GRAY, COLOR_GREYJOY, COLOR_IMPORTANT } from 'styles/variables';
import { addDays, formatDateISO, getMinutesSinceLastEvent } from 'utils';
import {
    cleanRecipientBeforeSending,
    clientToClientInput,
    getMaxDispatchDate,
    getMinDuedate,
    getPaymentTermOptions,
    getTemplate,
    invoicingMethodOptions,
} from 'utils/invoice/invoiceLogic';
import { GET_USER_EVENTS_LIST } from '../profile/queries';
import REGISTER_USER_EVENT from '../profile/queries/registerUserEvent';
import { UPDATE_INVOICE, UPDATE_RECIPIENT } from './queries';
import { useTranslation } from 'react-i18next';
import InfoMessage from 'components/InfoMessage';
import { DispatchDateType, strToDispatchDateType } from './InvoicePaymentPanel';
import { endOfDay, isBefore, subDays } from 'date-fns';
import { useUser } from 'queries/useUserQuery';

interface IInvoiceSendModalProps {
    handleModalClose: () => void;
    handleSend: (messageToEezy: string) => void;
    invoice: IInvoice;
    isOpen: boolean;
}

const Error = styled.div`
    color: ${COLOR_IMPORTANT};
    margin-bottom: 20px;
    margin-top: -10px;
    text-align: center;
`;

const InvoiceSendModal = (props: IInvoiceSendModalProps) => {
    const { t } = useTranslation();
    const user = useUser();

    const [updatedInvoice, setUpdatedInvoice] = useState<Partial<IInvoice>>({});

    const invoice = { ...props.invoice, ...updatedInvoice };

    const [dispatchDateType, setDispatchDateType] = useState(
        invoice?.dispatchDate ? DispatchDateType.Later : DispatchDateType.Now
    );

    const handleClose = () => {
        props.handleModalClose();
    };

    const handleSend = () => {
        props.handleSend(messageToEezy);
        const minutesSinceLastEventGiosg1 = getMinutesSinceLastEvent(
            lastUserEvents,
            UserEventNames.GIOSG_1_AFTER_INVOICE_SEND_SUCCESS
        );
        // const minutesSinceLastEventGiosg2 = getMinutesSinceLastEvent(lastUserEvents, UserEventNames.GIOSG_2_SURVEY);

        // if (
        //     !minutesSinceLastEventGiosg2 ||
        //     (
        //         minutesSinceLastEventGiosg2 > config.giosgInteraction2MinutesTillRepeat &&
        //         allGiosg2Events?.userEventsList?.length < config.giosgInteraction2TimesToRepeat
        //     )
        // ) {
        //     registerUserEvent({
        //         variables: {
        //             event: {
        //                 category: UserEventCategories.GIOSG,
        //                 name: UserEventNames.GIOSG_2_SURVEY
        //             }
        //         }
        //     });
        // } else
        if (
            !minutesSinceLastEventGiosg1 ||
            minutesSinceLastEventGiosg1 > config.giosgInteraction1MinutesTillRepeat
        ) {
            registerUserEvent({
                variables: {
                    event: {
                        category: UserEventCategories.GIOSG,
                        name: UserEventNames.GIOSG_1_AFTER_INVOICE_SEND_SUCCESS,
                    },
                },
            });
        }
        window.gtag && window.gtag(GtagCommands.event, GTMEvents.invoice_sent);
        handleClose();
    };

    const { data: lastUserEvents } = useQuery(GET_USER_EVENTS_LIST, {
        variables: {
            category: UserEventCategories.GIOSG,
            lastEventFlag: true,
        },
    });

    // const { data: allGiosg2Events } = useQuery(GET_USER_EVENTS_LIST, {
    //     variables: {
    //         name: UserEventNames.GIOSG_2_SURVEY
    //     }
    // });

    const [registerUserEvent] = useMutation(REGISTER_USER_EVENT, {
        onCompleted: () => {
            (window as any).userId = user?.id;
        },
        refetchQueries: () => [
            {
                query: GET_USER_EVENTS_LIST,
                variables: {
                    category: UserEventCategories.GIOSG,
                    lastEventFlag: true,
                },
            },
        ],
    });

    const [updateInvoice, { loading: invoiceUpdateLoading }] = useMutation(UPDATE_INVOICE, {
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const [updateRecipient, { loading: recipientUpdateLoading }] = useMutation(UPDATE_RECIPIENT, {
        onError: () => {
            toast.error(t('errors.general'));
        },
    });

    const handleInvoiceUpdate = (o: IInvoiceKeys) => {
        updateInvoice({
            optimisticResponse: {
                updateInvoice: {
                    __typename: 'Invoice',
                    ...props.invoice,
                    ...o,
                },
            },
            variables: {
                id: props.invoice.id,
                ...o,
            },
        });

        setUpdatedInvoice(o);
    };

    // If the invoice is changed so that the current dispatch date is too far in the future, adjust it to the
    // maximum allowed dispatch date.
    useEffect(() => {
        if (
            invoice.dispatchDate &&
            isBefore(getMaxDispatchDate(invoice), endOfDay(subDays(new Date(invoice.dispatchDate), 1)))
        ) {
            handleInvoiceUpdate({ dispatchDate: formatDateISO(getMaxDispatchDate(invoice)) });
            toast(t('invoice.dispatch-date-adjusted'), { type: 'warning' });
        }
    }, [invoice]);

    const handleRecipientChange = (val: string, name: string) => {
        setInvoicingMethodError(undefined);
        if (!invoice?.recipient) {
            return toast.error(t('errors.general'));
        }
        if (name === 'invoicingMethod') {
            setExplicitMethodValue(val as InvoicingMethod);
        }
        if (val === 'email' && name === 'invoicingMethod' && !invoice?.recipient?.email) {
            setInvoicingMethodError('missing-email');
            return;
        }
        if (val === 'einvoice' && name === 'invoicingMethod' && !invoice?.recipient?.einvoiceOvt) {
            setInvoicingMethodError('missing-einvoice');
            return;
        }
        const id = invoice.recipient.id;
        const recipient = cleanRecipientBeforeSending(clientToClientInput(invoice?.recipient));
        updateRecipient({
            variables: {
                recipient: {
                    ...recipient,
                    [name]: val,
                },
                recipientId: id,
            },
        }).catch(() => {
            toast.error(t('errors.general'));
        });
    };

    type InvoicingError = 'missing-einvoice' | 'missing-email' | undefined;
    const [invoicingMethodError, setInvoicingMethodError] = useState<InvoicingError>();

    const [explicitMethodValue, setExplicitMethodValue] = useState<InvoicingMethod>();

    const template = getTemplate(invoice?.template).id;
    const paymentTermOptions = getPaymentTermOptions(invoice);
    const [messageToEezy, setMessageToEezy] = useState(invoice.messageToEezy || '');

    const invoiceSendMethod = explicitMethodValue || props.invoice?.recipient?.invoicingMethod || 'email';

    return (
        <Modal
            id="invoice-send"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('invoice.send-invoice') || ''}
            noscroll
        >
            <ModalContent className="flex flex-col gap-4">
                {invoicingMethodError && <Error>{t('errors.' + invoicingMethodError)}</Error>}
                <div className="flex justify-between items-center">
                    <FormLabel>{t('invoice.template-title')}</FormLabel>
                    <p className="text-sm text-black">
                        {t(`invoice.templates.${template === 'domestic' ? 'domestic2' : template}`)}
                    </p>
                </div>

                <div className="flex justify-between items-center">
                    <FormLabel>{t('invoice.occupation')}</FormLabel>
                    <p className="text-sm text-black">{t(`occupations:${invoice?.occupation}`)}</p>
                </div>

                <div className="flex justify-between items-center">
                    <FormLabel>{t('invoice.form.recipient.invoicingMethod.label')}</FormLabel>
                    <FormSelect
                        name="invoicingMethod"
                        onChange={handleRecipientChange}
                        options={invoicingMethodOptions(invoice?.recipient?.type as ClientType)}
                        required
                        showIcon
                        value={invoiceSendMethod}
                    />
                </div>

                {invoice.paymentTerm !== null ? (
                    <div className="flex justify-between items-center">
                        <FormLabel>{t('invoice.paymentTerm')}</FormLabel>
                        <FormSelect
                            name="paymentTerm"
                            onChange={(val: string) => {
                                handleInvoiceUpdate({
                                    paymentTerm: parseInt(val, 10),
                                });
                            }}
                            options={paymentTermOptions}
                            required
                            showIcon
                            value={invoice?.paymentTerm + ''}
                        />
                    </div>
                ) : (
                    <div className="flex justify-between items-center">
                        <FormLabel>{t('general.duedate')}</FormLabel>
                        <DatePicker
                            buttonStyle={{
                                color: COLOR_GREYJOY,
                                marginLeft: 5,
                                paddingRight: 0,
                            }}
                            disablePast={true}
                            disableToolbar
                            errorPlacement="tooltip"
                            label={t('general.select-duedate')}
                            lightBorder
                            minDate={getMinDuedate(invoice)}
                            placeholder=""
                            icon={['far', 'calendar-day']}
                            id="invoice-duedate"
                            inputStyle={{
                                color: COLOR_DARK_GRAY,
                                textAlign: 'left',
                            }}
                            onChange={(dueDate: Date | null) => {
                                if (dueDate) {
                                    handleInvoiceUpdate({
                                        dueDate: formatDateISO(dueDate),
                                    });
                                }
                            }}
                            required
                            value={invoice.dueDate}
                            className="max-w-40"
                        />
                    </div>
                )}

                <div className="flex justify-between items-center">
                    <FormLabel>{t('invoice.debtCollection.label')}</FormLabel>

                    <SwitchButton
                        checked={invoice.debtCollection}
                        onChange={(e, checked) => {
                            handleInvoiceUpdate({
                                debtCollection: checked,
                            });
                        }}
                        inputProps={{
                            'aria-label': t('invoice.express-switch') || '',
                        }}
                    />
                </div>

                <div className="flex justify-between items-center">
                    <FormLabel>{t('invoice.dispatchDate.question')}</FormLabel>

                    <FormRadio
                        name="payment-dispatch-date"
                        onChange={(val) => {
                            if (val === DispatchDateType.Now) {
                                handleInvoiceUpdate({ dispatchDate: '' });
                            }
                            if (val === DispatchDateType.Later) {
                                handleInvoiceUpdate({
                                    dispatchDate: formatDateISO(addDays(1)),
                                });
                            }
                            setDispatchDateType(strToDispatchDateType(val));
                        }}
                        options={[
                            {
                                label: t('invoice.dispatchDate.immediately'),
                                value: DispatchDateType.Now,
                            },
                            {
                                label: t('invoice.dispatchDate.chooseDate'),
                                value: DispatchDateType.Later,
                            },
                        ]}
                        value={dispatchDateType}
                    />
                </div>

                {dispatchDateType === DispatchDateType.Later && (
                    <DatePicker
                        disablePast={true}
                        disableToolbar
                        label={t('invoice.dispatchDate.pickDate')}
                        maxDate={getMaxDispatchDate(invoice)} // One year
                        minDate={addDays(1)} // Tomorrow
                        placeholder={t('invoice.dispatchDate.placeholder')}
                        icon={['far', 'calendar-alt']}
                        id="invoice-dispatch-date"
                        onChange={(dispatchDate: Date | null) => {
                            if (dispatchDate) {
                                handleInvoiceUpdate({
                                    dispatchDate: formatDateISO(dispatchDate),
                                });
                            }
                        }}
                        value={invoice?.dispatchDate}
                    />
                )}

                <FormTextArea
                    label={t('invoice.sending-modal.message-to-eezy') || ''}
                    labelStyle={{ lineHeight: '28px', marginTop: '20px' }}
                    name="message-to-eezy"
                    onChange={(val: string) => {
                        setMessageToEezy(val);
                    }}
                    placeholder={t('invoice.sending-modal.message-placeholder') || ''}
                    value={messageToEezy}
                />

                {invoiceSendMethod === 'mail' && (
                    <InfoMessage message={t('invoice.templates.mailSendingAdditionalFee')} background />
                )}

                <p>{t('invoice.sending-modal.text1')}</p>
            </ModalContent>
            <ModalActions>
                <EezyButton color="purple" onClick={handleClose}>
                    {t('general.cancel')}
                </EezyButton>
                <EezyButton
                    color="important"
                    dark
                    disabled={invoiceUpdateLoading || recipientUpdateLoading || !!invoicingMethodError}
                    onClick={handleSend}
                >
                    {t('invoice.send')}
                </EezyButton>
            </ModalActions>
        </Modal>
    );
};

export default InvoiceSendModal;
