import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { EezyButton } from 'components/Buttons';
import { Flex } from 'components/Flex';
import { Modal, ModalActions, ModalContent } from 'components/modals/Modal';
import { RowTitle } from 'components/textElements';
import { COLOR_GREYHOUND } from 'styles/variables';
import GdprEn from './GdprEn';
import GdprFi from './GdprFi';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'utils/store';
import { logout } from 'actions/auth';

const Section = styled.div`
    margin: 30px 0;

    p {
        color: ${COLOR_GREYHOUND};
        margin-bottom: 5px;
    }
    div {
        margin-bottom: 15px;
    }
    .p1 {
        margin-left: 5px;
    }
    .p2 {
        margin-left: 10px;
    }
`;

interface IModalProps {
    accepted: boolean;
    handleConfirm: (document: string) => void;
    handleModalClose: () => void;
    isOpen: boolean;
    disableClose?: boolean;
}

const GdprModal = (props: IModalProps) => {
    const { t, i18n } = useTranslation();

    const confirm = () => {
        props.handleConfirm('GDPR');
        props.handleModalClose();
    };

    const renderCloseButton = () => {
        if (props.disableClose) {
            return (
                <ModalActions style={{ justifyContent: 'center' }}>
                    <EezyButton
                        color="purple"
                        onClick={() => {
                            dispatch(logout());
                        }}
                    >
                        {t('login.logout')}
                    </EezyButton>
                </ModalActions>
            );
        } else {
            return (
                <ModalActions style={{ justifyContent: 'center' }}>
                    <EezyButton color="purple" onClick={props.handleModalClose}>
                        {t('menu.close')}
                    </EezyButton>
                </ModalActions>
            );
        }
    };

    return (
        <Modal
            id="modal-yel"
            isOpen={props.isOpen}
            onClose={() => {
                if (!props.disableClose) {
                    props.handleModalClose();
                } else {
                    return null;
                }
            }}
            title={t('dashboard.messages.gdpr.modal-title') || ''}
            disableClose={props.disableClose}
        >
            <ModalContent>
                <Section>{i18n.language === 'fi' ? <GdprFi /> : <GdprEn />}</Section>

                {(!props.accepted || props.disableClose) && (
                    <Flex spread style={{ marginTop: 10 }}>
                        <RowTitle color="black">{t('dashboard.messages.gdpr.modal-confirm')}</RowTitle>
                        <EezyButton dark color="black" onClick={confirm}>
                            {t('form.confirm')}
                        </EezyButton>
                    </Flex>
                )}
                {renderCloseButton()}
            </ModalContent>
        </Modal>
    );
};

export default connect()(GdprModal);
