import { combineReducers } from 'redux';

import appReducer, { IAppState } from './appReducer';
import authReducer, { IAuth } from './authReducer';
import coworkerReducer, { IInvoiceState } from './coworkerReducer';
import fileReducer, { IFileState } from './fileReducer';
import invoiceReducer from './invoiceReducer';
import salaryReducer, { ISalaryState } from './salaryReducer';
import eezyPayReducer, { IEezyPayState } from './eezyPayReducer';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    coworker: coworkerReducer,
    file: fileReducer,
    invoice: invoiceReducer,
    salary: salaryReducer,
    eezyPay: eezyPayReducer,
});

export interface IRootState {
    app: IAppState;
    auth: IAuth;
    coworker: IInvoiceState;
    file: IFileState;
    invoice: IInvoiceState;
    salary: ISalaryState;
    eezyPay: IEezyPayState;
}
