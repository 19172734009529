import { HIDE_CONFIRMATION, SHOW_ALERT, HIDE_ALERT, SHOW_CONFIRMATION } from '../actionTypes';

export type IConfirmationTexts = {
    // Todo refactor to list of paragraphs
    paragraph?: string;
    paragraph2?: string;
    paragraph3?: string;
    paragraph4?: string;
    submit?: string;
    title: string;
};
export type IAlertTexts = IConfirmationTexts;

export interface IConfirmAction {
    onAction: (reason?: string) => void;
    texts: IConfirmationTexts;
    id: string;
}
export interface IAlertAction {
    onAction?: (reason?: string) => void;
    texts: IAlertTexts;
    id: string;
}

export const alertAction = (payload: IAlertAction) => ({
    payload,
    type: SHOW_ALERT,
});

export const hideAlert = () => ({
    type: HIDE_ALERT,
});

export const confirmAction = (payload: IConfirmAction) => ({
    payload,
    type: SHOW_CONFIRMATION,
});

export const hideConfirmation = () => ({
    type: HIDE_CONFIRMATION,
});
