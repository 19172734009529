import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'routes/Router';
import { enGB, fi } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const localeMap = {
    en: enGB,
    fi,
};

const App = () => {
    const { i18n } = useTranslation();
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[i18n.language as keyof typeof localeMap]}
        >
            <RouterProvider router={router} />
        </LocalizationProvider>
    );
};

export default App;
