import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SalaryCalculator, { CalculatorViewEnum } from '@eezydev/eezy-salary-calculator';
import styled from 'styled-components';
import { SalaryCalcConfig } from '.';
import { logoFull } from 'assets/images/eezylogo';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import LanguageOptions from 'components/LanguageOptions';
import { TogglingTools } from 'components/TogglingTools';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_GREYS_ANATOMY,
    COLOR_LILA,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    INVOICE_WIDTH,
    SCREEN_M,
    TOPBAR_HEIGHT,
} from 'styles/variables';
import Bar, { BarContent } from '../nav/Bar';
import PurpleBackground from '../nav/PurpleBackground';
import Footer from 'components/Footer';
import { useNavigate } from 'react-router-dom';

const TextButton = styled.button`
    color: ${COLOR_LILA};
    margin-left: 30px;
    text-decoration: none;

    span {
        font-size: 15px;
        font-weight: normal;
        margin-right: 10px;
        margin-left: 10px;
    }
    img {
        padding-bottom: 2px;
    }
`;
const Wrapper = styled.main`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    padding: 0 15px;
    width: 100%;

    @media (min-width: ${SCREEN_M}px) {
        flex-direction: row;
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }

    & > div:first-child {
        /* The right side */
        margin: 0 auto;
        max-width: none;
        width: 100%;
        @media (min-width: ${SCREEN_M}px) {
            margin: 0 15px;
            min-width: ${INVOICE_WIDTH}px;
            width: ${INVOICE_WIDTH}px;
        }
    }
    && > div:nth-child(2) {
        /* The right side */
        margin-top: 96px;
        padding: 30px 15px;
        width: 100%;
        @media (min-width: ${SCREEN_M}px) {
            min-width: 300px;
            max-width: 550px;
            padding: 0 15px 34px 15px;
        }

        div.help-area {
            background-color: ${COLOR_GREYS_ANATOMY};
            border-radius: ${BORDER_RADIUS};
            color: ${COLOR_BLACKWATER};
            line-height: 28px;
            padding: 15px;
            li {
                font-size: 16px;
                list-style: initial;
            }
            ul {
                margin-left: 15px;
            }
            p {
                font-size: 16px;
            }
        }
    }
`;

const SalaryCalculatorWrapper: FC = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <>
            <Bar>
                <BarContent>
                    <div style={{ display: 'flex' }}>
                        <LanguageOptions />
                        <TextButton
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <Icon className="small" icon={['far', 'arrow-left']} color={COLOR_LILA} />
                            <span aria-hidden>{t('menu.back')}</span>
                        </TextButton>
                    </div>
                </BarContent>
            </Bar>
            <PurpleBackground>
                <Wrapper>
                    <Flex column center fullWidth style={{ height: '100%' }}>
                        <img src={logoFull} alt="Eezy logo" style={{ margin: 38, maxWidth: 150 }} />
                        <SalaryCalculator
                            language={i18n.language}
                            config={SalaryCalcConfig}
                            initialView={CalculatorViewEnum.Simple}
                        />
                    </Flex>
                    <TogglingTools defaultOpen={false} titleTransKey="general.help">
                        <div className="help-area">
                            <ul>
                                <li>{t('salarycalc:1')}</li>
                                <br />
                                <li>{t('salarycalc:2')}</li>
                                <ul>
                                    <li>{t('salarycalc:2-list.1')}</li>
                                    <li>{t('salarycalc:2-list.2')}</li>
                                    <ul>
                                        <li>{t('salarycalc:2-list.3.1')}</li>
                                        <li>{t('salarycalc:2-list.3.2')}</li>
                                    </ul>
                                </ul>
                                <br />
                                <li>
                                    {t('salarycalc:3')}{' '}
                                    <a
                                        href={
                                            i18n.language === 'fi'
                                                ? 'https://kevytyrittajat.eezy.fi/kevytyrittajyys/kevytyrittajan-vakuutukset/yel-vakuutus/'
                                                : 'https://kevytyrittajat.eezy.fi/en/light-entrepreneurship/light-entrepreneurs-insurances/yel-insurance/'
                                        }
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {t('salarycalc:3-anchor')}
                                    </a>
                                </li>
                                <br />
                                <li>
                                    {t('salarycalc:4')}{' '}
                                    <a
                                        href={
                                            i18n.language === 'fi'
                                                ? 'https://kevytyrittajat.eezy.fi/hinnasto/'
                                                : 'https://kevytyrittajat.eezy.fi/en/price-list/'
                                        }
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {t('salarycalc:4-anchor')}
                                    </a>
                                </li>
                                <br />
                                <li>{t('salarycalc:5')}</li>
                                <br />
                                <li>{t('salarycalc:6')}</li>
                                <ul>
                                    <li>{t('salarycalc:6-list.1')}</li>
                                    <li>{t('salarycalc:6-list.2')}</li>
                                </ul>
                                <br />

                                <li>{t('salarycalc:7')}</li>
                                <ul>
                                    <li>{t('salarycalc:7-list.1')}</li>
                                    <li>{t('salarycalc:7-list.2')}</li>
                                </ul>
                                <br />
                                <li>{t('salarycalc:8')}</li>
                                <ul>
                                    <li>{t('salarycalc:8-list.1')}</li>
                                </ul>
                                <br />
                            </ul>
                            <p>{t('salarycalc:9')}</p>
                        </div>
                    </TogglingTools>
                </Wrapper>
            </PurpleBackground>
            <Footer />
        </>
    );
};

export default SalaryCalculatorWrapper;
