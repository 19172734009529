import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { showModals, hideModal } from 'actions/auth';
import { COLOR_GREYHOUND } from '../../styles/variables';
import { Modal, ModalActions, ModalContent } from '../../components/modals/Modal';
import { GET_USER_DATA } from '../../containers/profile/queries';
import { useQuery } from '@apollo/client';
import { trans } from '../../utils';
import { EezyButton } from '../Buttons';
import { GtagCommands, GTMEvents } from '../../constants/user';
import { useUser } from 'queries/useUserQuery';
import { useTranslation } from 'react-i18next';

interface IModalProps {
    handleModalClose: () => void;
    handleConfirm: () => void;
    isOpen: boolean;
    hasAddress: boolean;
}

const ListWrapper = styled.ol`
    margin: 15px 20px;
    padding-left: 15px;
    list-style-type: decimal;

    li {
        margin-bottom: 10px;
        position: relative;
        padding-left: 10px;

        &::marker {
            font-weight: bold;
            color: ${COLOR_GREYHOUND};
        }
    }
`;

const CustomParagraph = styled.p`
    margin: 15px 0;
`;

const IntroText = styled.p`
    color: ${COLOR_GREYHOUND};
`;

const DisabledMessageList = styled.ul`
    list-style-type: none; // Removes the default bullets
    margin: 0;
    padding: 0;

    li {
        position: relative;
        margin: 0;
        padding-left: 20px;
        color: ${COLOR_GREYHOUND};

        &:before {
            content: '•'; // You can use other symbols or even images
            position: absolute;
            left: 0;
            color:${COLOR_GREYHOUND} // Match the color with the text
            font-size: 1.2em; // Adjust the size
        }
    }
`;

const DisabledMessageItem = styled.li`
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const EezyPayModal = (props: IModalProps) => {
    const { i18n } = useTranslation();
    const user = useUser();
    const { hasAddress, isOpen, handleConfirm, handleModalClose } = props;
    const { data: userData } = useQuery(GET_USER_DATA);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const bankAccountNumber = userData?.userData?.bankAccountNumber;
    const isDisabled = !user?.isIdentified || !bankAccountNumber || !hasAddress;
    const readMoreUrl =
        i18n.language === 'en'
            ? 'https://kevytyrittajat.eezy.fi/en/eezypay-en/'
            : 'https://kevytyrittajat.eezy.fi/eezypay/';

    const handleClick = (type: string) => {
        dispatch(hideModal('EEZY_PAY'));

        switch (type) {
            case 'isIdentified':
                dispatch(showModals(['AUTH']));
                break;
            case 'bankAccountNumber':
                navigate('/profile/salary');
                break;
            case 'hasAddress':
                navigate('/profile/contact');
                break;
            default:
                break;
        }
    };

    const getDisabledMessage = () => {
        const steps = [];
        if (!user?.isIdentified)
            steps.push({ text: trans('eezy-pay-modal.complete-identification'), type: 'isIdentified' });
        if (!bankAccountNumber)
            steps.push({ text: trans('eezy-pay-modal.add-bank-account'), type: 'bankAccountNumber' });
        if (!hasAddress) steps.push({ text: trans('eezy-pay-modal.add-address'), type: 'hasAddress' });

        return (
            <div>
                <IntroText>{trans('eezy-pay-modal.you-still-need-to')}</IntroText>
                <DisabledMessageList>
                    {steps.map((step, index) => (
                        <DisabledMessageItem
                            key={index}
                            onClick={() => {
                                handleClick(step.type);
                            }}
                        >
                            {step.text}
                        </DisabledMessageItem>
                    ))}
                </DisabledMessageList>
            </div>
        );
    };

    useEffect(() => {
        window.gtag && window.gtag(GtagCommands.event, GTMEvents.eezypay_interest);
    }, []);

    return (
        <Modal
            small
            id="modal-eezy-pay-about"
            isOpen={isOpen}
            onClose={handleModalClose}
            title={trans('eezy-pay-modal.title')}
        >
            <ModalContent>
                <p>{trans('eezy-pay-modal.p-1')}</p>
                <ListWrapper>
                    <li>
                        <strong>{trans('eezy-pay-modal.list-1')}</strong> {trans('eezy-pay-modal.list-1-1')}
                    </li>
                    <li>
                        <strong>{trans('eezy-pay-modal.list-2')} </strong>
                        {trans('eezy-pay-modal.list-2-1')}
                    </li>
                    <li>
                        <strong>{trans('eezy-pay-modal.list-3')} </strong>
                        {trans('eezy-pay-modal.list-3-1')}{' '}
                        <strong>{trans('eezy-pay-modal.list-3-2')} </strong>
                        {trans('eezy-pay-modal.list-3-3')}
                    </li>
                    <li>
                        <strong>{trans('eezy-pay-modal.list-4')} </strong>
                        {trans('eezy-pay-modal.list-4-1')}
                    </li>
                </ListWrapper>
                <p>
                    {trans('eezy-pay-modal.p-2')}{' '}
                    <a href={readMoreUrl} target="_blank" rel="noreferrer">
                        {trans('eezy-pay-modal.read-more')}
                    </a>
                </p>
                <CustomParagraph>{trans('eezy-pay-modal.p-3')}</CustomParagraph>
            </ModalContent>
            <ModalActions style={{ flexDirection: 'column', alignItems: 'center' }}>
                <EezyButton
                    style={{ minWidth: '50%', margin: '0 auto' }}
                    autoFocus
                    color="purple"
                    dark
                    disabled={isDisabled}
                    onClick={() => {
                        handleConfirm();
                        handleModalClose();
                    }}
                    type="submit"
                >
                    {trans('eezy-pay-modal.button')}
                </EezyButton>
                {isDisabled && <DisabledMessageList>{getDisabledMessage()}</DisabledMessageList>}
            </ModalActions>
        </Modal>
    );
};

export default EezyPayModal;
